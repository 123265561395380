import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, ACCEPTANCE_DELETION } from '../../constants/defaultValues';

const startFetchAcceptanceDeletions = createAsyncThunk('acceptanceDeletion/startFetchAcceptanceDeletions', async ({ locale, token, filter }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;


        const res = await axios.get(`${ACCEPTANCE_DELETION}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchAcceptanceDeletion = createAsyncThunk('acceptanceDeletion/startFetchAcceptanceDeletion', async ({ acceptanceDeletion, history, locale, token }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${ACCEPTANCE_DELETION}/edit/${acceptanceDeletion}`, { headers });

        const data = res.data;

        return data
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startStatusAcceptanceDeletion = createAsyncThunk('acceptanceDeletion/startStatusAcceptanceDeletion', async ({ locale, token, id, action }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${ACCEPTANCE_DELETION}/status/${id}`, { action: action }, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


//Heading Functions
const setSearch = createAsyncThunk('acceptanceDeletion/setSearch', ( args) => {
    return args;
});

const setCurrentPage = createAsyncThunk('acceptanceDeletion/setCurrentPage', ( args) => {
    return args;
});

const setSort = createAsyncThunk('acceptanceDeletion/setSort', ( args) => {
    return args;
});

const initialState = {
    acceptanceDeletions: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{ desc: false, id: "status_id" }],
    statusAcceptanceDeletion: '',
    acceptanceDeletionData: {},
    loadingModal: false,
    loadingBtn: false,
    loading: true,
    error: ''
}

export const acceptanceDeletionSlice = createSlice({
    name: 'acceptanceDeletion',
    initialState,
    reducers: {
        fetchAcceptanceDeletionsSuccess(state, { payload }) {
            return { ...state, loading: false, acceptanceDeletions: payload, error: '' };
        },
        clearAcceptanceDeletion(state) {
            return { ...state, loadingModal: false, loadingBtn: false, loading: false, acceptanceDeletionData: initialState.acceptanceDeletionData, error: '' };
        },
        clearAlertAcceptanceDeletion(state) {
            return { ...state, statusAcceptanceDeletion: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
    },
    extraReducers: {
      [startFetchAcceptanceDeletions.pending]: (state) => {
        return { ...state, loading: true, loadingBtn: false, error: '' };
      },
      [startFetchAcceptanceDeletions.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, acceptanceDeletions: payload, error: '' };
      },
      [startFetchAcceptanceDeletions.rejected]: (state, { payload }) => {
        return { ...state, loading: false, acceptanceDeletions: initialState.acceptanceDeletions, error: payload };
      },

      [startFetchAcceptanceDeletion.pending]: (state) => {
        return { ...state, loadingModal: true, loadingBtn: false, error: '' };
      },
      [startFetchAcceptanceDeletion.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, acceptanceDeletionData: payload, error: '' };
      },
      [startFetchAcceptanceDeletion.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, acceptanceDeletionData: '', error: payload };
      },

      [startStatusAcceptanceDeletion.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, statusAcceptanceDeletion: payload, error: '' };
      },
      [startStatusAcceptanceDeletion.rejected]: (state, { payload }) => {
        return { ...state, loading: false, statusAcceptanceDeletion: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      },
    },
});

export const acceptanceDeletionActions = {
    ...acceptanceDeletionSlice.actions,
    startFetchAcceptanceDeletions,
    startFetchAcceptanceDeletion,
    startStatusAcceptanceDeletion,
    setSearch,
    setCurrentPage,
    setSort
}
export default acceptanceDeletionSlice.reducer