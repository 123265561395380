import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, BUGS } from '../../constants/defaultValues';

const startCreateBug = createAsyncThunk('bug/startCreateBug', async ({ bug, locale, token, form, toggle, dropzone, bugs }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`

        const res = await axios.post(`${BUGS}/store`, bug, { headers });

        let new_bugs = JSON.parse(JSON.stringify(bugs));

        new_bugs.data.unshift(res.data.data);
        new_bugs.totalItemCount = bugs.totalItemCount + 1;

        form.resetForm()
        form.setStatus({ success: true })
        dropzone.removeAllFiles(true)
        toggle()   

        return { status: res.data.status, data: new_bugs };
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateBug = createAsyncThunk('bug/startUpdateBug', async ({ id, bug, locale, token, form, bugs, toggle, dropzone }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${BUGS}/update/${id}`, bug, { headers });

        let new_bugs = bugs.data.map((bug_item, i) => {
            let new_bug_item = {...bug_item};

            if (Number(new_bug_item.id) === Number(id)) {
                new_bug_item = res.data.data
            }
  
            return new_bug_item
        });
  
        const newArray = {
            data: new_bugs,
            totalItemCount: bugs.totalItemCount
        }
        
        form.setStatus({ success: true })
        dropzone.removeAllFiles(true)
        toggle()

        return { status: res.data.status, data: newArray };
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchBugs = createAsyncThunk('bug/startFetchBugs', async ({ locale, token, filter }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;


        const res = await axios.get(`${BUGS}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('bug/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${BUGS}/dataFields?edit=${ifEdit}`, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchBug = createAsyncThunk('bug/startFetchBug', async ({ bug, history, locale, token }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${BUGS}/edit/${bug}`, { headers });

        const data = res.data;

        const results = {
          edit: true,
          report_type_id: data.report_type_id,
          priority_type_id: data.priority_type_id,
          message: data.message,
          comments: data.comments,
          status: data.status,
          files: [],
          filesData: data.filesData
        };

        return results
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startStatusBug = createAsyncThunk('bug/startStatusBug', async ({ locale, token, id, action }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${BUGS}/status/${id}`, { action: action }, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteBug = createAsyncThunk('bug/startDeleteBug', async ({ locale, token, id }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${BUGS}/destroy/${id}`, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteFile = createAsyncThunk('bug/startDeleteFile', async ({ locale, token, id }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${BUGS}/destroyFile/${id}`, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


//Heading Functions
const setSearch = createAsyncThunk('bug/setSearch', ( args) => {
    return args;
});

const setCurrentPage = createAsyncThunk('bug/setCurrentPage', ( args) => {
    return args;
});

const setSort = createAsyncThunk('bug/setSort', ( args) => {
    return args;
});

const initialState = {
    bugs: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{ desc: false, id: "id" }],
    createBug: '',
    updateBug: '',
    statusBug: '',
    deleteBug: '',
    deleteFile: '',
    bugData: {
        edit: false,
        report_type_id: '',
        priority_type_id: '',
        message: '',
        comments: '',
        files: [],
        filesData: []
    },
    dataFields: {
        reports: [],
        priorities: []
    },
    bug_id: '',
    capture: '',
    typeModal: 1,
    isEditModal: 0,
    isOpenModal: false,
    loadingModal: false,
    loadingBtn: false,
    loading: true,
    error: ''
}

export const bugSlice = createSlice({
    name: 'bug',
    initialState,
    reducers: {
        setToggleModal(state, { payload }) {
            return { ...state, isOpenModal: !state.isOpenModal, isEditModal: payload.edit ? 1 : 0, bug_id: payload.bug_id, typeModal: payload.typeModal, capture: payload.capture };
        },
        fetchBugsSuccess(state, { payload }) {
            return { ...state, loading: false, bugs: payload, error: '' };
        },
        clearBug(state) {
            return { ...state, loadingModal: false, loadingBtn: false, loading: false, bugData: initialState.bugData, error: '' };
        },
        clearAlertBug(state) {
            return { ...state, updateBug: '', createBug: '', deleteBug: '', statusBug: '', deleteFile: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
    },
    extraReducers: {
      [startCreateBug.pending]: (state) => {
        return { ...state, loading: true, loadingBtn: true, error: '' };
      },
      [startCreateBug.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, loadingBtn: false, bugs: payload.data, createBug: payload.status, error: '' };
      },
      [startCreateBug.rejected]: (state, { payload }) => {
        return { ...state, loading: false, loadingBtn: false, createBug: '', error: payload };
      },

      [startFetchBugs.pending]: (state) => {
        return { ...state, loading: true, loadingBtn: false, error: '' };
      },
      [startFetchBugs.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, bugs: payload, error: '' };
      },
      [startFetchBugs.rejected]: (state, { payload }) => {
        return { ...state, loading: false, bugs: initialState.bugs, error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loadingModal: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, dataFields: initialState.dataFields, error: payload };
      },

      [startUpdateBug.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateBug.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, bugs: payload.data, updateBug: payload.status, error: '' };
      },
      [startUpdateBug.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateBug: '', error: payload };
      },

      [startFetchBug.pending]: (state) => {
        return { ...state, loadingModal: true, loadingBtn: false, error: '' };
      },
      [startFetchBug.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, bugData: payload, error: '' };
      },
      [startFetchBug.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, bugData: '', error: payload };
      },

      [startDeleteBug.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteBug: payload, error: '' };
      },
      [startDeleteBug.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteBug: '', error: payload };
      },

      [startStatusBug.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, statusBug: payload, error: '' };
      },
      [startStatusBug.rejected]: (state, { payload }) => {
        return { ...state, loading: false, statusBug: '', error: payload };
      },

      [startDeleteFile.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteFile: payload, error: '' };
      },
      [startDeleteFile.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteFile: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      },
    },
});

export const bugActions = {
    ...bugSlice.actions,
    startCreateBug,
    startFetchBugs,
    startFetchDataFields,
    startUpdateBug,
    startFetchBug,
    startDeleteBug,
    startStatusBug,
    startDeleteFile,
    setSearch,
    setCurrentPage,
    setSort
}
export default bugSlice.reducer