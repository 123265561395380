import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, CVS } from '../../constants/defaultValues';

const startFetchCvs = createAsyncThunk('cv/startFetchCvs', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${CVS}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchCv = createAsyncThunk('cv/startFetchCv', async ({cv, history, locale, token }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CVS}/edit/${cv}`, { headers });

        const data = res.data;

        const results = {
          edit: true,
          career_id: data.career_id,
          name: data.name,
          surname: data.surname,
          email: data.email,
          message: data.message,
          file_name: data.file,
          file: ''
        };

        dispatch(startFetchDataSelectFields({ locale: locale, token: token, ifEdit: 1 }));

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateCv = createAsyncThunk('cv/startCreateCv', async ({ cv, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CVS}/store`, cv, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateCv = createAsyncThunk('cv/startUpdateCv', async ({ id, cv, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CVS}/update/${id}`, cv, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('cv/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CVS}/dataFields?edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataSelectFields = createAsyncThunk('cv/startFetchDataSelectFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CVS}/selectField?edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteCv = createAsyncThunk('cv/startDeleteCv', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${CVS}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});



//Heading Functions
const setSearch = createAsyncThunk('cv/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('cv/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('cv/setSort', ( args, { rejectWithValue }) => {
    return args;
});


const initialState = {
    cvs: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    cvData: {
        career_id: '',
        name: '',
        surname: '',
        email: '',
        message: '',
        file_name: '',
        file: ''
    },
    dataFields: {},
    dataSelectFields: {
        careers: []
    },
    createCv: '',
    updateCv: '',
    deleteCv: '',
    loadingBtn: false,
    loading: true,
    error: ''
}

export const cvSlice = createSlice({
    name: 'cv',
    initialState,
    reducers: {
        fetchCvsSuccess(state, { payload }) {
            return { ...state, loading: false, cvs: payload, error: '' };
        },
        clearCv(state) {
            return { ...state, loadingBtn: false, loading: false, cvData: initialState.cvData, dataSelectFields: initialState.dataSelectFields, error: '' };
        },
        clearAlertCv(state) {
            return { ...state, updateCv: '', createCv: '', deleteCv: '', activeCv: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchCvs.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchCvs.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, cvs: payload, error: '' };
      },
      [startFetchCvs.rejected]: (state, { payload }) => {
        return { ...state, loading: false, cvs: initialState.cvs, error: payload };
      },

      [startFetchCv.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchCv.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, cvData: payload, error: '' };
      },
      [startFetchCv.rejected]: (state, { payload }) => {
        return { ...state, loading: false, cvData: '', error: payload };
      },

      [startCreateCv.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateCv.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createCv: payload, error: '' };
      },
      [startCreateCv.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createCv: '', error: payload };
      },

      [startUpdateCv.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateCv.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateCv: payload, error: '' };
      },
      [startUpdateCv.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateCv: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchDataSelectFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataSelectFields: payload, error: '' };
      },
      [startFetchDataSelectFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataSelectFields: initialState.dataSelectFields, error: payload };
      },

      [startDeleteCv.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteCv: payload, error: '' };
      },
      [startDeleteCv.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteCv: '', error: payload };
      },

      

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const cvActions = {
    ...cvSlice.actions,
    startFetchCvs,
    startFetchCv,
    startCreateCv,
    startUpdateCv,
    startFetchDataFields,
    startFetchDataSelectFields,
    startDeleteCv,
    
    setSearch,
    setCurrentPage,
    setSort
}
export default cvSlice.reducer