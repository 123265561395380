import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, CAREERS } from '../../constants/defaultValues';

const startFetchDataFields = createAsyncThunk('career/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CAREERS}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchCareers = createAsyncThunk('career/startFetchCareers', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${CAREERS}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateCareer = createAsyncThunk('career/startCreateCareer', async ({ career, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CAREERS}/store`, career, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateCareer = createAsyncThunk('career/startUpdateCareer', async ({ id, career, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CAREERS}/update/${id}`, career, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteCareer = createAsyncThunk('career/startDeleteCareer', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${CAREERS}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveCareer = createAsyncThunk('career/startActiveCareer', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${CAREERS}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchCareer = createAsyncThunk('career/startFetchCareer', async ({ career, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CAREERS}/edit/${career}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            name: data.name,
            langs: data.langs,
            spoken_languages: data.spoken_languages,
            linkedin: data.linkedin,
            jobfind: data.jobfind,
            url_en: data.url_en ? data.url_en : '',
            url_el: data.url_el ? data.url_el : '',
            title_en: data.title_en ? data.title_en : '',
            title_el: data.title_el ? data.title_el : '',
            text_en: data.text_en ? data.text_en : '',
            text_el: data.text_el ? data.text_el : '',
            meta_title_en: data.page_config ? data.page_config.meta_title_en : '',
            meta_title_el: data.page_config ? data.page_config.meta_title_el : '',
            meta_desc_en: data.page_config ? data.page_config.meta_desc_en : '',
            meta_desc_el: data.page_config ? data.page_config.meta_desc_el : '',
            meta_lsi_en: data.page_config ? data.page_config.meta_lsi_en : [],
            meta_lsi_el: data.page_config ? data.page_config.meta_lsi_el : [],
            sort: data.sort,
            image: data.image,
            image_name: data.image
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('career/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('career/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('career/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    careers: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    careerData: {
        name: '',
        langs: [],
        spoken_languages: [],
        linkedin: '',
        jobfind: '',
        sort: 0,
        url_en: '',
        url_el: '',
        title_en: '',
        title_el: '',
        text_en: '',
        text_el: '',
        meta_title_en: '',
        meta_title_el: '',
        meta_desc_en: '',
        meta_desc_el: '',
        meta_lsi_en: [],
        meta_lsi_el: [],
        image: '',
        image_name: ''
    },
    dataFields: {
        languages: [],
        spoken_languages: []
    },
    createCareer: '',
    updateCareer: '',
    deleteCareer: '',
    loadingBtn: false,
    loading: true,
    error: ''
}

export const careerSlice = createSlice({
    name: 'career',
    initialState,
    reducers: {
        fetchCareersSuccess(state, { payload }) {
            return { ...state, loading: false, careers: payload, error: '' };
        },
        clearCareer(state) {
            return { ...state, loadingBtn: false, loading: false, careerData: initialState.careerData, error: '' };
        },
        clearAlertCareer(state) {
            return { ...state, updateCareer: '', createCareer: '', deleteCareer: '', activeCareer: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchCareers.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchCareers.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, careers: payload, error: '' };
      },
      [startFetchCareers.rejected]: (state, { payload }) => {
        return { ...state, loading: false, careers: initialState.careers, error: payload };
      },

      [startFetchCareer.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchCareer.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, careerData: payload, error: '' };
      },
      [startFetchCareer.rejected]: (state, { payload }) => {
        return { ...state, loading: false, careerData: '', error: payload };
      },
      
      [startCreateCareer.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateCareer.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createCareer: payload, error: '' };
      },
      [startCreateCareer.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createCareer: '', error: payload };
      },

      [startUpdateCareer.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateCareer.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateCareer: payload, error: '' };
      },
      [startUpdateCareer.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateCareer: '', error: payload };
      },

      [startDeleteCareer.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteCareer: payload, error: '' };
      },
      [startDeleteCareer.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteCareer: '', error: payload };
      },

      [startActiveCareer.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeCareer: payload, error: '' };
      },
      [startActiveCareer.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeCareer: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload }; 
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const careerActions = {
    ...careerSlice.actions,
    startFetchCareers,
    startFetchCareer,
    startCreateCareer,
    startUpdateCareer,
    startDeleteCareer,
    startActiveCareer,
    startFetchDataFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default careerSlice.reducer