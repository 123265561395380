import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { createFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';

import authUserReducer from './auth/authUserSlice';
import accountReducer from './validate/accountSlice';
import settingsReducer from './settings/settingsSlice';
import menuReducer from './menu/menuSlice';
import notificationReducer from './notifications/notificationSlice';
import bugReducer from './bugs/bugSlice';
import acceptanceDeletionReducer from './acceptance-deletion/acceptanceDeletionSlice';
import auctionLeadReducer from './auction-lead/auctionLeadSlice';
import activityReducer from './activity/activitySlice';
import startReducer from './start/startSlice';
import calendarReducer from './calendar/calendarSlice';
import taskReducer from './tasks/taskSlice';
import fileTemplateReducer from './file-template/fileTemplateSlice';
import knowledgeDatabaseReducer from './knowledge-database/knowledgeDatabaseSlice';
import absenceReducer from './absences/absenceSlice';
import liveGoalReducer from './liveGoals/liveGoalSlice';
import campaignReducer from './campaigns/campaignSlice';
import permissionReducer from './permissions/permissionSlice';
import roleReducer from './roles/roleSlice';
import userReducer from './users/userSlice';
import paymentReducer from './payments/paymentSlice';
import groupReducer from './group/groupSlice';
import cvReducer from './cv/cvSlice';
import parentMenuReducer from './parent-menu/parentMenuSlice';
import sectionMenuReducer from './section-menu/sectionMenuSlice';
import childrenMenuReducer from './children-menu/childrenMenuSlice';
import articleCategoryReducer from './article-category/articleCategorySlice';
import articleReducer from './article/articleSlice';
import careerReducer from './career/careerSlice';
import homePageConfigReducer from './home-page-config/homePageConfigSlice';
import contactPageConfigReducer from './contact-page-config/contactPageConfigSlice';
import faqReducer from './faq/faqSlice';
import partnerReducer from './partner/partnerSlice';
import ourTeamReducer from './ourTeam/ourTeamSlice';
import typeReducer from './types/typeSlice';
import typeSyncReducer from './typeSyncs/typeSyncSlice';
import contactScoreReducer from './contact-score/contactScoreSlice';
import propertyScoreReducer from './property-score/propertyScoreSlice';
import readinessScoreReducer from './readiness-score/readinessScoreSlice';
import quarterGoalReducer from './quarter-goals/quarterGoalSlice';
import dayOffReducer from './days-off/dayOffSlice';
import closedDayReducer from './closed-days/closedDaySlice';
import propertyCategoryReducer from './property-categories/propertyCategorySlice';
import propertySubcategoryReducer from './property-subcategories/propertySubcategorySlice';
import extraReducer from './extras/extraSlice';
import countryReducer from './countries/countrySlice';
import timezoneReducer from './timezones/timezoneSlice';
import propertyCountryReducer from './property-countries/propertyCountrySlice';
import propertyPrefectureReducer from './property-prefectures/propertyPrefectureSlice';
import propertyMunicipalityReducer from './property-municipalities/propertyMunicipalitySlice';
import propertyAreaReducer from './property-areas/propertyAreaSlice';
import complexReducer from './complexes/complexSlice';
import websitePropertyReducer from './website-properties/websitePropertySlice';
import auctionPropertyReducer from './auction-properties/auctionPropertySlice';
import assignmentReducer from './assignment/assignmentSlice';
import indicationReducer from './indication/indicationSlice';
import contactReducer from './contacts/contactSlice';
import requestReducer from './requests/requestSlice';
import depositReducer from './deposits/depositSlice';
import dealReducer from './deals/dealSlice';
import propertyReducer from './properties/propertySlice';
import propertyBucketReducer from './property-buckets/bucketSlice';
import requestBucketReducer from './request-bucket/requestBucketSlice';
import legalAuditReducer from './legal-audit/legalAuditSlice';
import commentsReducer from './comments/commentsSlice';

const persistConfig = {
  key: 'root',
  storage,
  transform: [
    createFilter('authUser', ['author'])
  ]
}

const rootReducer = combineReducers({
  authUser: authUserReducer,
  account: accountReducer,
  settings: settingsReducer,
  menu: menuReducer,
  notification: notificationReducer,
  bug: bugReducer,
  acceptanceDeletion: acceptanceDeletionReducer,
  activity: activityReducer,
  start: startReducer,
  calendar: calendarReducer,
  task: taskReducer,
  fileTemplate: fileTemplateReducer,
  knowledgeDatabase: knowledgeDatabaseReducer,
  absence: absenceReducer,
  liveGoal: liveGoalReducer,
  campaign: campaignReducer,
  permission: permissionReducer,
  role: roleReducer,
  user: userReducer,
  payment: paymentReducer,
  group: groupReducer,
  cv: cvReducer,
  parentMenu: parentMenuReducer,
  sectionMenu: sectionMenuReducer,
  childrenMenu: childrenMenuReducer,
  articleCategory: articleCategoryReducer,
  article: articleReducer,
  career: careerReducer,
  homePageConfig: homePageConfigReducer,
  contactPageConfig: contactPageConfigReducer,
  faq: faqReducer,
  partner: partnerReducer,
  ourTeam: ourTeamReducer,
  type: typeReducer,
  typeSync: typeSyncReducer,
  contactScore: contactScoreReducer,
  propertyScore: propertyScoreReducer,
  readinessScore: readinessScoreReducer,
  quarterGoal: quarterGoalReducer,
  dayOff: dayOffReducer,
  closedDay: closedDayReducer,
  propertyCategory: propertyCategoryReducer,
  propertySubcategory: propertySubcategoryReducer,
  extra: extraReducer,
  country: countryReducer,
  timezone: timezoneReducer,
  propertyCountry: propertyCountryReducer,
  propertyPrefecture: propertyPrefectureReducer,
  propertyMunicipality: propertyMunicipalityReducer,
  propertyArea: propertyAreaReducer,
  complex: complexReducer,
  websiteProperty: websitePropertyReducer,
  auctionProperty: auctionPropertyReducer,
  assignment: assignmentReducer,
  indication: indicationReducer,
  contact: contactReducer,
  request: requestReducer,
  auctionLead: auctionLeadReducer,
  deposit: depositReducer,
  deal: dealReducer,
  property: propertyReducer,
  propertyBucket: propertyBucketReducer,
  requestBucket: requestBucketReducer,
  legalAudit: legalAuditReducer,
  comment: commentsReducer,
});

export default persistReducer(persistConfig, rootReducer);