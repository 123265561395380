import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, PROPERTY_BUCKET } from '../../constants/defaultValues';

const startFetchSearchFields = createAsyncThunk('propertyBucket/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_BUCKET}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateBucket = createAsyncThunk('propertyBucket/startCreateBucket', async ({  bucket, locale, token, form, dropzone }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_BUCKET}/store`, bucket, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
            dropzone.removeAllFiles(true)
        }

        return res.data;
    } catch (error) {
        console.log(error)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateBucket = createAsyncThunk('propertyBucket/startUpdateBucket', async ({ id, bucket, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_BUCKET}/update/${id}`, bucket, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchBuckets = createAsyncThunk('propertyBucket/startFetchBuckets', async ({ locale, token, filter, loading }, { rejectWithValue, dispatch }) => {
    try {
        dispatch(propertyBucketSlice.actions.actionFetchBuckets(loading));
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_BUCKET}?
        pageSize=${filter.pageSize}
        &currentPage=${filter.currentPage}
        &orderBy=${filter.orderBy}
        &search=${filter.search}
        &gender_type=${filter.searchFilter.gender_type.length > 0 ? filter.searchFilter.gender_type.join() : ""}
        &state_type=${filter.searchFilter.state_type.length > 0 ? filter.searchFilter.state_type.join() : ""}
        &status_type=${filter.searchFilter.status_type.length > 0 ? filter.searchFilter.status_type.join() : ""}
        &source_type=${filter.searchFilter.source_type.length > 0 ? filter.searchFilter.source_type.join() : ""}
        &category_type=${filter.searchFilter.category_type.length > 0 ? filter.searchFilter.category_type.join() : ""}
        &subcategory_type=${filter.searchFilter.subcategory_type.length > 0 ? filter.searchFilter.subcategory_type.join() : ""}
        &transaction_type=${filter.searchFilter.transaction_type.length > 0 ? filter.searchFilter.transaction_type.join() : ""}
        &prefecture_type=${filter.searchFilter.prefecture_type.length > 0 ? filter.searchFilter.prefecture_type.join() : ""}
        &municipality_type=${filter.searchFilter.municipality_type.length > 0 ? filter.searchFilter.municipality_type.join() : ""}
        &area_type=${filter.searchFilter.area_type.length > 0 ? filter.searchFilter.area_type.join() : ""}
        &stages_type=${filter.searchFilter.stages_type.length > 0 ? filter.searchFilter.stages_type.join() : ""}
        &createdUser_type=${filter.searchFilter.createdUser_type.length > 0 ? filter.searchFilter.createdUser_type.join() : ""}
        &assignUser_type=${filter.searchFilter.assignUser_type.length > 0 ? filter.searchFilter.assignUser_type.join() : ""}
        &fdsUser_type=${filter.searchFilter.fdsUser_type.length > 0 ? filter.searchFilter.fdsUser_type.join() : ""}
        &ecc_type=${filter.searchFilter.ecc_type.length > 0 ? filter.searchFilter.ecc_type.join() : ""}
       
        &fromDateCreate_type=${filter.searchFilter.fromDateCreate_type}
        &toDateCreate_type=${filter.searchFilter.toDateCreate_type}
        &fromDateIncome_type=${filter.searchFilter.fromDateIncome_type}
        &toDateIncome_type=${filter.searchFilter.toDateIncome_type}
        &fromPrice_type=${filter.searchFilter.fromPrice_type}
        &toPrice_type=${filter.searchFilter.toPrice_type}
        &fromSq_type=${filter.searchFilter.fromSq_type}
        &toSq_type=${filter.searchFilter.toSq_type}
        `, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteBucket = createAsyncThunk('propertyBucket/startDeleteBucket', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${PROPERTY_BUCKET}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteFile = createAsyncThunk('propertyBucket/startDeleteFile', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${PROPERTY_BUCKET}/destroyFile/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startRemoveOperator = createAsyncThunk('propertyBucket/startRemoveOperator', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_BUCKET}/removeOperator/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCopyBucket = createAsyncThunk('propertyBucket/startCopyBucket', async ({ locale, token, id, buckets }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_BUCKET}/copy/${id}`, { headers });

        let newBuckets = JSON.parse(JSON.stringify(buckets));

        newBuckets.data.unshift(res.data.bucket);
        newBuckets.totalItemCount = newBuckets.totalItemCount + 1;

        let newArray = {
          data: newBuckets.data,
          totalItemCount: newBuckets.totalItemCount
        }

        dispatch(propertyBucketSlice.actions.fetchBucketsSuccess(newArray))

        return res.data.status;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSubcategory = createAsyncThunk('propertyBucket/startFetchSubcategory', async ({ locale, token, category_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_BUCKET}/selectSubcategory?category_id=${category_id}`, { headers });

        return res.data.subcategories;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchMunicipalities = createAsyncThunk('propertyBucket/startFetchMunicipalities', async ({ locale, token, prefecture_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_BUCKET}/getMunicipality`, { prefecture_id: prefecture_id }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchAreas = createAsyncThunk('propertyBucket/startFetchAreas', async ({ locale, token, prefecture_id, municipality_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_BUCKET}/getArea`, { prefecture_id: prefecture_id, municipality_id: municipality_id }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startBucketPhotos = createAsyncThunk('propertyBucket/startBucketPhotos', async ({ bucket, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_BUCKET}/bucketPhotos/${bucket}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startBucketInfo = createAsyncThunk('propertyBucket/startBucketInfo', async ({ bucket, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_BUCKET}/info/${bucket}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('propertyBucket/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_BUCKET}/dataFields?lang=${locale}&edit=${ifEdit ? 1 : 0}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchBucket = createAsyncThunk('propertyBucket/startFetchBucket', async ({ bucket, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_BUCKET}/edit/${bucket}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            id: data.id,
            tab: data.tab,
            communication_channel: data.communication_channel,
            status_type_id: data.status_type_id,
            income_date: data.income_date ? new Date(data.income_date) : null,
            active_or_passive: data.active_or_passive,
            bedrooms: data.bedrooms,
            elevator: data.elevator,
            link: data.link,
            first_com_id: data.first_com_id,
            fds_user: data.fds_user,
            sale_or_rent: data.sale_or_rent,
            category_id: data.category_id,
            subcategory_id: data.subcategory_id,
            floor: data.floor,
            lux_prop: data.lux_prop,
            dei: data.dei,
            property_id: data.property_id,
            stage_id: data.stage_id,
            price: data.price,
            sq_meters: data.sq_meters,
            prefecture_id: data.prefecture_id,
            municipality_id: data.municipality_id,
            area_id: data.area_id,
            address: data.address,
            lat: data.lat,
            lng: data.lng,
            ecc: data.ecc,
            contact_id: data.contact_id,
            name: data.name,
            surname: data.surname,
            gender: data.gender,
            country: data.country,
            email: data.email,
            phone: data.phone,
            phoneCountry: data.phoneCountry,
            phoneCode: data.phoneIso,
            phoneIso: data,
            mobile: data.mobile,
            mobileCountry: data.mobileCountry,
            mobileCode: data.mobileCode,
            mobileIso: data.mobileIso,
            spoken_language: data.spoken_language,
            preferred_communication_id: data.preferred_communication_id,
            age_range_id: data.age_range_id,
            cooperative_owner_id: data.cooperative_owner_id,
            contact_source: data.contact_source,
            operator: data.operator,
            notes: data.notes,
            files: [],
            filesData: data.filesData,
    
            contacts: data.contacts,
            loading: false
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startBucketsClusterPoints = createAsyncThunk('propertyBucket/startBucketsClusterPoints', async ({ filter, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_BUCKET}/bucketsClusterPoints?
        search=${filter.search}
        &gender_type=${filter.searchFilter.gender_type.length > 0 ? filter.searchFilter.gender_type.join() : ""}
        &state_type=${filter.searchFilter.state_type.length > 0 ? filter.searchFilter.state_type.join() : ""}
        &status_type=${filter.searchFilter.status_type.length > 0 ? filter.searchFilter.status_type.join() : ""}
        &source_type=${filter.searchFilter.source_type.length > 0 ? filter.searchFilter.source_type.join() : ""}
        &category_type=${filter.searchFilter.category_type.length > 0 ? filter.searchFilter.category_type.join() : ""}
        &subcategory_type=${filter.searchFilter.subcategory_type.length > 0 ? filter.searchFilter.subcategory_type.join() : ""}
        &transaction_type=${filter.searchFilter.transaction_type.length > 0 ? filter.searchFilter.transaction_type.join() : ""}
        &prefecture_type=${filter.searchFilter.prefecture_type.length > 0 ? filter.searchFilter.prefecture_type.join() : ""}
        &municipality_type=${filter.searchFilter.municipality_type.length > 0 ? filter.searchFilter.municipality_type.join() : ""}
        &area_type=${filter.searchFilter.area_type.length > 0 ? filter.searchFilter.area_type.join() : ""}
        &stages_type=${filter.searchFilter.stages_type.length > 0 ? filter.searchFilter.stages_type.join() : ""}
        &createdUser_type=${filter.searchFilter.createdUser_type.length > 0 ? filter.searchFilter.createdUser_type.join() : ""}
        &assignUser_type=${filter.searchFilter.assignUser_type.length > 0 ? filter.searchFilter.assignUser_type.join() : ""}
        &fdsUser_type=${filter.searchFilter.fdsUser_type.length > 0 ? filter.searchFilter.fdsUser_type.join() : ""}
        &ecc_type=${filter.searchFilter.ecc_type.length > 0 ? filter.searchFilter.ecc_type.join() : ""}
       
        &fromDateCreate_type=${filter.searchFilter.fromDateCreate_type}
        &toDateCreate_type=${filter.searchFilter.toDateCreate_type}
        &fromDateIncome_type=${filter.searchFilter.fromDateIncome_type}
        &toDateIncome_type=${filter.searchFilter.toDateIncome_type}
        &fromPrice_type=${filter.searchFilter.fromPrice_type}
        &toPrice_type=${filter.searchFilter.toPrice_type}
        &fromSq_type=${filter.searchFilter.fromSq_type}
        &toSq_type=${filter.searchFilter.toSq_type}
    `, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSelectedOrderOption = createAsyncThunk('contact/setSelectedOrderOption', ( args ) => {
    return args;
});

const setSearch = createAsyncThunk('contact/setSearch', ( args ) => {
    return args;
});

const setCurrentPage = createAsyncThunk('contact/setCurrentPage', ( args ) => {
    return args;
});

const initialState = {
    buckets: {
        data: [],
        totalItemCount: 0
    },
    displayMode: "list",
    selectedPageSize: 10,
    selectedOrderOption: "",
    search: "",
    searchValue: "",
    searchFilterType: 1,
    fields: {},
    searchFilter: [
        { translate: "general.state", id: "state_type", dataKey: "state_types", search: "", type: 1 },
        { translate: "general.status", id: "status_type", dataKey: "status_types", search: "", type: 1 },
        { translate: "general.gender", id: "gender_type", dataKey: "gender_types", search: "", type: 1 },
        { translate: "general.source", id: "source_type", dataKey: "source_types", search: "", type: 1 },
        { translate: "property.property-category", id: "category_type", dataKey: "category_types", search: "", type: 1 },
        { translate: "property.property-subcategory", id: "subcategory_type", dataKey: "subcategory_types", search: "", type: 1 },
        { translate: "property.luxury-property", id: "lux_prop_type", dataKey: "lux_prop_types", search: "", type: 1 },
        { translate: "property.property-transaction-types", id: "transaction_type", dataKey: "transaction_types", search: "", type: 1 },
        { translate: "general.prefectures", id: "prefecture_type", dataKey: "prefecture_types", search: "", type: 1 },
        { translate: "general.municipalities", id: "municipality_type", dataKey: "municipality_types", search: "", type: 1 },
        { translate: "general.areas", id: "area_type", dataKey: "area_types", search: "", type: 1 },
        { translate: "menu.stage-list", id: "stages_type", dataKey: "stages_types", search: "", type: 1 },
        { translate: "menu.create-user", id: "createdUser_type", dataKey: "createdUser_types", search: "", type: 1 },
        { translate: "general.assignee", id: "assignUser_type", dataKey: "assignUser_types", search: "", type: 1 },
        { translate: "general.fds", id: "fdsUser_type", dataKey: "fdsUser_types", search: "", type: 1 },
        { translate: "general.ecc", id: "ecc_type", dataKey: "ecc_types", search: "", type: 1 },
        
        { translate: "general.create-date", id: "create_date_type", dataKey: "", search: "", type: 1 },
        { translate: "general.income-date", id: "income_date_type", dataKey: "", search: "", type: 1 },
        { translate: "general.price", id: "price_type", dataKey: "", search: "", type: 1 },
        { translate: "property.property-sq-meters", id: "sq_type", dataKey: "", search: "", type: 1 },
    ],
    searchFilterValues: {
        state_type: [],
        status_type: [],
        gender_type: [],
        source_type: [],
        category_type: [],
        subcategory_type: [],
        lux_prop_type: [],
        transaction_type: [],
        prefecture_type: [],
        municipality_type: [],
        area_type: [],
        stages_type: [],
        createdUser_type: [],
        assignUser_type: [],
        fdsUser_type: [],
        ecc_type: [],

        fromDateCreate_type: "",
        toDateCreate_type: "",
        fromDateIncome_type: "",
        toDateIncome_type: "",
        fromPrice_type: "",
        toPrice_type: "",
        fromSq_type: "",
        toSq_type: "",
    },
    bucketPhotos: {
        large: [],
        thump: []
    },
    bucketData: {
        id: "",
        tab: 1,
        communication_channel: "",
        status_type_id: 1,
        income_date: new Date(),
        active_or_passive: "",
        link: "",
        first_com_id: 1,
        fds_user: "",
        sale_or_rent: 1,
        category_id: "",
        subcategory_id: "",
        floor: "",
        lux_prop: "",
        bedrooms: "",
        elevator: "",
        dei: "",
        property_id: "",
        stage_id: "",
        price: "",
        sq_meters: "",
        prefecture_id: "",
        municipality_id: "",
        area_id: "",
        address: "",
        lat: "",
        lng: "",
        ecc: "",
        contact_id: "",
        name: "",
        surname: "",
        gender: "",
        country: "",
        email: "",
        phone: "",
        phoneCountry: "",
        phoneCode: "",
        phoneIso: "",
        mobile: "",
        mobileCountry: "",
        mobileCode: "",
        spoken_language: [],
        preferred_communication_id: [],
        age_range_id: "",
        cooperative_owner_id: "",
        contact_source: "",
        operator: "",
        notes: "",
        files: [],
        filesData: [],

        contacts: [],
        loading: false
    },
    dataFields: {
        status: [],
        genders: [],
        sources: [],
        transactions: [],
        categories: [],
        first_coms: [],
        prefectures: [],
        assignUsers: [],
        fdsUsers: [],
        countries: [],
        languages: [],
        age_ranges: [],
        preferredCommunications: [],
        active_or_passives: [],
        cooperative_owners: [],
        states: [],
        ranges: [],
        cooperatives: [],
        eccs: []
    },
    subcategory: [],
    municipalities: [],
    areas: [],
    bucketsClusterPoints: [],
    createBucket: '',
    updateBucket: '',
    deleteBucket: '',
    deleteFile: '',
    copyBucket: '',
    bucketInfo: '',
    removeOperator: '',
    loadingBtn: false,
    loading: true,
    loadingModal: false,
    loadingSearch: false,
    error: ''
}

export const propertyBucketSlice = createSlice({
    name: 'propertyBucket',
    initialState,
    reducers: {
        fetchBucketsSuccess(state, { payload }) {
            return { ...state, loading: false, loadingSearch: false, buckets: payload, error: '' };
        },
        clearBucket(state) {
            return { ...state, loadingBtn: false, loading: false, loadingSearch: false, loadingModal: false, subcategory: [], municipalities: [], areas: [], bucketData: initialState.bucketData, error: '' };
        },
        clearAlertBucket(state) {
            return { ...state, createBucket: '', updateBucket: '', deleteBucket: '', copyBucket: '', bucketInfo: '', deleteFile: '', removeOperator: '', error: '' };
        },
        actionFetchBuckets(state, { payload }) {
            return { ...state, loading: payload ? true : false, loadingSearch: true, error: '' };
        },
        dataSelectMunicipalities(state, { payload }) {
            return { ...state, municipalities: payload, error: '' };
        },
        dataSelectAreas(state, { payload }) {
            return { ...state, areas: payload, error: '' };
        },
        setDisplayMode(state, { payload }) {
            return { ...state, displayMode: payload };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 };
        },
        setFilter(state, { payload }) {
            return { ...state, searchFilterValues: payload };
        },
        setFilterType(state, { payload }) {
            return { ...state, searchFilterType: payload };
        },
        setFilterFieldSearch(state, { payload }) {
            return { ...state, fields: payload.dataValues, searchFilter: payload.values };
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
    },
    extraReducers: {
      [startFetchSearchFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSearchFields.fulfilled]: (state, { payload }) => {
        return { ...state, fields: payload, error: '' };
      },
      [startFetchSearchFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, fields: initialState.fields, error: payload };
      },

      [startCreateBucket.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateBucket.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createBucket: payload, error: '' };
      },
      [startCreateBucket.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createBucket: '', error: payload };
      },

      [startUpdateBucket.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateBucket.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateBucket: payload, error: '' };
      },
      [startUpdateBucket.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateBucket: '', error: payload };
      },

      [startFetchBuckets.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, loadingSearch: false, buckets: payload, error: '' };
      },
      [startFetchBuckets.rejected]: (state, { payload }) => {
        return { ...state, loading: false, loadingSearch: false, buckets: initialState.buckets, error: payload };
      },

      [startDeleteBucket.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteBucket: payload, error: '' };
      },
      [startDeleteBucket.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteBucket: '', error: payload };
      },

      [startCopyBucket.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, copyBucket: payload, error: '' };
      },
      [startCopyBucket.rejected]: (state, { payload }) => {
        return { ...state, loading: false, copyBucket: '', error: payload };
      },

      [startDeleteFile.fulfilled]: (state, { payload }) => {
        return { ...state, deleteFile: payload, error: '' };
      },
      [startDeleteFile.rejected]: (state, { payload }) => {
        return { ...state, deleteFile: '', error: payload };
      },

      [startFetchSubcategory.fulfilled]: (state, { payload }) => {
        return { ...state, subcategory: payload, error: '' };
      },
      [startFetchSubcategory.rejected]: (state, { payload }) => {
        return { ...state, subcategory: initialState.subcategory, error: payload };
      },

      [startFetchMunicipalities.fulfilled]: (state, { payload }) => {
        return { ...state, municipalities: payload, error: '' };
      },
      [startFetchMunicipalities.rejected]: (state, { payload }) => {
        return { ...state, municipalities: initialState.municipalities, error: payload };
      },

      [startFetchAreas.fulfilled]: (state, { payload }) => {
        return { ...state, areas: payload, error: '' };
      },
      [startFetchAreas.rejected]: (state, { payload }) => {
        return { ...state, areas: initialState.areas, error: payload };
      },

      [startBucketPhotos.pending]: (state) => {
        return { ...state, loadingModal: true, error: '' };
      },
      [startBucketPhotos.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, bucketPhotos: payload, error: '' };
      },
      [startBucketPhotos.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, bucketPhotos: '', error: payload };
      },

      [startBucketInfo.pending]: (state) => {
        return { ...state, loadingModal: true, error: '' };
      },
      [startBucketInfo.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, bucketInfo: payload, error: '' };
      },
      [startBucketInfo.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, bucketInfo: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
       },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchBucket.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchBucket.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, bucketData: payload, error: '' };
      },
      [startFetchBucket.rejected]: (state, { payload }) => {
        return { ...state, loading: false, bucketData: '', error: payload };
      },

      [startBucketsClusterPoints.pending]: (state) => {
        return { ...state, loadingModal: true, error: '' };
      },
      [startBucketsClusterPoints.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, bucketsClusterPoints: payload, error: '' };
      },
      [startBucketsClusterPoints.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, bucketsClusterPoints: [], error: payload };
      },

      [startRemoveOperator.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, removeOperator: payload, error: '' };
      },
      [startRemoveOperator.rejected]: (state, { payload }) => {
        return { ...state, loading: false, removeOperator: '', error: payload };
      },

      //Heading Functions
      [setSelectedOrderOption.fulfilled]: (state, { payload }) => {
        return { ...state, selectedOrderOption: payload, currentPage: 1 };
      },
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      }
    },
});


export const propertyBucketActions = {
    ...propertyBucketSlice.actions,
    startFetchBuckets,
    startFetchSearchFields,
    startCreateBucket,
    startUpdateBucket,
    startBucketInfo,
    startDeleteBucket,
    startCopyBucket,
    startDeleteFile,
    startBucketPhotos,
    startFetchDataFields,
    startFetchBucket,
    startBucketsClusterPoints,
    startRemoveOperator,

    startFetchSubcategory,
    startFetchMunicipalities,
    startFetchAreas,

    setSelectedOrderOption,
    setSearch,
    setCurrentPage
}
export default propertyBucketSlice.reducer