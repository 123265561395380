import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, PROPERTY_MUNICIPALITIES } from '../../constants/defaultValues';

const startFetchDataFields = createAsyncThunk('propertyMunicipality/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_MUNICIPALITIES}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataSelectFields = createAsyncThunk('propertyMunicipality/startFetchDataSelectFields', async ({ locale, token, ifEdit, country_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_MUNICIPALITIES}/selectField?country_id=${country_id}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPropertyMunicipalities = createAsyncThunk('propertyMunicipality/startFetchPropertyMunicipalities', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${PROPERTY_MUNICIPALITIES}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreatePropertyMunicipality = createAsyncThunk('propertyMunicipality/startCreatePropertyMunicipality', async ({ propertyMunicipality, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_MUNICIPALITIES}/store`, propertyMunicipality, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdatePropertyMunicipality = createAsyncThunk('propertyMunicipality/startUpdatePropertyMunicipality', async ({ id, propertyMunicipality, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_MUNICIPALITIES}/update/${id}`, propertyMunicipality, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeletePropertyMunicipality = createAsyncThunk('propertyMunicipality/startDeletePropertyMunicipality', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${PROPERTY_MUNICIPALITIES}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActivePropertyMunicipality = createAsyncThunk('propertyMunicipality/startActivePropertyMunicipality', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${PROPERTY_MUNICIPALITIES}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPropertyMunicipality = createAsyncThunk('propertyMunicipality/startFetchPropertyMunicipality', async ({ propertyMunicipality, history, locale, token }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_MUNICIPALITIES}/edit/${propertyMunicipality}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            municipality_en: data.municipality_en,
            municipality_el: data.municipality_el,
            dom_city_id: data.dom_city_id,
            xe_city_id:  data.xe_city_id,
            fer_city_id:  data.fer_city_id,
            country_id: data.country_id,
            prefecture_id: data.prefecture_id,
            geometry_location_id: data.geometry_location_id,
            kml: data.kml,
            lat: data.lat,
            lng: data.lng,
            zoom: data.zoom,
            portal: data.portal === 1 ? true : false
        };

        dispatch(propertyMunicipalitySlice.actions.dataSelectFields({prefectures: data.prefectures}))

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('propertyMunicipality/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('propertyMunicipality/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('propertyMunicipality/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    propertyMunicipalities: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    createPropertyMunicipality: '',
    updatePropertyMunicipality: '',
    deletePropertyMunicipality: '',
    propertyMunicipalityData: {
        edit:false,
        municipality_en: '',
        municipality_el: '',
        lat: '37.9815336',
        lng: '23.7493355',
        geometry_location_id: '',
        kml: '',
        zoom: 6,
        country_id: '',
        prefecture_id: '',
        dom_city_id: '',
        xe_city_id: '',
        fer_city_id: '',
        portal: false
    },
    dataSelectFields: {
        prefectures: []
    },
    dataFields: {
        countries: [],
        geometry_locations: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const propertyMunicipalitySlice = createSlice({
    name: 'propertyMunicipality',
    initialState,
    reducers: {
        fetchPropertyMunicipalitiesSuccess(state, { payload }) {
            return { ...state, loading: false, propertyMunicipalities: payload, error: '' };
        },
        dataSelectFields(state, { payload }){
            return { ...state, dataSelectFields: payload, error: '' };
        },
        clearPropertyMunicipality(state) {
            return { ...state, loadingBtn: false, loading: false, propertyMunicipalityData: initialState.propertyMunicipalityData, dataSelectFields: initialState.dataSelectFields, error: '' };
        },
        clearAlertPropertyMunicipality(state) {
            return { ...state, updatePropertyMunicipality: '', createPropertyMunicipality: '', deletePropertyMunicipality: '', activePropertyMunicipality: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchPropertyMunicipalities.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchPropertyMunicipalities.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, propertyMunicipalities: payload, error: '' };
      },
      [startFetchPropertyMunicipalities.rejected]: (state, { payload }) => {
        return { ...state, loading: false, propertyMunicipalities: initialState.propertyMunicipalities, error: payload };
      },

      [startFetchPropertyMunicipality.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchPropertyMunicipality.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, propertyMunicipalityData: payload, error: '' };
      },
      [startFetchPropertyMunicipality.rejected]: (state, { payload }) => {
        return { ...state, loading: false, propertyMunicipalityData: '', error: payload };
      },
      
      [startCreatePropertyMunicipality.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreatePropertyMunicipality.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createPropertyMunicipality: payload, error: '' };
      },
      [startCreatePropertyMunicipality.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createPropertyMunicipality: '', error: payload };
      },

      [startUpdatePropertyMunicipality.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdatePropertyMunicipality.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updatePropertyMunicipality: payload, error: '' };
      },
      [startUpdatePropertyMunicipality.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updatePropertyMunicipality: '', error: payload };
      },

      [startDeletePropertyMunicipality.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deletePropertyMunicipality: payload, error: '' };
      },
      [startDeletePropertyMunicipality.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deletePropertyMunicipality: '', error: payload };
      },

      [startActivePropertyMunicipality.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activePropertyMunicipality: payload, error: '' };
      },
      [startActivePropertyMunicipality.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activePropertyMunicipality: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload }; 
      },

      [startFetchDataSelectFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataSelectFields: payload, error: '' };
      },
      [startFetchDataSelectFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataSelectFields: initialState.dataSelectFields, error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const propertyMunicipalityActions = {
    ...propertyMunicipalitySlice.actions,
    startFetchPropertyMunicipalities,
    startFetchPropertyMunicipality,
    startCreatePropertyMunicipality,
    startUpdatePropertyMunicipality,
    startDeletePropertyMunicipality,
    startActivePropertyMunicipality,
    startFetchDataFields,
    startFetchDataSelectFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default propertyMunicipalitySlice.reducer