import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, INDICATIONS } from '../../constants/defaultValues';

const startFetchIndications = createAsyncThunk('indication/startFetchIndications', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${INDICATIONS}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteIndication = createAsyncThunk('indication/startDeleteIndication', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${INDICATIONS}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startPdfIndication = createAsyncThunk('indication/startPdfIndication', async ({ locale, token, id }, { rejectWithValue, dispatch }) => {
    try {
        dispatch(indicationSlice.actions.actionPdfIndication(id));
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${INDICATIONS}/pdf/${id}`, { headers });

        let pdfWindow = window.open("Indication Order", "_blank", "toolbar=1, scrollbars=1, resizable=1, width=" + 600 + ", height=" + 800);
        pdfWindow.document.write("<html<head><title>ReWize</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
        pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(res.data)+"#toolbar=1&navpanes=0&scrollbar=0'></embed></body></html>");

        return true;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('indication/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('indication/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('indication/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    indications: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:true, id: "id"}],
    deleteIndication: '',
    pdfIndication: '',
    loadingPdfBtn: false,
    loadingBtn: false,
    loading: true,
    error: ''
}

export const indicationSlice = createSlice({
    name: 'indication',
    initialState,
    reducers: {
        fetchIndicationsSuccess(state, { payload }) {
            return { ...state, loading: false, indications: payload, error: '' };
        },
        actionPdfIndication(state, { payload }) {
            return { ...state, loadingPdfBtn: true, pdfIndication: payload, error: '' };
        },
        clearAlertIndication(state) {
            return { ...state, deleteIndication: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchIndications.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchIndications.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, indications: payload, error: '' };
      },
      [startFetchIndications.rejected]: (state, { payload }) => {
        return { ...state, loading: false, error: payload };
      },

      [startDeleteIndication.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteIndication: payload, error: '' };
      },
      [startDeleteIndication.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteIndication: '', error: payload };
      },

  
      [startPdfIndication.fulfilled]: (state) => {
        return { ...state, loadingPdfBtn: false, pdfIndication: '', error: '' };
      },
      [startPdfIndication.rejected]: (state, { payload }) => {
        return { ...state, loadingPdfBtn: false, pdfIndication: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const indicationActions = {
    ...indicationSlice.actions,
    startFetchIndications,
    startDeleteIndication,
    startPdfIndication,
    setSearch,
    setCurrentPage,
    setSort
}
export default indicationSlice.reducer