import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, ARTICLES } from '../../constants/defaultValues';

const startFetchDataFields = createAsyncThunk('article/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${ARTICLES}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchArticles = createAsyncThunk('article/startFetchArticles', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${ARTICLES}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateArticle = createAsyncThunk('article/startCreateArticle', async ({ article, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${ARTICLES}/store`, article, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateArticle = createAsyncThunk('article/startUpdateArticle', async ({ id, article, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${ARTICLES}/update/${id}`, article, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteArticle = createAsyncThunk('article/startDeleteArticle', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${ARTICLES}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveArticle = createAsyncThunk('article/startActiveArticle', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${ARTICLES}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchArticle = createAsyncThunk('article/startFetchArticle', async ({article, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${ARTICLES}/edit/${article}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            name: data.name,
            langs: data.langs,
            article_category_id: data.article_category_id,
            author_id: data.author_id,
            reading_time: data.reading_time,
            template: data.template,
            image_or_yt: data.image_or_yt,
            bottom_img_yt_multiply: data.bottom_img_yt_multiply,
            extra_text: data.extra_text ? true : false,
            url_en: data.url_en ? data.url_en : '',
            url_el: data.url_el ? data.url_el : '',
            title_en: data.title_en ? data.title_en : '',
            title_el: data.title_el ? data.title_el : '',
            text_en: data.text_en ? data.text_en : '',
            text_el: data.text_el ? data.text_el : '',
            subtext_en: data.subtext_en ? data.subtext_en : '',
            subtext_el: data.subtext_el ? data.subtext_el : '',
            meta_title_en: data.page_config ? data.page_config.meta_title_en : '',
            meta_title_el: data.page_config ? data.page_config.meta_title_el : '',
            meta_desc_en: data.page_config ? data.page_config.meta_desc_en : '',
            meta_desc_el: data.page_config ? data.page_config.meta_desc_el : '',
            meta_lsi_en: data.page_config ? data.page_config.meta_lsi_en : [],
            meta_lsi_el: data.page_config ? data.page_config.meta_lsi_el : [],
            youtube_url: data.youtube_url,
            youtube_url_bottom: data.youtube_url_bottom,
            sort: data.sort,
            image: data.image,
            image_name: data.image,
            image_bottom: data.image_bottom,
            image_bottom_name: data.image_bottom,
            images_bottom_multiply: data.images_bottom_multiply
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('article/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('article/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('article/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    articles: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    articleData: {
        name: '',
        langs: [],
        article_category_id: '',
        author_id: '',
        reading_time: '',
        template: 1,
        image_or_yt: 0,
        bottom_img_yt_multiply: 0,
        extra_text: false,
        sort: 0,
        url_en: '',
        url_el: '',
        title_en: '',
        title_el: '',
        text_en: '',
        text_el: '',
        subtext_en: '',
        subtext_el: '',
        meta_title_en: '',
        meta_title_el: '',
        meta_desc_en: '',
        meta_desc_el: '',
        meta_lsi_en: [],
        meta_lsi_el: [],
        youtube_url: '',
        youtube_url_bottom: '',
        image: '',
        image_name: '',
        image_bottom: '',
        image_bottom_name: '',
        images_bottom_multiply: []
    },
    dataFields: {
        article_categories: [],
        languages: [],
        authors: []
    },
    createArticle: '',
    updateArticle: '',
    deleteArticle: '',
    loadingBtn: false,
    loading: true,
    error: ''
}

export const articleSlice = createSlice({
    name: 'article',
    initialState,
    reducers: {
        fetchArticlesSuccess(state, { payload }) {
            return { ...state, loading: false, articles: payload, error: '' };
        },
        clearArticle(state) {
            return { ...state, loadingBtn: false, loading: false, articleData: initialState.articleData, error: '' };
        },
        clearAlertArticle(state) {
            return { ...state, updateArticle: '', createArticle: '', deleteArticle: '', activeArticle: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchArticles.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchArticles.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, articles: payload, error: '' };
      },
      [startFetchArticles.rejected]: (state, { payload }) => {
        return { ...state, loading: false, error: payload };
      },

      [startFetchArticle.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchArticle.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, articleData: payload, error: '' };
      },
      [startFetchArticle.rejected]: (state, { payload }) => {
        return { ...state, loading: false, articleData: '', error: payload };
      },
      
      [startCreateArticle.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateArticle.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createArticle: payload, error: '' };
      },
      [startCreateArticle.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createArticle: '', error: payload };
      },

      [startUpdateArticle.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateArticle.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateArticle: payload, error: '' };
      },
      [startUpdateArticle.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateArticle: '', error: payload };
      },

      [startDeleteArticle.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteArticle: payload, error: '' };
      },
      [startDeleteArticle.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteArticle: '', error: payload };
      },

      [startActiveArticle.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeArticle: payload, error: '' };
      },
      [startActiveArticle.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeArticle: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, error: payload }; 
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const articleActions = {
    ...articleSlice.actions,
    startFetchArticles,
    startFetchArticle,
    startCreateArticle,
    startUpdateArticle,
    startDeleteArticle,
    startActiveArticle,
    startFetchDataFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default articleSlice.reducer