import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, FAQS } from '../../constants/defaultValues';

const startFetchFaqs = createAsyncThunk('faq/startFetchFaqs', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${FAQS}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateFaq = createAsyncThunk('faq/startCreateFaq', async ({ faq, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${FAQS}/store`, faq, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateFaq = createAsyncThunk('faq/startUpdateFaq', async ({ id, faq, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${FAQS}/update/${id}`, faq, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteFaq = createAsyncThunk('faq/startDeleteFaq', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${FAQS}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveFaq = createAsyncThunk('faq/startActiveFaq', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${FAQS}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchFaq = createAsyncThunk('faq/startFetchFaq', async ({ faq, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${FAQS}/edit/${faq}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            title_en: data.title_en,
            title_el: data.title_el,
            text_en: data.text_en,
            text_el: data.text_el,
            category: data.category,
            sort: data.sort
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('faq/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('faq/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('faq/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    faqs: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    faqData: {
        sort: 0,
        title_en: '',
        title_el: '',
        text_en: '',
        text_el: '',
        category: ''
    },
    createFaq: '',
    updateFaq: '',
    deleteFaq: '',
    loadingBtn: false,
    loading: true,
    error: ''
}

export const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {
        fetchFaqsSuccess(state, { payload }) {
            return { ...state, loading: false, faqs: payload, error: '' };
        },
        clearFaq(state) {
            return { ...state, loadingBtn: false, loading: false, faqData: initialState.faqData, error: '' };
        },
        clearAlertFaq(state) {
            return { ...state, updateFaq: '', createFaq: '', deleteFaq: '', activeFaq: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchFaqs.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchFaqs.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, faqs: payload, error: '' };
      },
      [startFetchFaqs.rejected]: (state, { payload }) => {
        return { ...state, loading: false, faqs: initialState.faqs, error: payload };
      },

      [startFetchFaq.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchFaq.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, faqData: payload, error: '' };
      },
      [startFetchFaq.rejected]: (state, { payload }) => {
        return { ...state, loading: false, faqData: '', error: payload };
      },
      
      [startCreateFaq.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateFaq.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createFaq: payload, error: '' };
      },
      [startCreateFaq.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createFaq: '', error: payload };
      },

      [startUpdateFaq.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateFaq.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateFaq: payload, error: '' };
      },
      [startUpdateFaq.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateFaq: '', error: payload };
      },

      [startDeleteFaq.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteFaq: payload, error: '' };
      },
      [startDeleteFaq.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteFaq: '', error: payload };
      },

      [startActiveFaq.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeFaq: payload, error: '' };
      },
      [startActiveFaq.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeFaq: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});

export const faqActions = {
    ...faqSlice.actions,
    startFetchFaqs,
    startFetchFaq,
    startCreateFaq,
    startUpdateFaq,
    startDeleteFaq,
    startActiveFaq,
    setSearch,
    setCurrentPage,
    setSort
}
export default faqSlice.reducer