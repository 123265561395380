import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
// import CacheBuster from 'react-cache-buster';
// import packageJson from '../package.json';
// import { getBuildDate } from "./helpers/Utils";
import withClearCache from "./ClearCache";

const App = React.lazy(() => import(/* webpackChunkName: "App" */'./App'));

// const isProduction = process.env.NODE_ENV === 'production';

const ClearCacheComponent = withClearCache(MainApp);

function MainApp(props) {
  return (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<div className="loading-overlay" />}>
            <App />
          </Suspense>
        </PersistGate>
      </Provider>
  );
}

ReactDOM.render(
    // <CacheBuster
    //   currentVersion={packageJson.version}
    //   isEnabled={isProduction} //If false, the library is disabled.
    //   isVerboseMode={false} //If true, the library writes verbose logs to console.
    //   loadingComponent={<div className="loading-overlay" />} //If not pass, nothing appears at the time of new version check.
    //   metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    // >
    <ClearCacheComponent />,
    // </CacheBuster>,
  document.getElementById('root')
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
