import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, AUCTION_PROPERTY } from '../../constants/defaultValues';

const startFetchDataFields = createAsyncThunk('auctionProperty/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${AUCTION_PROPERTY}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchAuctionProperties = createAsyncThunk('auctionProperty/startFetchAuctionProperties', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${AUCTION_PROPERTY}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteAuctionProperty = createAsyncThunk('auctionProperty/startDeleteAuctionProperty', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${AUCTION_PROPERTY}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startSyncAuctionProperty = createAsyncThunk('auctionProperty/startSyncAuctionProperty', async ({ locale, token, id, auctionProperties }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${AUCTION_PROPERTY}/sync/${id}`, { headers });

        let new_data = [];

        auctionProperties.data.forEach(item => {
            let new_item = {...item};

            if(parseInt(new_item.id) === parseInt(id)){
              new_item = res.data.item;
            }

            new_data.push(new_item)
        });

        let newArray = {
            data: new_data,
            totalItemCount: auctionProperties.totalItemCount
        }

        return { status: res.data.status, data: newArray };
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchAuctionProperty = createAsyncThunk('auctionProperty/startFetchAuctionProperty', async ({ id, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${AUCTION_PROPERTY}/edit/${id}`, { headers });

        return res.data;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateAuctionProperty = createAsyncThunk('auctionProperty/startUpdateAuctionProperty', async ({ id, auctionProperty, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${AUCTION_PROPERTY}/update/${id}`, auctionProperty, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSubcategory = createAsyncThunk('auctionProperty/startFetchSubcategory', async ({ locale, token, ifEdit, category_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${AUCTION_PROPERTY}/selectSubcategory?category_id=${category_id}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchMunicipalities = createAsyncThunk('auctionProperty/startFetchMunicipalities', async ({ locale, token, ifEdit, prefecture_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${AUCTION_PROPERTY}/getMunicipality?prefecture_id=${prefecture_id}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchAreas = createAsyncThunk('auctionProperty/startFetchAreas', async ({ locale, token, ifEdit, prefecture_id, municipality_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${AUCTION_PROPERTY}/getArea?prefecture_id=${prefecture_id}&municipality_id=${municipality_id}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPropMatching = createAsyncThunk('auctionProperty/startFetchPropMatching', async ({ id, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${AUCTION_PROPERTY}/fetchAuctionsMatching/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startAddCycle = createAsyncThunk('auctionProperty/startAddCycle', async ({ id, auction_id, cycle, matchingProps, auctionProperties, locale, token, toggle }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${AUCTION_PROPERTY}/addCycle/${id}`, {auction_id: auction_id, cycle: cycle}, { headers });

        let new_data = [];

        matchingProps.forEach(item => {
            let new_item = {...item};

            if(parseInt(new_item.id) === parseInt(id)){
              new_item = res.data.item;
            }

            new_data.push(new_item)
        });

        let new_data_list = [];

        auctionProperties.data.forEach(item => {
            let new_item_list = {...item};

            if(parseInt(new_item_list.id) === parseInt(id)){
                new_item_list = res.data.listItem;
            }

            new_data_list.push(new_item_list)
        });

        const newArray = {
            data: new_data_list,
            totalItemCount: auctionProperties.totalItemCount
        }

        toggle();

        return {status: res.data.status, matchingProps: new_data, auctionProperties: newArray };
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions

const setCurrentPage = createAsyncThunk('auctionProperty/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('auctionProperty/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    auctionProperties: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    currentPage: 1,
    sort: [{ desc: false, id: "id" }],
    deleteAuctionProperty: '',
    syncAuctionProperty: '',
    updateAuctionProperty: '',
    addCycle: '',
    auctionPropertyData: {},
    dataFields: {
        categories: [],
        pools: [],
        views: [],
        viewsRates: [],
        prefectures: [],
        plans: [],
        energy_class: [],
        heating_sources: [],
        heating_facilities: [],
        buildables: []
    },
    subcategories: [],
    municipalities: [],
    areas: [],
    propMatching: [],
    loadingBtn: false,
    loadingPropModal: false,
    loadingCycleModalBtn: false,
    loading: true,
    error: ''
}

export const auctionPropertySlice = createSlice({
    name: 'auctionProperty',
    initialState,
    reducers: {
        fetchAuctionPropertiesSuccess(state, { payload }) {
            return { ...state, loading: false, auctionProperties: payload, error: '' };
        },
        clearAuctionProperties(state) {
            return { ...state,  loadingPropModal: false, loadingCycleModalBtn: false, loadingBtn: false, loading: false, auctionPropertyData: {} };
        },
        clearAlertAuctionProperty(state) {
            return { ...state, deleteAuctionProperty: '', syncAuctionProperty: '', updateAuctionProperty: '', addCycle: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        dataSelectSubcategory(state, { payload }) {
            return { ...state, subcategories: payload, error: '' };
        },
        dataSelectAreas(state, { payload }) {
            return { ...state, areas: payload, error: '' };
        },
        dataSelectMunicipalities(state, { payload }) {
            return { ...state, municipalities: payload, error: '' };
        },
    },
    extraReducers: {
        [startFetchDataFields.pending]: (state) => {
            return { ...state, loading: true, error: '' };
        },
        [startFetchDataFields.fulfilled]: (state, { payload }) => {
            return { ...state, dataFields: payload, error: '' };
        },
        [startFetchDataFields.rejected]: (state, { payload }) => {
            return { ...state, loading: false, dataFields: initialState.dataFields, error: payload }; 
        },

        [startFetchAuctionProperties.pending]: (state) => {
            return { ...state, loading: true, error: '' };
        },
        [startFetchAuctionProperties.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false, auctionProperties: payload, error: '' };
        },
        [startFetchAuctionProperties.rejected]: (state, { payload }) => {
            return { ...state, loading: false, auctionProperties: initialState.auctionProperties, error: payload };
        },

        [startFetchAuctionProperty.pending]: (state) => {
            return { ...state, loading: true, error: '' };
        },
        [startFetchAuctionProperty.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false, auctionPropertyData: payload.data, subcategories: payload.subcategories, municipalities: payload.municipalities, areas: payload.areas, error: '' };
        },
        [startFetchAuctionProperty.rejected]: (state, { payload }) => {
            return { ...state, loading: false, auctionPropertyData: {}, subcategories: initialState.subcategories, municipalities: initialState.municipalities, areas: initialState.areas, error: payload };
        },

        [startDeleteAuctionProperty.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false, deleteAuctionProperty: payload, error: '' };
        },
        [startDeleteAuctionProperty.rejected]: (state, { payload }) => {
            return { ...state, loading: false, deleteAuctionProperty: '', error: payload };
        },

        [startSyncAuctionProperty.pending]: (state) => {
            return { ...state, loadingBtn: true, error: '' };
        },
        [startSyncAuctionProperty.fulfilled]: (state, { payload }) => {
            return { ...state, loadingBtn: false, auctionProperties: payload.data, syncAuctionProperty: payload.status, error: '' };
        },
        [startSyncAuctionProperty.rejected]: (state, { payload }) => {
            return { ...state, loadingBtn: false, syncAuctionProperty: '', error: payload };
        },

        [startUpdateAuctionProperty.pending]: (state) => {
            return { ...state, loadingBtn: true, error: '' };
        },
        [startUpdateAuctionProperty.fulfilled]: (state, { payload }) => {
            return { ...state, loadingBtn: false, updateAuctionProperty: payload, error: '' };
        },
        [startUpdateAuctionProperty.rejected]: (state, { payload }) => {
            return { ...state, loadingBtn: false, updateAuctionProperty: '', error: payload };
        },

        [startFetchSubcategory.fulfilled]: (state, { payload }) => {
            return { ...state, subcategories: payload, error: '' };
        },

        [startFetchSubcategory.rejected]: (state, { payload }) => {
            return { ...state, subcategory: initialState.subcategory, error: payload };
        },

        [startFetchMunicipalities.fulfilled]: (state, { payload }) => {
            return { ...state, municipalities: payload, error: '' };
        },
        [startFetchMunicipalities.rejected]: (state, { payload }) => {
            return { ...state, municipalities: initialState.municipalities, error: payload };
        },

        [startFetchAreas.fulfilled]: (state, { payload }) => {
            return { ...state, areas: payload, error: '' };
        },
        [startFetchAreas.rejected]: (state, { payload }) => {
            return { ...state, areas: initialState.areas, error: payload };
        },

        [startFetchPropMatching.pending]: (state) => {
            return { ...state, loadingPropModal: true, error: '' };
        },
        [startFetchPropMatching.fulfilled]: (state, { payload }) => {
            return { ...state, loadingPropModal: false, propMatching: payload, error: '' };
        },
        [startFetchPropMatching.rejected]: (state, { payload }) => {
            return { ...state, loadingPropModal: false, propMatching: [], error: payload };
        },

        [startAddCycle.pending]: (state) => {
            return { ...state, loadingCycleModalBtn: true, error: '' };
        },
        [startAddCycle.fulfilled]: (state, { payload }) => {
            return { ...state, loadingCycleModalBtn: false, addCycle: payload.status, propMatching: payload.matchingProps, auctionProperties: payload.auctionProperties, error: '' };
        },
        [startAddCycle.rejected]: (state, { payload }) => {
            return { ...state, loadingCycleModalBtn: false, addCycle: '', error: payload };
        },

        //Heading Functions
        [setCurrentPage.fulfilled]: (state, { payload }) => {
            return { ...state, currentPage: payload }
        },
        [setSort.fulfilled]: (state, { payload }) => {
            return { ...state, sort: payload }
        }
    },
});


export const auctionPropertyActions = {
    ...auctionPropertySlice.actions,
    startFetchAuctionProperties,
    startFetchAuctionProperty,
    startDeleteAuctionProperty,
    startSyncAuctionProperty,
    startFetchDataFields,
    startUpdateAuctionProperty,
    startFetchSubcategory,
    startFetchMunicipalities,
    startFetchAreas,
    startFetchPropMatching,
    startAddCycle,
    setCurrentPage,
    setSort
}
export default auctionPropertySlice.reducer