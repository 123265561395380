import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, CHILDREN_MENUS } from '../../constants/defaultValues';

const startFetchDataFields = createAsyncThunk('childrenMenu/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CHILDREN_MENUS}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataSelectFields = createAsyncThunk('childrenMenu/startFetchDataSelectFields', async ({ locale, token, ifEdit, parent_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CHILDREN_MENUS}/selectField?parentId=${parent_id}&lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchChildrenMenus = createAsyncThunk('childrenMenu/startFetchChildrenMenus', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${CHILDREN_MENUS}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateChildrenMenu = createAsyncThunk('childrenMenu/startCreateChildrenMenu', async ({ children_menu, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CHILDREN_MENUS}/store`, children_menu, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateChildrenMenu = createAsyncThunk('childrenMenu/startUpdateChildrenMenu', async ({ id, children_menu, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CHILDREN_MENUS}/update/${id}`, children_menu, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteChildrenMenu = createAsyncThunk('childrenMenu/startDeleteChildrenMenu', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${CHILDREN_MENUS}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveChildrenMenu = createAsyncThunk('childrenMenu/startActiveChildrenMenu', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${CHILDREN_MENUS}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchChildrenMenu = createAsyncThunk('childrenMenu/startFetchChildrenMenu', async ({ children_menu, history, locale, token }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CHILDREN_MENUS}/edit/${children_menu}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            children_menu_en: data.children_menu_en,
            children_menu_el: data.children_menu_el,
            ch_link_en: data.ch_link_en,
            ch_link_el: data.ch_link_el,
            parent_menu_id: data.parent_menu_id,
            section_menu_id: data.section_menu_id,
            target_blank: data.target_blank === 1 ? true : false,
            footer: data.footer === 1 ? true : false,
            sort: data.sort
        };

        dispatch(startFetchDataSelectFields({ locale: locale, token: token, ifEdit: 1, parent_id: data.parent_menu_id }));

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('childrenMenu/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('childrenMenu/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('childrenMenu/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    childrenMenus: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    childrenMenuData: {
        children_menu_en: '',
        children_menu_el: '',
        ch_link_en: '',
        ch_link_el: '',
        parent_menu_id: '',
        section_menu_id: '',
        target_blank: '',
        footer: '',
        sort: 0
    },
    createChildrenMenu: '',
    updateChildrenMenu: '',
    deleteChildrenMenu: '',
    dataSelectFields: {
        section_menus: []
    },
    dataFields: {
        parent_menus: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const childrenMenuSlice = createSlice({
    name: 'childrenMenu',
    initialState,
    reducers: {
        fetchChildrenMenusSuccess(state, { payload }) {
            return { ...state, loading: false, childrenMenus: payload, error: '' };
        },
        clearChildrenMenu(state) {
            return { ...state, loadingBtn: false, loading: false, childrenMenuData: initialState.childrenMenuData, error: '' };
        },
        clearAlertChildrenMenu(state) {
            return { ...state, updateChildrenMenu: '', createChildrenMenu: '', deleteChildrenMenu: '', activeChildrenMenu: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchChildrenMenus.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchChildrenMenus.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, childrenMenus: payload, error: '' };
      },
      [startFetchChildrenMenus.rejected]: (state, { payload }) => {
        return { ...state, loading: false, childrenMenus: initialState.childrenMenus, error: payload };
      },

      [startFetchChildrenMenu.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchChildrenMenu.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, childrenMenuData: payload, error: '' };
      },
      [startFetchChildrenMenu.rejected]: (state, { payload }) => {
        return { ...state, loading: false, childrenMenuData: '', error: payload };
      },
      
      [startCreateChildrenMenu.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateChildrenMenu.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createChildrenMenu: payload, error: '' };
      },
      [startCreateChildrenMenu.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createChildrenMenu: '', error: payload };
      },

      [startUpdateChildrenMenu.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateChildrenMenu.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateChildrenMenu: payload, error: '' };
      },
      [startUpdateChildrenMenu.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateChildrenMenu: '', error: payload };
      },

      [startDeleteChildrenMenu.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteChildrenMenu: payload, error: '' };
      },
      [startDeleteChildrenMenu.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteChildrenMenu: '', error: payload };
      },

      [startActiveChildrenMenu.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeChildrenMenu: payload, error: '' };
      },
      [startActiveChildrenMenu.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeChildrenMenu: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload }; 
      },

      [startFetchDataSelectFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataSelectFields: payload, error: '' };
      },
      [startFetchDataSelectFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataSelectFields: initialState.dataSelectFields, error: payload };    
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const childrenMenuActions = {
    ...childrenMenuSlice.actions,
    startFetchChildrenMenus,
    startFetchChildrenMenu,
    startCreateChildrenMenu,
    startUpdateChildrenMenu,
    startDeleteChildrenMenu,
    startActiveChildrenMenu,
    startFetchDataFields,
    startFetchDataSelectFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default childrenMenuSlice.reducer