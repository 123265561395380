import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, DEAL } from '../../constants/defaultValues';

const startCreateDeal = createAsyncThunk('deal/startCreateDeal', async ({ deal, locale, token, form }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${DEAL}/store`, deal, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        dispatch(dealSlice.actions.clearProperty());
        dispatch(dealSlice.actions.clearDeposits());
        dispatch(dealSlice.actions.clearRequest());

        return res.data.status;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateDeal = createAsyncThunk('deal/startUpdateDeal', async ({ id, deal, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${DEAL}/update/${id}`, deal, { headers });

        if(res.data){
            form.setFieldValue('files', []);
            form.setStatus({ success: true });
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false });
        form.setSubmitting(false);
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDeal = createAsyncThunk('deal/startFetchDeal', async ({ deal, history, locale, token }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${DEAL}/edit/${deal}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            id: data.data.id,
            property_id: data.data.property_id,
            request_id: data.data.request_id,
            amount: data.data.amount,
            status: data.data.status,
            collector: data.data.collector,
            deposit_amount: data.data.deposit_amount,
            pr_re_fee: data.data.pr_re_fee,
            pr_ext_re_fee: data.data.pr_ext_re_fee,
            rq_re_fee: data.data.rq_re_fee,
            rq_ext_re_fee: data.data.rq_ext_re_fee,
            contract_exp_date: data.data.contract_exp_date ? new Date(data.data.contract_exp_date) : null,

            notary_name: data.data.notary_name,
            notary_email: data.data.notary_email,
            lawyer_name: data.data.lawyer_name,
            lawyer_email: data.data.lawyer_email,

            phonesNotary: data.data.phonesNotary,
            mobilesNotary: data.data.mobilesNotary,
            phonesLawyer: data.data.phonesLawyer,
            mobilesLawyer: data.data.mobilesLawyer,

            files: [],
            filesData: data.data.filesData
        };

        return { data: results, details_property: data.details_property, details_request: data.details_request, deposits: data.deposits};
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDeals = createAsyncThunk('deal/startFetchDeals', async ({ locale, token, filter, loading }, { rejectWithValue, dispatch }) => {
    try {
        dispatch(dealSlice.actions.actionFetchDeals(loading))
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${DEAL}?
        pageSize=${filter.pageSize}
        &currentPage=${filter.currentPage}
        &orderBy=${filter.orderBy}
        &search=${filter.search}
        &agent_type=${filter.searchFilter.agent_type.length > 0 ? filter.searchFilter.agent_type.join() : ""}
        &status_type=${filter.searchFilter.status_type.length > 0 ? filter.searchFilter.status_type.join() : ""}
        &collector_type=${filter.searchFilter.collector_type.length > 0 ? filter.searchFilter.collector_type.join() : ""}
        &deposit_type=${filter.searchFilter.deposit_type.length > 0 ? filter.searchFilter.deposit_type.join() : ""}
        &transaction_type=${filter.searchFilter.transaction_type.length > 0 ? filter.searchFilter.transaction_type.join() : ""}
    
        &fromDateCreate_type=${filter.searchFilter.fromDateCreate_type}
        &toDateCreate_type=${filter.searchFilter.toDateCreate_type}
        &fromBudget_type=${filter.searchFilter.fromBudget_type}
        &toBudget_type=${filter.searchFilter.toBudget_type}
        `, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDealInfo = createAsyncThunk('deal/startDealInfo', async ({ deal, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${DEAL}/edit/${deal}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('deal/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${DEAL}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSearchFields = createAsyncThunk('deal/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${DEAL}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchProperty = createAsyncThunk('deal/startFetchProperty', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${DEAL}/fetchProperty`, {property_id: id}, { headers });

        return { details_property: res.data.details_property, deposits: res.data.deposits }
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchRequest = createAsyncThunk('deal/startFetchRequest', async ({ locale, token, id, setFieldValue }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${DEAL}/fetchRequest`, {request_id: id}, { headers });

        setFieldValue("filesData", res.data.files)

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSelectedOrderOption = createAsyncThunk('deal/setSelectedOrderOption', ( args ) => {
    return args;
});

const setSearch = createAsyncThunk('deal/setSearch', ( args ) => {
    return args;
});

const setCurrentPage = createAsyncThunk('deal/setCurrentPage', ( args ) => {
    return args;
});

const setSort = createAsyncThunk('deal/setSort', ( args ) => {
    return args;
});

const initialState = {
    deals: {
        data: [],
        totalItemCount: 0
    },
    dealInfo: {},
    displayMode: "list",
    selectedPageSize: 10,
    selectedOrderOption: "",
    search: "",
    searchValue: "",
    searchFilterType: 1,
    stage: "",
    fields: [],
    searchFilter: [
        { translate: "general.agent", id: "agent_type", dataKey: "agent_types", search: "", type: 1 },
        { translate: "general.status", id: "status_type", dataKey: "status_types", search: "", type: 1 },
        { translate: "deposits.collector", id: "collector_type", dataKey: "collector_types", search: "", type: 1 },
        { translate: "menu.deposits-list", id: "deposit_type", dataKey: "deposit_types", search: "", type: 1 },
        { translate: "general.sale-rent", id: "transaction_type", dataKey: "transaction_types", search: "", type: 1 },
        { translate: "general.create-date", id: "dateCreate_type", dataKey: "", search: "", type: 1 },
        { translate: "general.budget", id: "budget_type", dataKey: "", search: "", type: 1 }
    ],
    searchFilterValues: {
        agent_type: [],
        status_type: [],
        collector_type: [],
        deposit_type: [],
        transaction_type: [],

        fromDateCreate_type: "",
        toDateCreate_type: "",
        fromBudget_type: "",
        toBudget_type: ""
    },
    currentPage: 1,
    dealData: {
        property_id: "",
        request_id: "",
        amount: "",
        status: 1,
        collector: "",
        deposit_amount: "",
        pr_re_fee: "",
        pr_ext_re_fee: "",
        rq_re_fee: "",
        rq_ext_re_fee: "",
        contract_exp_date: null,

        notary_name: "",
        notary_email: "",
        lawyer_name: "",
        lawyer_email: "",

        phonesNotary: [],
        mobilesNotary: [],
        phonesLawyer: [],
        mobilesLawyer: [],

        files: [],
        filesData: []
    },
    createDeal: '',
    updateDeal: '',
    details_property: {},
    details_request: {},
    deposits: [],
    dataFields: {
        status: [],
        collectors: [],
        files: [],
        countries: []
    },
    loadingBtn: false,
    loading: true,
    loadingModal: false,
    loadingSearch: false,
    loadingPropertyField: false,
    loadingRequestField: false,
    error: ''
}

export const dealSlice = createSlice({
    name: 'deal',
    initialState,
    reducers: {
        fetchDealsSuccess(state, { payload }) {
            return { ...state, loading: false, loadingSearch: false, deals: payload, error: '' };
        },
        clearDeal(state) {
            return { ...state, loadingBtn: false, loadingModal: false, loading: false, loadingSearch: false, loadingPropertyField: false, loadingRequestField: false, dealData: initialState.dealData, details_property: initialState.details_property, details_request: initialState.details_request, deposits: initialState.deposits, error: '' };
        },
        clearAlertDeal(state) {
            return { ...state, updateDeal: '', createDeal: '',  error: '' };
        },
        setDisplayMode(state, { payload }) {
            return { ...state, displayMode: payload };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 };
        },
        setFilter(state, { payload }) {
            return { ...state, searchFilterValues: payload };
        },
        setFilterType(state, { payload }) {
            return { ...state, searchFilterType: payload };
        },
        setFilterFieldSearch(state, { payload }) {
            return { ...state, fields: payload.dataValues, searchFilter: payload.values };
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
        actionFetchDeals(state, { payload }) {
            return { ...state, loading: payload ? true : false, loadingSearch: true, error: '' };
        },
        clearProperty(state) {
            return { ...state, details_property: {} };
        },
        clearDeposits(state) {
            return { ...state, deposits: [] };
        },
        clearRequest(state) {
            return { ...state, details_request: {} };
        }
    },
    extraReducers: {
      [startCreateDeal.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateDeal.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createDeal: payload, error: '' };
      },
      [startCreateDeal.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createDeal: '', error: payload };
      },

      [startUpdateDeal.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateDeal.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateDeal: payload, error: '' };
      },
      [startUpdateDeal.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateDeal: '', error: payload };
      },
      
      [startFetchDeal.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDeal.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, dealData: payload.data, details_property: payload.details_property, details_request: payload.details_request, deposits: payload.deposits, error: '' };
      },
      [startFetchDeal.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dealData: initialState.dealData, details_property: initialState.details_property, details_request: initialState.details_request, deposits: initialState.deposits, error: payload };
      },
  
      [startFetchDeals.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, loadingSearch: false, deals: payload, error: '' };
      },
      [startFetchDeals.rejected]: (state, { payload }) => {
        return { ...state, loading: false, loadingSearch: false, deals: initialState.deals, error: payload };
      },

      [startDealInfo.pending]: (state) => {
        return { ...state, loadingModal: true, error: '' };
      },
      [startDealInfo.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, dealInfo: payload, error: '' };
      },
      [startDealInfo.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, dealInfo: {}, error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchSearchFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSearchFields.fulfilled]: (state, { payload }) => {
        return { ...state, fields: payload, error: '' };
      },
      [startFetchSearchFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, fields: initialState.fields, error: payload };
      },

      [startFetchProperty.pending]: (state) => {
        return { ...state, loadingPropertyField: true, error: '' };
      },
      [startFetchProperty.fulfilled]: (state, { payload }) => {
        return { ...state, loadingPropertyField: false, details_property: payload.details_property, deposits: payload.deposits, error: '' };
      },
      [startFetchProperty.rejected]: (state, { payload }) => {
        return { ...state, loadingPropertyField: false, details_property: initialState.details_property, deposits: initialState.deposits, error: payload };
      },

      [startFetchRequest.pending]: (state) => {
        return { ...state, loadingRequestField: true, error: '' };
      },
      [startFetchRequest.fulfilled]: (state, { payload }) => {
        return { ...state, loadingRequestField: false, details_request: payload.details_request, error: '' };
      },
      [startFetchRequest.rejected]: (state, { payload }) => {
        return { ...state, loadingRequestField: false, details_request: initialState.details_request, error: payload };
      },

      //Heading Functions
      [setSelectedOrderOption.fulfilled]: (state, { payload }) => {
        return { ...state, selectedOrderOption: payload, currentPage: 1 };
      },
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const dealActions = {
    ...dealSlice.actions,
    startCreateDeal,
    startUpdateDeal,
    startFetchDeal,
    startFetchDeals,
    startDealInfo,
    startFetchDataFields,
    startFetchSearchFields,

    startFetchProperty,
    startFetchRequest,

    setSelectedOrderOption,
    setSearch,
    setCurrentPage,
    setSort
}
export default dealSlice.reducer