import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, COMPLEXES } from '../../constants/defaultValues';

const startFetchDataFields = createAsyncThunk('complex/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${COMPLEXES}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchComplexes = createAsyncThunk('complex/startFetchComplexes', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${COMPLEXES}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateComplex = createAsyncThunk('complex/startCreateComplex', async ({ complex, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${COMPLEXES}/store`, complex, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateComplex = createAsyncThunk('complex/startUpdateComplex', async ({ id, complex, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${COMPLEXES}/update/${id}`, complex, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteComplex = createAsyncThunk('complex/startDeleteComplex', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${COMPLEXES}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveComplex = createAsyncThunk('complex/startActiveComplex', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${COMPLEXES}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchComplex = createAsyncThunk('complex/startFetchComplex', async ({ complex, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${COMPLEXES}/edit/${complex}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            name_en: data.name_en,
            name_el: data.name_el,
            prop_numbers: data.prop_numbers,
            sq_meters: data.sq_meters,
            sea_accessibility_type_id: data.sea_accessibility_type_id,
            lat: data.lat,
            lng: data.lng,
            zoom: data.zoom,
            geometric_location: data.geometric_location ? data.geometric_location : []
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPropComplex = createAsyncThunk('complex/startFetchPropContact', async ({ id, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${COMPLEXES}/fetchPropComplex/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('complex/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('complex/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('complex/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    complexes: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{ desc: false, id: "id" }],
    createComplex: '',
    updateComplex: '',
    deleteComplex: '',
    propComplex: [],
    complexData: {
        edit: false,
        name_en: '',
        name_el: '',
        lat: '37.9815336',
        lng: '23.7493355',
        zoom: 6,
        geometric_location: [],
        prop_numbers: '',
        sq_meters: '',
        sea_accessibility_type_id: ''
    },
    dataFields: {
        seaAccessibilities: []
    },
    loadingPropModal: false,
    loadingBtn: false,
    loading: true,
    error: ''
}

export const complexSlice = createSlice({
    name: 'complex',
    initialState,
    reducers: {
        fetchComplexesSuccess(state, { payload }) {
            return { ...state, loading: false, complexes: payload, error: '' };
        },
        clearComplex(state) {
            return { ...state, loadingBtn: false, loading: false, complexData: initialState.complexData, error: '' };
        },
        clearAlertComplex(state) {
            return { ...state, updateComplex: '', createComplex: '', deleteComplex: '', activeComplex: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchComplexes.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchComplexes.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, complexes: payload, error: '' };
      },
      [startFetchComplexes.rejected]: (state, { payload }) => {
        return { ...state, loading: false, complexes: initialState.complexes, error: payload };
      },

      [startFetchComplex.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchComplex.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, complexData: payload, error: '' };
      },
      [startFetchComplex.rejected]: (state, { payload }) => {
        return { ...state, loading: false, complexData: '', error: payload };
      },
      
      [startCreateComplex.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateComplex.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createComplex: payload, error: '' };
      },
      [startCreateComplex.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createComplex: '', error: payload };
      },

      [startUpdateComplex.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateComplex.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateComplex: payload, error: '' };
      },
      [startUpdateComplex.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateComplex: '', error: payload };
      },

      [startDeleteComplex.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteComplex: payload, error: '' };
      },
      [startDeleteComplex.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteComplex: '', error: payload };
      },

      [startActiveComplex.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeComplex: payload, error: '' };
      },
      [startActiveComplex.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeComplex: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload }; 
      },

      [startFetchPropComplex.pending]: (state) => {
        return { ...state, loadingPropModal: true, error: '' };
      },
      [startFetchPropComplex.fulfilled]: (state, { payload }) => {
        return { ...state, loadingPropModal: false, propComplex: payload, error: '' };
      },
      [startFetchPropComplex.rejected]: (state, { payload }) => {
        return { ...state, loadingPropModal: false, propComplex: [], error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const complexActions = {
    ...complexSlice.actions,
    startFetchComplexes,
    startFetchComplex,
    startCreateComplex,
    startUpdateComplex,
    startDeleteComplex,
    startActiveComplex,
    startFetchPropComplex,
    startFetchDataFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default complexSlice.reducer