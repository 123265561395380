import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, AUTH } from '../../constants/defaultValues';

const startLogin = createAsyncThunk('authUser/startLogin', async ( args, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = 'en';
        const response = await axios.post(`${AUTH}/login`, args, { headers });

        return response.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startForgotPassword = createAsyncThunk('authUser/startForgotPassword', async ( args, { rejectWithValue }) => {
    try {
        const res = await axios.post(`${AUTH}/forgot`, args, { HEADERS });

        args.email = '';
        return res.data.message;
    } catch (error) {
        args.email = '';
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCheckResetToken = createAsyncThunk('authUser/startCheckResetToken', async ( args, { rejectWithValue }) => {
    const { data, history} = args;
    try {
        const res = await axios.post(`${AUTH}/resetCheck`, data, { HEADERS });

        return res.data;
    } catch (error) {
        history.push('/');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startResetPassword = createAsyncThunk('authUser/startResetPassword', async ( args, { rejectWithValue }) => {
    const { data, history} = args;
    try {
        const res = await axios.post(`${AUTH}/reset`, data, { HEADERS });

        history.push('/');

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startSetPassword = createAsyncThunk('authUser/startSetPassword', async ( args, { rejectWithValue }) => {
    const { user_id, expires, signature, data, history} = args;
    try {
        const res = await axios.post(`${AUTH}/savePassword/${user_id}?expires=${expires}&signature=${signature}`, data, { HEADERS });

        history.push('/');

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startRefreshToken = createAsyncThunk('authUser/startRefreshToken', async ( args, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['Authorization'] = `Bearer ${args}`;
        const res = await axios.get(`${AUTH}/refreshToken`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const addLog = createAsyncThunk('authUser/addLog', async ( args, { rejectWithValue }) => {
    const { token, data } = args;
    const headers = HEADERS;
    try {
        headers['Authorization'] = `Bearer ${token}`;
        const res = await axios.post(`${AUTH}/addLog`, data, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const initialState = {
    author: null,
    forgotAuthorMail: '',
    checkResetToken: '',
    newPassword: '',
    setPassword: '',
    resetPasswordCode: '',
    loading: false,
    updateVersion: '',
    error: ''
}

export const authUserSlice = createSlice({
    name: 'authUser',
    initialState,
    reducers: {
        clearAlertAuth(state) {
            state.forgotAuthorMail= ''
            state.checkResetToken= ''
            state.newPassword= ''
            state.setPassword= ''
            state.resetPasswordCode= ''
            state.updateVersion= ''
            state.error= ''
        },
        clearAuth(state) {
            state.loading = false
            state.error= ''
        },
        loginAuthorError(state, { payload }) {
            state.author = null
            state.loading = false
            state.error= payload
        },
        logout(state, { payload }) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${payload}`;
            axios.post(`${AUTH}/logout`, null, { HEADERS });

            state.author = null
            state.error= ''
        },
        updateAuth(state, { payload }) {
            state.author = payload;
        },
        updateSuccess(state) {
            state.updateVersion = "success";
        },
        updateUserDataLogin(state, { payload }) {
            let authorLogin = {...state.author}
            authorLogin.user = payload;

            state.author = authorLogin;
        },
    },
    extraReducers: {
      [startLogin.pending]: (state) => {
        state.loading = true
        state.error = ''
      },
      [startLogin.fulfilled]: (state, { payload }) => {
        state.author = payload;
        state.error = '';
        state.loading = false;
      },
      [startLogin.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.author = null
      },

      [startForgotPassword.pending]: (state) => {
        state.loading = true
        state.error = ''
      },
      [startForgotPassword.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.error = '';
        state.forgotAuthorMail = payload;
      },
      [startForgotPassword.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.forgotAuthorMail = ''
      },

      [startCheckResetToken.pending]: (state) => {
        state.loading = true
        state.error = ''
      },
      [startCheckResetToken.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.error = '';
        state.checkResetToken = payload;
      },
      [startCheckResetToken.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.checkResetToken = ''
      },

      [startResetPassword.pending]: (state) => {
        state.loading = true
        state.error = ''
      },
      [startResetPassword.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.error = '';
        state.newPassword = payload;
        state.resetPasswordCode = '';
      },
      [startResetPassword.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.newPassword = '';
        state.resetPasswordCode = '';
      },

      [startSetPassword.pending]: (state) => {
        state.loading = true
        state.error = ''
      },
      [startSetPassword.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.error = '';
        state.setPassword = payload;
      },
      [startSetPassword.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.setPassword = '';
      },

      [startRefreshToken.pending]: (state) => {
        state.loading = true
        state.error = ''
      },
      [startRefreshToken.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.error = '';
        state.author = payload;
      },
      [startRefreshToken.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.author = null
      }
    },
});

export const authUserActions = {
    ...authUserSlice.actions,
    startLogin,
    startForgotPassword,
    startCheckResetToken,
    startResetPassword,
    startSetPassword,
    startRefreshToken,
    addLog
}
export default authUserSlice.reducer