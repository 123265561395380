import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, USER } from '../../constants/defaultValues';
import { authUserActions } from "../auth/authUserSlice";

const startFetchUsers = createAsyncThunk('user/startFetchUsers', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${USER}?pageSize=${filter.pageSize}&currentPage=${filter.currentPage}&orderBy=${filter.orderBy}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateUser = createAsyncThunk('user/startCreateUser', async ({ user, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${USER}/store`, user, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateUser = createAsyncThunk('user/startUpdateUser', async ({ author, id, user, locale, token, form }, { rejectWithValue, dispatch}) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${USER}/update/${id}`, user, { headers });

        if(res.data){
            if(parseInt(author) === parseInt(id)){
                dispatch(authUserActions.updateUserDataLogin(res.data.user))
            }
            form.setStatus({ success: true })
        }

        return res.data.status;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteUser = createAsyncThunk('user/startDeleteUser', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${USER}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveUser = createAsyncThunk('user/startActiveUser', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${USER}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startChangePassword = createAsyncThunk('user/startChangePassword', async ({ id, data, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${USER}/changePassword/${id}`, data, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startChangeEmailPassword = createAsyncThunk('user/startChangeEmailPassword', async ({ id, data, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${USER}/changeEmailPassword/${id}`, data, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startWelcomeUser = createAsyncThunk('user/startWelcomeUser', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${USER}/welcome/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            console.log(err)
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


const startUserInfo = createAsyncThunk('user/startUserInfo', async ({ user, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${USER}/edit/${user}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('user/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${USER}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchUser = createAsyncThunk('user/startFetchUser', async ({ user, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${USER}/edit/${user}`, { headers });

        const data = res.data;
        
        const results = {
          edit: true,
          id: data.id,
          name: data.name,
          surname: data.surname,
          gender_id: data.gender_id,
          isOnline: data.isOnline,
          email: data.email,
          date_work_team: new Date(data.date_work_team),
          vat: data.vat,
          tax: data.tax,
          gemi: data.gemi ? data.gemi : '',
          birthday: data.birthday ? new Date(data.birthday) : null,
          passport: data.passport,
          address: data.address,
          country: data.country,
          iso: data.iso,
          image: data.image ? data.image : '',
          file: '',
          notes: data.notes ? data.notes : '',
          lat: Number(data.lat),
          lng: Number(data.lng),
          socials: data.socials,
          job_title: data.job_title.id ? data.job_title.id : '',
          department: data.department ? data.department.id : '',
          group_id: data.group_id,
          group_name: data.group_name,
          role_id: data.role_id.length > 0 ? data.role_id[0].id : "",
          extraPhones: data.extraPhones,
          extraMobiles: data.extraMobiles,
          spoken_language: data.spoken_language,
          verified: data.verified,
          welcome_email: data.welcome_email,
          radios: data.radios,
          files: [],
          filesData: data.filesData,
          prefectures: data.prefectures,
          municipalities: data.municipalities,
          areas: data.areas
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDigitalCardLink = createAsyncThunk('user/startFetchDigitalCardLink', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${USER}/digitalCardLink`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startSendDigitalCardLink = createAsyncThunk('user/startSendDigitalCardLink', async ({ data, locale, token, alert, toggle, messages }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${USER}/sendDigitalCardLink`, data, { headers });

        if(res.data === "success"){
            alert.success(
                messages['alert.success-saved'],
                messages['alert.success'],
                3000,
                null,
                null,
                ''
            );
            toggle();
        }
        

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchMunicipalities = createAsyncThunk('user/startFetchMunicipalities', async ({ locale, token, prefecture_ids }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${USER}/getMunicipality`, { prefecture_ids: prefecture_ids }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchAreas = createAsyncThunk('user/startFetchAreas', async ({ locale, token, prefecture_ids, municipality_ids }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${USER}/getArea`, { prefecture_ids: prefecture_ids, municipality_ids: municipality_ids }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

// Settings User

const startFetchSettingsUser = createAsyncThunk('user/startFetchSettingsUser', async ({ user, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${USER}/editSettings/${user}`, { headers });

        const data = res.data;
        
        const results = {
          id: data.id,
          assignment_exclude: data.assignment_exclude,
          sign_signature: data.sign_signature,
          new_req_notification: data.new_req_notification,
          new_req_group_notification: data.new_req_group_notification,
          new_prop_sync_notification: data.new_prop_sync_notification,
          new_prop_sync_area_notification: data.new_prop_sync_area_notification
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateSettingsUser = createAsyncThunk('user/startUpdateSettingsUser', async ({ author, id, user, locale, token, form }, { rejectWithValue, dispatch}) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${USER}/updateSettings/${id}`, user, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});



//Heading Functions
const setSearch = createAsyncThunk('user/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('user/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSelectedOrderOption = createAsyncThunk('user/setSelectedOrderOption', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    users: {
        data: [],
        totalItemCount: 0
    },
    displayMode: "thumblist",
    selectedPageSize: 10,
    selectedOrderOption: { column: "id-asc", label: "ID ASC" },
    search: "",
    searchValue: "",
    currentPage: 1,
    digitalCardLink: '',
    sendDigitalCardLink: '',
    cardDatafields: {
        countries: [],
    },
    userData: {
        name: '',
        surname: '',
        gender_id: '',
        isOnline: false,
        email: '',
        date_work_team: new Date(),
        vat: '',
        tax: '',
        gemi: '',
        birthday: null,
        passport: '',
        address: '',
        country: '',
        iso: '',
        image: '',
        file: '',
        notes: '',
        lat: '',
        lng: '',
        social: [],
        job_title: '',
        department: '',
        group_id: '',
        role_id: '',
        socials: [],
        extraPhones: [],
        extraMobiles: [],
        spoken_language: [],
        welcome_email: 0,
        verified: 0,
        radios:[],
        files: [],
        filesData: [],
        prefectures: [],
        municipalities: [],
        areas: []
    },
    userSettingsData: {
        assignment_exclude: 0,
        sign_signature: "",
        new_req_notification: 0,
        new_req_group_notification: 0,
        new_prop_sync_notification: 0,
        new_prop_sync_area_notification: 0,
    },
    createUser: '',
    updateUser: '',
    updateSettingsUser: '',
    changePasswordUser: '',
    changeEmailPasswordUser: '',
    deleteUser: '',
    activeUser: '',
    welcomeUser: '',
    userInfo: '',
    dataFields: {
        groups: [],
        roles: [],
        jobTitles: [],
        departments: [],
        countries: [],
        languages: [],
        radios: [],
        files: [],
        genders: [],
        prefectures: [],
        municipalities: [],
        areas: []
    },
    loadingBtn: false,
    loading: true,
    loadingModal: false,
    error: ''
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        fetchUsersSuccess(state, { payload }) {
            return { ...state, loading: false, users: payload, error: '' };
        },
        clearUser(state) {
            return { ...state, loadingBtn: false, loading: false, userData: initialState.userData, error: '' };
        },
        clearAlertUser(state) {
            return { ...state, updateUser: '', updateSettingsUser: '', createUser: '', deleteUser: '', activeUser: '', welcomeUser: '', changePasswordUser: '', changeEmailPasswordUser: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 };
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
        setDisplayMode(state, { payload }) {
            return { ...state, displayMode: payload };
        },
    },
    extraReducers: {
      [startFetchUsers.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchUsers.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, users: payload, error: '' };
      },
      [startFetchUsers.rejected]: (state, { payload }) => {
        return { ...state, loading: false, users: initialState.users, error: payload };
      },

      [startCreateUser.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateUser.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createUser: payload, error: '' };
      },
      [startCreateUser.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createUser: '', error: payload };
      },

      [startUpdateUser.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateUser.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateUser: payload, error: '' };
      },
      [startUpdateUser.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateUser: '', error: payload };
      },

      [startFetchUser.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchUser.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, userData: payload, error: '' };
      },
      [startFetchUser.rejected]: (state, { payload }) => {
        return { ...state, loading: false, userData: '', error: payload };
      },

      [startDeleteUser.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteUser: payload, error: '' };
      },
      [startDeleteUser.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteUser: '', error: payload };
      },

      [startActiveUser.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeUser: payload, error: '' };
      },
      [startActiveUser.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeUser: '', error: payload };
      },

      [startChangePassword.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, changePasswordUser: payload, error: '' };
      },
      [startChangePassword.rejected]: (state, { payload }) => {
        return { ...state, loading: false, changePasswordUser: '', error: payload };
      },

      [startChangeEmailPassword.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, changeEmailPasswordUser: payload, error: '' };
      },
      [startChangeEmailPassword.rejected]: (state, { payload }) => {
        return { ...state, loading: false, changeEmailPasswordUser: '', error: payload };
      },

      [startWelcomeUser.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, welcomeUser: payload, error: '' };
      },
      [startWelcomeUser.rejected]: (state, { payload }) => {
        return { ...state, loading: false, welcomeUser: '', error: payload };
      },

      [startUserInfo.pending]: (state) => {
        return { ...state, loadingModal: true, error: '' };
      },
      [startUserInfo.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, userInfo: payload, error: '' };
      },
      [startUserInfo.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, userInfo: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchDigitalCardLink.pending]: (state) => {
        return { ...state, loadingModal: true, error: '' };
      },
      [startFetchDigitalCardLink.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, digitalCardLink: payload.link, cardDatafields: { countries: payload.dataFields },  error: '' };
      },
      [startFetchDigitalCardLink.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, digitalCardLink: '', cardDatafields: initialState.cardDatafields, error: payload };
      },

      [startSendDigitalCardLink.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startSendDigitalCardLink.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, sendDigitalCardLink: payload,  error: '' };
      },
      [startSendDigitalCardLink.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, sendDigitalCardLink: '', error: payload };
      },

      [startFetchMunicipalities.fulfilled]: (state, { payload }) => {
        let municipalityFields = {...state.dataFields}
        municipalityFields.municipalities = payload;
        municipalityFields.areas = [];

        return { ...state, dataFields: municipalityFields, error: '' };
      },
      [startFetchMunicipalities.rejected]: (state, { payload }) => {
        let municipalityErrorFields = {...state.dataFields}
        municipalityErrorFields.municipalities = [];
        municipalityErrorFields.areas = [];

        return { ...state, dataFields: municipalityErrorFields, error: payload };
      },

      [startFetchAreas.fulfilled]: (state, { payload }) => {
        let areaFields = {...state.dataFields}
        areaFields.areas = payload;

        return { ...state, dataFields: areaFields, error: '' };
      },
      [startFetchAreas.rejected]: (state, { payload }) => {
        let areaErrorFields = {...state.dataFields}
        areaErrorFields.areas = [];

        return { ...state, dataFields: areaErrorFields, error: payload };
      },

      //Settings User

      [startFetchSettingsUser.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSettingsUser.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, userSettingsData: payload, error: '' };
      },
      [startFetchSettingsUser.rejected]: (state, { payload }) => {
        return { ...state, loading: false, userSettingsData: '', error: payload };
      },

      [startUpdateSettingsUser.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateSettingsUser.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateSettingsUser: payload, error: '' };
      },
      [startUpdateSettingsUser.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateSettingsUser: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSelectedOrderOption.fulfilled]: (state, { payload }) => {
        return { ...state, selectedOrderOption: payload, currentPage: 1 };
      },

    },
});


export const userActions = {
    ...userSlice.actions,
    startFetchUsers,
    startCreateUser,
    startUpdateUser,
    startFetchUser,
    startDeleteUser,
    startActiveUser,
    startChangePassword,
    startChangeEmailPassword,
    startWelcomeUser,
    startUserInfo,
    startFetchDataFields,
    startFetchDigitalCardLink,
    startSendDigitalCardLink,
    startFetchMunicipalities,
    startFetchAreas,
    setSearch,
    setCurrentPage,
    setSelectedOrderOption,

    startFetchSettingsUser,
    startUpdateSettingsUser
    
}
export default userSlice.reducer