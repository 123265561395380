import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, PAYMENTS } from '../../constants/defaultValues';

const startCreatePayment = createAsyncThunk('payment/startCreatePayment', async ({ payment, locale, token, form, toggle, payments }, { rejectWithValue, dispatch }) => {
    const headers = HEADERS;
    try {
        dispatch(paymentSlice.actions.setLoading());
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`

        const res = await axios.post(`${PAYMENTS}/store`, payment, { headers });

        let new_payments = JSON.parse(JSON.stringify(payments));

        new_payments.data.unshift(res.data.data);
        new_payments.totalItemCount = payments.totalItemCount + 1;

        dispatch(paymentSlice.actions.fetchPaymentsSuccess(new_payments));

        form.resetForm()
        form.setStatus({ success: true })
        toggle()

        return res.data.status;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdatePayment = createAsyncThunk('payment/startUpdatePayment', async ({ id, payment, locale, token, form, payments, toggle }, { rejectWithValue, dispatch }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PAYMENTS}/update/${id}`, payment, { headers });

        let new_payments = payments.data.map((payment_item, i) => {
            let new_payment_item = {...payment_item};

            if (Number(new_payment_item.id) === Number(id)) {
                new_payment_item = res.data.data
            }
  
            return new_payment_item
        });
  
        const newArray = {
            data: new_payments,
            totalItemCount: payments.totalItemCount
        }
        
        dispatch(paymentSlice.actions.fetchPaymentsSuccess(newArray));
        
        form.setStatus({ success: true })
        toggle()

        return res.data.status;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPayments = createAsyncThunk('payment/startFetchPayments', async ({ user_id, locale, token, filter, history }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;


        const res = await axios.get(`${PAYMENTS}/${user_id}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('payment/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PAYMENTS}/dataFields?edit=${ifEdit}`, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPayment = createAsyncThunk('payment/startFetchPayment', async ({ payment, history, locale, token }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PAYMENTS}/edit/${payment}`, { headers });

        const data = res.data;

        const results = {
            edit: true, 
            user_id: data.user_id,
            package_id: data.package_id,
            from: new Date(data.from),
            to: new Date(data.to),
            completed: data.completed,
            net_price: data.net_price,
            vat: data.vat,
            vat_price: data.vat_price,
            discount: data.discount,
            discount_price: data.discount_price,
            total_price: data.total_price,
            payment_date: data.payment_date ? new Date(data.payment_date) : null,
            transaction_id: data.transaction_id ? data.transaction_id : "",
            files: [],
            filesData: data.filesData
        };

        return results
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startStatusPayment = createAsyncThunk('payment/startStatusPayment', async ({ locale, token, id, action }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${PAYMENTS}/status/${id}`, { action: action }, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeletePayment = createAsyncThunk('payment/startDeletePayment', async ({ locale, token, id }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${PAYMENTS}/destroy/${id}`, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startPdfInvoice = createAsyncThunk('payment/startPdfInvoice', async ({ locale, token, id }, { rejectWithValue, dispatch }) => {
    try {
        dispatch(paymentSlice.actions.actionPdfInvoice(id));
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PAYMENTS}/pdf/${id}`, { headers });

        let pdfWindow = window.open("Invoice Payment", "_blank", "toolbar=1, scrollbars=1, resizable=1, width=" + 600 + ", height=" + 800);
        pdfWindow.document.write("<html<head><title>ReWize</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
        pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(res.data)+"#toolbar=1&navpanes=0&scrollbar=0'></embed></body></html>");

        return true;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchRenewPayment = createAsyncThunk('payment/startFetchRenewPayment', async ({ locale, token, id, package_id }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PAYMENTS}/renewPayment/${id}/${package_id}`, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startSuccessPayment = createAsyncThunk('payment/startSuccessPayment', async ({ author, payment, package_id, renewal, transaction, payments, locale, token }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const obj = {
            user_id: author.user.id,
            payment_id: payment,
            package_id: package_id,
            transaction_id: transaction,
            renewal: renewal
        };

        const res = await axios.post(`${PAYMENTS}/successPayment`, obj, { headers });

        let new_payments = JSON.parse(JSON.stringify(payments));

        new_payments.data.unshift(res.data.data);
        new_payments.totalItemCount = payments.totalItemCount + 1;

        dispatch(paymentSlice.actions.fetchPaymentsSuccess(new_payments));

        return res.data.status;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteFile = createAsyncThunk('payment/startDeleteFile', async ({ locale, token, id }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${PAYMENTS}/destroyFile/${id}`, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


//Heading Functions
const setSearch = createAsyncThunk('payment/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('payment/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('payment/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    payments: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{ desc: false, id: "id" }],
    createPayment: '',
    updatePayment: '',
    statusPayment: '',
    deletePayment: '',
    deleteFile: '',
    paymentData: {
        edit: false,
        user_id: '',
        package_id: '',
        from: null,
        to: null,
        completed: 0,
        net_price: '',
        vat: '',
        vat_price: '',
        discount: 0,
        discount_price: 0,
        total_price: '',
        payment_date: null,
        transaction_id: '',
        files: [],
        filesData: []
    },
    renewPaymentData: {
        id: '',
        clientSecret: '',
        package: [],
        banks: []
    },
    dataFields: {
        packages: []
    },
    payment_id: '',
    pdfInvoice: '',
    loadingPdfBtn: false,
    isEditModal: 0,
    isOpenModal: false,
    loadingModal: false,
    loadingBtn: false,
    loading: true,
    error: ''
}

export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setLoading(state) {
            return { ...state, loading: true };
        },
        setToggleModal(state, { payload }) {
            return { ...state, isOpenModal: !state.isOpenModal, isEditModal: payload.edit ? 1 : 0, payment_id: payload.payment_id };
        },
        fetchPaymentsSuccess(state, { payload }) {
            return { ...state, loading: false, payments: payload, error: '' };
        },
        clearPayment(state) {
            return { ...state, loadingModal: false, loadingBtn: false, loading: false, paymentData: initialState.paymentData, error: '' };
        },
        clearAlertPayment(state) {
            return { ...state, updatePayment: '', createPayment: '', deletePayment: '', statusPayment: '', deleteFile: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
        actionPdfInvoice(state, { payload }) {
            return { ...state, loadingPdfBtn: true, pdfInvoice: payload, error: '' };
        }
    },
    extraReducers: {
      [startCreatePayment.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreatePayment.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createPayment: payload, error: '' };
      },
      [startCreatePayment.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createPayment: '', error: payload };
      },

      [startFetchPayments.pending]: (state) => {
        return { ...state, loading: true, loadingBtn: false, error: '' };
      },
      [startFetchPayments.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, payments: payload, error: '' };
      },
      [startFetchPayments.rejected]: (state, { payload }) => {
        return { ...state, loading: false, payments: initialState.payments, error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loadingModal: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, dataFields: initialState.dataFields, error: payload };
      },

      [startUpdatePayment.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdatePayment.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updatePayment: payload, error: '' };
      },
      [startUpdatePayment.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updatePayment: '', error: payload };
      },

      [startFetchPayment.pending]: (state) => {
        return { ...state, loadingModal: true, loadingBtn: false, error: '' };
      },
      [startFetchPayment.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, paymentData: payload, error: '' };
      },
      [startFetchPayment.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, paymentData: '', error: payload };
      },

      [startDeletePayment.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deletePayment: payload, error: '' };
      },
      [startDeletePayment.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deletePayment: '', error: payload };
      },

      [startStatusPayment.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, statusPayment: payload, error: '' };
      },
      [startStatusPayment.rejected]: (state, { payload }) => {
        return { ...state, loading: false, statusPayment: '', error: payload };
      },

      [startPdfInvoice.fulfilled]: (state) => {
        return { ...state, loadingPdfBtn: false, pdfInvoice: '', error: '' };
      },
      [startPdfInvoice.rejected]: (state, { payload }) => {
        return { ...state, loadingPdfBtn: false, pdfInvoice: '', error: payload };
      },

      [startFetchRenewPayment.pending]: (state) => {
        return { ...state, loadingModal: true, loadingBtn: false, error: '' };
      },
      [startFetchRenewPayment.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, renewPaymentData: payload, error: '' };
      },
      [startFetchRenewPayment.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, renewPaymentData: initialState.renewPaymentData, error: payload };
      },

      [startSuccessPayment.fulfilled]: (state) => {
        return { ...state, loading: false, error: '' };
      },

      [startDeleteFile.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteFile: payload, error: '' };
      },
      [startDeleteFile.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteFile: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      },
    },
});

export const paymentActions = {
    ...paymentSlice.actions,
    startCreatePayment,
    startFetchPayments,
    startFetchDataFields,
    startUpdatePayment,
    startFetchPayment,
    startDeletePayment,
    startStatusPayment,
    startPdfInvoice,
    startFetchRenewPayment,
    startSuccessPayment,
    startDeleteFile,
    setSearch,
    setCurrentPage,
    setSort
}
export default paymentSlice.reducer