import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, GROUP } from '../../constants/defaultValues';

const startFetchGroups = createAsyncThunk('group/startFetchGroups', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${GROUP}?pageSize=${filter.pageSize}&currentPage=${filter.currentPage}&orderBy=${filter.orderBy}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateGroup = createAsyncThunk('group/startCreateGroup', async ({ group, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${GROUP}/store`, group, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateGroup = createAsyncThunk('group/startUpdateGroup', async ({ id, group, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${GROUP}/update/${id}`, group, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('group/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${GROUP}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchGroup = createAsyncThunk('group/startFetchGroup', async ({ group, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${GROUP}/edit/${group}`, { headers });

        const data = res.data;

        const results = {
          edit: true,
          name: data.name,
          company_name: data.company_name,
          vat: data.vat,
          gemi: data.gemi,
          number_agents: data.number_agents,
          show_auctions: data.show_auctions,
          image: data.image ? data.image : '',
          file: '',
          rates: data.rates
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteGroup = createAsyncThunk('group/startDeleteGroup', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${GROUP}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveGroup = createAsyncThunk('group/startActiveGroup', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${GROUP}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startGroupInfo = createAsyncThunk('group/startGroupInfo', async ({ group, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${GROUP}/edit/${group}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchUsersGroup = createAsyncThunk('group/startFetchUsersGroup', async ({ id, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${GROUP}/fetchUsersGroup/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('group/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('group/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSelectedOrderOption = createAsyncThunk('group/setSelectedOrderOption', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    groups: {
        data: [],
        totalItemCount: 0
    },
    displayMode: "thumblist",
    selectedPageSize: 10,
    selectedOrderOption: { column: "id-asc", label: "ID ASC" },
    search: "",
    searchValue: "",
    currentPage: 1,
    usersGroup: [],
    groupData: {
        name: '',
        company_name: '',
        vat: '',
        gemi: '',
        number_agents: '',
        show_auctions: 0,
        image: '',
        file: '',
        rates: []
    },
    createGroup: '',
    updateGroup: '',
    deleteGroup: '',
    activeGroup: '',
    groupInfo: '',
    dataFields: {},
    loadingBtn: false,
    loading: true,
    loadingModal: false,
    error: ''
}

export const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        fetchGroupsSuccess(state, { payload }) {
            return { ...state, loading: false, groups: payload, error: '' };
        },
        clearGroup(state) {
            return { ...state, loadingBtn: false, loading: false, groupData: initialState.groupData, error: '' };
        },
        clearAlertGroup(state) {
            return { ...state, updateGroup: '', createGroup: '', deleteGroup: '', activeGroup: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 };
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
        setDisplayMode(state, { payload }) {
            return { ...state, displayMode: payload };
        },
    },
    extraReducers: {
      [startFetchGroups.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchGroups.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, groups: payload, error: '' };
      },
      [startFetchGroups.rejected]: (state, { payload }) => {
        return { ...state, loading: false, groups: initialState.groups, error: payload };
      },

      [startCreateGroup.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateGroup.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createGroup: payload, error: '' };
      },
      [startCreateGroup.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createGroup: '', error: payload };
      },

      [startUpdateGroup.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateGroup.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateGroup: payload, error: '' };
      },
      [startUpdateGroup.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateGroup: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchGroup.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchGroup.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, groupData: payload, error: '' };
      },
      [startFetchGroup.rejected]: (state, { payload }) => {
        return { ...state, loading: false, groupData: '', error: payload };
      },

      
      [startDeleteGroup.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteGroup: payload, error: '' };
      },
      [startDeleteGroup.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteGroup: '', error: payload };
      },

      [startActiveGroup.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeGroup: payload, error: '' };
      },
      [startActiveGroup.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeGroup: '', error: payload };
      },

      [startGroupInfo.pending]: (state) => {
        return { ...state, loadingModal: true, error: '' };
      },
      [startGroupInfo.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, groupInfo: payload, error: '' };
      },
      [startGroupInfo.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, groupInfo: '', error: payload };
      },

      [startFetchUsersGroup.pending]: (state) => {
        return { ...state, loadingModal: true, error: '' };
      },
      [startFetchUsersGroup.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, usersGroup: payload, error: '' };
      },
      [startFetchUsersGroup.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, usersGroup: [], error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSelectedOrderOption.fulfilled]: (state, { payload }) => {
        return { ...state, selectedOrderOption: payload, currentPage: 1 };
      },

    },
});


export const groupActions = {
    ...groupSlice.actions,
    startFetchDataFields,
    startFetchGroups,
    startCreateGroup,
    startUpdateGroup,
    startFetchGroup,
    startDeleteGroup,
    startActiveGroup,
    startGroupInfo,
    startFetchUsersGroup,
    setSearch,
    setCurrentPage,
    setSelectedOrderOption
}
export default groupSlice.reducer