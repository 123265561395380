import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, AUCTION_LEAD } from '../../constants/defaultValues';

const startFetchAuctionLeads = createAsyncThunk('auctionLead/startFetchAuctionLeads', async ({ locale, token, filter }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;


        const res = await axios.get(`${AUCTION_LEAD}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchAuctionLead = createAsyncThunk('auctionLead/startFetchAuctionLead', async ({ auctionLead, history, locale, token }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${AUCTION_LEAD}/${auctionLead}`, { headers });

        const data = res.data;

        return data
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startStatusAuctionLead = createAsyncThunk('auctionLead/startStatusAuctionLead', async ({ locale, token, id, action }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${AUCTION_LEAD}/status/${id}`, { action: action }, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteAuctionLead = createAsyncThunk('auctionLead/startDeleteAuctionLead', async ({ locale, token, id }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${AUCTION_LEAD}/${id}`, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//FORM ACTIONS

const startFetchDataFormFields = createAsyncThunk('auctionLead/startFetchDataFormFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${AUCTION_LEAD}/formFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchOperator = createAsyncThunk('auctionLead/startFetchOperator', async ({ locale, token, property_id, setValue }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${AUCTION_LEAD}/getOperator/${property_id}`, { headers });

        setTimeout(() => {
            setValue('operator', res.data.operator)
        }, 500);

        return res.data.status;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateForm = createAsyncThunk('auctionLead/startCreateForm', async ({ request, locale, token, auctionLeads, form, toggle }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${AUCTION_LEAD}`, request, { headers });

        let new_list = JSON.parse(JSON.stringify(auctionLeads));
        new_list.data.unshift(res.data.auction);
        new_list.totalItemCount = new_list.totalItemCount + 1;
        
        dispatch(auctionLeadSlice.actions.fetchAuctionLeadsSuccess(new_list));

        form.resetForm();
        form.setStatus({ success: true });

        toggle();

        return res.data.status;
    } catch (error) {
        form.setStatus({ success: false });
        form.setSubmitting(false);
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


//Heading Functions
const setSearch = createAsyncThunk('auctionLead/setSearch', ( args) => {
    return args;
});

const setCurrentPage = createAsyncThunk('auctionLead/setCurrentPage', ( args) => {
    return args;
});

const setSort = createAsyncThunk('auctionLead/setSort', ( args) => {
    return args;
});

const initialState = {
    auctionLeads: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{ desc: false, id: "status_id" }],
    createForm: '',
    statusAuctionLead: '',
    deleteAuctionLead: '',
    auctionLeadData: {},
    formData: {
        auction_id: '',
        contact_type: [],
        contacts: [],
        contact_id: '',
        name: '',
        surname: '',
        gender: '',
        country: '',
        email: '',
        phone: '',
        phoneCode: '',
        phoneCountry: '',
        phoneIso: '',
        mobile: '',
        mobileCountry: '',
        mobileCode: '',
        mobileIso: '',
        spoken_language: [],
        preferred_communication_id: [],
        age_range_id: '',
        cooperative_owner_id: '',
        contact_source: '',
        operator: '',
        sendOffer: 1,
        notes: ''
    },
    formFields: {
        types: [],
        genders: [],
        countries: [],
        preferredCommunications: [],
        languages: [],
        sources: [],
        ranges: [],
        cooperatives: [],
        operators: []
    },
    loadingModal: false,
    loadingBtn: false,
    loadingForm: true,
    loadingFormBtn: false,
    loading: true,
    error: ''
}

export const auctionLeadSlice = createSlice({
    name: 'auctionLead',
    initialState,
    reducers: {
        fetchAuctionLeadsSuccess(state, { payload }) {
            return { ...state, loading: false, auctionLeads: payload, error: '' };
        },
        clearAuctionLead(state) {
            return { ...state, loadingModal: false, loadingBtn: false, loading: false, loadingForm: false, loadingFormBtn: false, formData: initialState.formData, auctionLeadData: initialState.auctionLeadData, error: '' };
        },
        clearAlertAuctionLead(state) {
            return { ...state, statusAuctionLead: '', deleteAuctionLead: '', createForm: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
    },
    extraReducers: {
      [startFetchAuctionLeads.pending]: (state) => {
        return { ...state, loading: true, loadingBtn: false, error: '' };
      },
      [startFetchAuctionLeads.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, auctionLeads: payload, error: '' };
      },
      [startFetchAuctionLeads.rejected]: (state, { payload }) => {
        return { ...state, loading: false, auctionLeads: initialState.auctionLeads, error: payload };
      },

      [startFetchAuctionLead.pending]: (state) => {
        return { ...state, loadingModal: true, loadingBtn: false, error: '' };
      },
      [startFetchAuctionLead.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, auctionLeadData: payload, error: '' };
      },
      [startFetchAuctionLead.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, auctionLeadData: '', error: payload };
      },

      [startStatusAuctionLead.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, statusAuctionLead: payload, error: '' };
      },
      [startStatusAuctionLead.rejected]: (state, { payload }) => {
        return { ...state, loading: false, statusAuctionLead: '', error: payload };
      },

      [startDeleteAuctionLead.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteAuctionLead: payload, error: '' };
      },
      [startDeleteAuctionLead.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteAuctionLead: '', error: payload };
      },

      // FORM ACTIONS
      [startFetchDataFormFields.pending]: (state) => {
        return { ...state, loadingForm: true, error: '' };
      },
      [startFetchDataFormFields.fulfilled]: (state, { payload }) => {
        return { ...state, formFields: payload, error: '' };
      },
      [startFetchDataFormFields.rejected]: (state, { payload }) => {
        return { ...state, loadingForm: false, formFields: initialState.formFields, error: payload };
      },

      [startFetchOperator.fulfilled]: (state, { payload }) => {
        return { ...state, error: '' };
      },
      [startFetchOperator.rejected]: (state, { payload }) => {
        return { ...state, error: payload };
      },

      [startCreateForm.pending]: (state) => {
        return { ...state, loading: true, loadingFormBtn: true, error: '' };
      },
      [startCreateForm.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, loadingFormBtn: false, createForm: payload, error: '' };
      },
      [startCreateForm.rejected]: (state, { payload }) => {
        return { ...state, loading: false, loadingFormBtn: false, createForm: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      },
    },
});

export const auctionLeadActions = {
    ...auctionLeadSlice.actions,
    startFetchAuctionLeads,
    startFetchAuctionLead,
    startStatusAuctionLead,
    startDeleteAuctionLead,
    startFetchDataFormFields,
    startFetchOperator,
    startCreateForm,
    setSearch,
    setCurrentPage,
    setSort
}
export default auctionLeadSlice.reducer