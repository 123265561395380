import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, DEPOSIT } from '../../constants/defaultValues';

const startCreateDeposit = createAsyncThunk('deposit/startCreateDeposit', async ({ deposit, locale, token, form }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${DEPOSIT}/store`, deposit, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        dispatch(depositSlice.actions.clearProperty());
        dispatch(depositSlice.actions.clearRequest());

        return res.data.status;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateDeposit = createAsyncThunk('deposit/startUpdateDeposit', async ({ id, deposit, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${DEPOSIT}/update/${id}`, deposit, { headers });

        if(res.data){
            form.setFieldValue('files', []);
            form.setStatus({ success: true });
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false });
        form.setSubmitting(false);
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDeposit = createAsyncThunk('deposit/startFetchDeposit', async ({ deposit, history, locale, token }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${DEPOSIT}/edit/${deposit}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            id: data.data.id,
            property_id: data.data.property_id,
            request_id: data.data.request_id,
            deal_id: data.data.deal_id,
            amount: data.data.amount,
            status: data.data.status,
            collector: data.data.collector,
            files: [],
            filesData: data.data.filesData
        };

        return { data: results, details_property: data.details_property, details_request: data.details_request};
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDeposits = createAsyncThunk('deposit/startFetchDeposits', async ({ locale, token, filter, loading }, { rejectWithValue, dispatch }) => {
    try {
        dispatch(depositSlice.actions.actionFetchDeposits(loading))
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${DEPOSIT}?
        pageSize=${filter.pageSize}
        &currentPage=${filter.currentPage}
        &orderBy=${filter.orderBy}
        &search=${filter.search}
        &agent_type=${filter.searchFilter.agent_type.length > 0 ? filter.searchFilter.agent_type.join() : ""}
        &status_type=${filter.searchFilter.status_type.length > 0 ? filter.searchFilter.status_type.join() : ""}
        &collector_type=${filter.searchFilter.collector_type.length > 0 ? filter.searchFilter.collector_type.join() : ""}
        &transaction_type=${filter.searchFilter.transaction_type.length > 0 ? filter.searchFilter.transaction_type.join() : ""}
    
        &fromDateCreate_type=${filter.searchFilter.fromDateCreate_type}
        &toDateCreate_type=${filter.searchFilter.toDateCreate_type}
        &fromBudget_type=${filter.searchFilter.fromBudget_type}
        &toBudget_type=${filter.searchFilter.toBudget_type}
        `, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDepositInfo = createAsyncThunk('deposit/startDepositInfo', async ({ deposit, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${DEPOSIT}/edit/${deposit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('deposit/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${DEPOSIT}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSearchFields = createAsyncThunk('deposit/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${DEPOSIT}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchProperty = createAsyncThunk('deposit/startFetchProperty', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${DEPOSIT}/fetchProperty`, {property_id: id}, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchRequest = createAsyncThunk('deposit/startFetchRequest', async ({ locale, token, id, setFieldValue }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${DEPOSIT}/fetchRequest`, {request_id: id}, { headers });

        setFieldValue("filesData", res.data.files)

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSelectedOrderOption = createAsyncThunk('deposit/setSelectedOrderOption', ( args ) => {
    return args;
});

const setSearch = createAsyncThunk('deposit/setSearch', ( args ) => {
    return args;
});

const setCurrentPage = createAsyncThunk('deposit/setCurrentPage', ( args ) => {
    return args;
});

const setSort = createAsyncThunk('deposit/setSort', ( args ) => {
    return args;
});

const initialState = {
    deposits: {
        data: [],
        totalItemCount: 0
    },
    depositInfo: {},
    displayMode: "list",
    selectedPageSize: 10,
    selectedOrderOption: "",
    search: "",
    searchValue: "",
    searchFilterType: 1,
    stage: "",
    fields: [],
    searchFilter: [
        { translate: "general.agent", id: "agent_type", dataKey: "agent_types", search: "", type: 1 },
        { translate: "general.status", id: "status_type", dataKey: "status_types", search: "", type: 1 },
        { translate: "deposits.collector", id: "collector_type", dataKey: "collector_types", search: "", type: 1 },
        { translate: "general.sale-rent", id: "transaction_type", dataKey: "transaction_types", search: "", type: 1 },
        { translate: "general.create-date", id: "dateCreate_type", dataKey: "", search: "", type: 1 },
        { translate: "general.budget", id: "budget_type", dataKey: "", search: "", type: 1 }
    ],
    searchFilterValues: {
        agent_type: [],
        status_type: [],
        collector_type: [],
        transaction_type: [],

        fromDateCreate_type: "",
        toDateCreate_type: "",
        fromBudget_type: "",
        toBudget_type: ""
    },
    currentPage: 1,
    depositData: {
        property_id: '',
        request_id: '',
        deal_id: '',
        amount: '',
        status: 1,
        collector: '',
        files: [],
        filesData: []
    },
    createDeposit: '',
    updateDeposit: '',
    details_property: {},
    details_request: {},
    dataFields: {
        status: [],
        collectors: [],
        files: []
    },
    loadingBtn: false,
    loading: true,
    loadingModal: false,
    loadingSearch: false,
    loadingPropertyField: false,
    loadingRequestField: false,
    error: ''
}

export const depositSlice = createSlice({
    name: 'deposit',
    initialState,
    reducers: {
        fetchDepositsSuccess(state, { payload }) {
            return { ...state, loading: false, loadingSearch: false, deposits: payload, error: '' };
        },
        clearDeposit(state) {
            return { ...state, loadingBtn: false, loadingModal: false, loading: false, loadingSearch: false, loadingPropertyField: false, loadingRequestField: false, depositData: initialState.depositData, details_property: initialState.details_property, details_request: initialState.details_request, error: '' };
        },
        clearAlertDeposit(state) {
            return { ...state, updateDeposit: '', createDeposit: '',  error: '' };
        },
        setDisplayMode(state, { payload }) {
            return { ...state, displayMode: payload };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 };
        },
        setFilter(state, { payload }) {
            return { ...state, searchFilterValues: payload };
        },
        setFilterType(state, { payload }) {
            return { ...state, searchFilterType: payload };
        },
        setFilterFieldSearch(state, { payload }) {
            return { ...state, fields: payload.dataValues, searchFilter: payload.values };
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
        actionFetchDeposits(state, { payload }) {
            return { ...state, loading: payload ? true : false, loadingSearch: true, error: '' };
        },
        clearProperty(state) {
            return { ...state, details_property: {} };
        },
        clearRequest(state) {
            return { ...state, details_request: {} };
        },
        setProperty(state, { payload }) {
            return { ...state, details_property: payload };
        },
        setRequest(state, { payload }) {
            return { ...state, details_request: payload };
        }
    },
    extraReducers: {
      [startCreateDeposit.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateDeposit.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createDeposit: payload, error: '' };
      },
      [startCreateDeposit.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createDeposit: '', error: payload };
      },

      [startUpdateDeposit.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateDeposit.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateDeposit: payload, error: '' };
      },
      [startUpdateDeposit.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateDeposit: '', error: payload };
      },
      
      [startFetchDeposit.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDeposit.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, depositData: payload.data, details_property: payload.details_property, details_request: payload.details_request, error: '' };
      },
      [startFetchDeposit.rejected]: (state, { payload }) => {
        return { ...state, loading: false, depositData: initialState.depositData, details_property: initialState.details_property, details_request: initialState.details_request, error: payload };
      },
  
      [startFetchDeposits.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, loadingSearch: false, deposits: payload, error: '' };
      },
      [startFetchDeposits.rejected]: (state, { payload }) => {
        return { ...state, loading: false, loadingSearch: false, deposits: initialState.deposits, error: payload };
      },

      [startDepositInfo.pending]: (state) => {
        return { ...state, loadingModal: true, error: '' };
      },
      [startDepositInfo.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, depositInfo: payload, error: '' };
      },
      [startDepositInfo.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, depositInfo: {}, error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchSearchFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSearchFields.fulfilled]: (state, { payload }) => {
        return { ...state, fields: payload, error: '' };
      },
      [startFetchSearchFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, fields: initialState.fields, error: payload };
      },

      [startFetchProperty.pending]: (state) => {
        return { ...state, loadingPropertyField: true, error: '' };
      },
      [startFetchProperty.fulfilled]: (state, { payload }) => {
        return { ...state, loadingPropertyField: false, details_property: payload, error: '' };
      },
      [startFetchProperty.rejected]: (state, { payload }) => {
        return { ...state, loadingPropertyField: false, details_property: {}, error: payload };
      },

      [startFetchRequest.pending]: (state) => {
        return { ...state, loadingRequestField: true, error: '' };
      },
      [startFetchRequest.fulfilled]: (state, { payload }) => {
        return { ...state, loadingRequestField: false, details_request: payload.details_request, error: '' };
      },
      [startFetchRequest.rejected]: (state, { payload }) => {
        return { ...state, loadingRequestField: false, details_request: {}, error: payload };
      },

      //Heading Functions
      [setSelectedOrderOption.fulfilled]: (state, { payload }) => {
        return { ...state, selectedOrderOption: payload, currentPage: 1 };
      },
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const depositActions = {
    ...depositSlice.actions,
    startCreateDeposit,
    startUpdateDeposit,
    startFetchDeposit,
    startFetchDeposits,
    startDepositInfo,
    startFetchDataFields,
    startFetchSearchFields,

    startFetchProperty,
    startFetchRequest,

    setSelectedOrderOption,
    setSearch,
    setCurrentPage,
    setSort
}
export default depositSlice.reducer