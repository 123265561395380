import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, PARENT_MENUS } from '../../constants/defaultValues';

const startFetchParentMenus = createAsyncThunk('parentMenu/startFetchParentMenus', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${PARENT_MENUS}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateParentMenu = createAsyncThunk('parentMenu/startCreateParentMenu', async ({ parent_menu, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PARENT_MENUS}/store`, parent_menu, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateParentMenu = createAsyncThunk('parentMenu/startUpdateParentMenu', async ({ id, parent_menu, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PARENT_MENUS}/update/${id}`, parent_menu, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteParentMenu = createAsyncThunk('parentMenu/startDeleteParentMenu', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${PARENT_MENUS}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveParentMenu = createAsyncThunk('parentMenu/startActiveParentMenu', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${PARENT_MENUS}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchParentMenu = createAsyncThunk('parentMenu/startFetchParentMenu', async ({ parent_menu, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PARENT_MENUS}/edit/${parent_menu}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            parent_menu_en: data.parent_menu_en,
            parent_menu_el: data.parent_menu_el,
            link_en: data.link_en,
            link_el: data.link_el,
            target_blank: data.target_blank === 1 ? true : false,
            header: data.header === 1 ? true : false,
            footer: data.footer === 1 ? true : false,
            sort: data.sort
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('parentMenu/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('parentMenu/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('parentMenu/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    parentMenus: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    parentMenuData: {
        parent_menu_en: '',
        parent_menu_el: '',
        link_en: '',
        link_el: '',
        target_blank: false,
        header: false,
        footer: false,
        sort: 0
    },
    createParentMenu: '',
    updateParentMenu: '',
    deleteParentMenu: '',
    loadingBtn: false,
    loading: true,
    error: ''
}

export const parentMenuSlice = createSlice({
    name: 'parentMenu',
    initialState,
    reducers: {
        fetchParentMenusSuccess(state, { payload }) {
            return { ...state, loading: false, parentMenus: payload, error: '' };
        },
        clearParentMenu(state) {
            return { ...state, loadingBtn: false, loading: false, parentMenuData: initialState.parentMenuData, error: '' };
        },
        clearAlertParentMenu(state) {
            return { ...state, updateParentMenu: '', createParentMenu: '', deleteParentMenu: '', activeParentMenu: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchParentMenus.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchParentMenus.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, parentMenus: payload, error: '' };
      },
      [startFetchParentMenus.rejected]: (state, { payload }) => {
        return { ...state, loading: false, parentMenus: initialState.parentMenus, error: payload };
      },

      [startFetchParentMenu.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchParentMenu.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, parentMenuData: payload, error: '' };
      },
      [startFetchParentMenu.rejected]: (state, { payload }) => {
        return { ...state, loading: false, parentMenuData: '', error: payload };
      },
      
      [startCreateParentMenu.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateParentMenu.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createParentMenu: payload, error: '' };
      },
      [startCreateParentMenu.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createParentMenu: '', error: payload };
      },

      [startUpdateParentMenu.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateParentMenu.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateParentMenu: payload, error: '' };
      },
      [startUpdateParentMenu.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateParentMenu: '', error: payload };
      },

      [startDeleteParentMenu.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteParentMenu: payload, error: '' };
      },
      [startDeleteParentMenu.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteParentMenu: '', error: payload };
      },

      [startActiveParentMenu.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeParentMenu: payload, error: '' };
      },
      [startActiveParentMenu.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeParentMenu: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const parentMenuActions = {
    ...parentMenuSlice.actions,
    startFetchParentMenus,
    startFetchParentMenu,
    startCreateParentMenu,
    startUpdateParentMenu,
    startDeleteParentMenu,
    startActiveParentMenu,
    setSearch,
    setCurrentPage,
    setSort
}
export default parentMenuSlice.reducer