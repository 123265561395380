import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, PROPERTY_COUNTRIES } from '../../constants/defaultValues';

const startFetchDataFields = createAsyncThunk('propertyCountry/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_COUNTRIES}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPropertyCountries = createAsyncThunk('propertyCountry/startFetchPropertyCountries', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${PROPERTY_COUNTRIES}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreatePropertyCountry = createAsyncThunk('propertyCountry/startCreatePropertyCountry', async ({ propertyCountry, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_COUNTRIES}/store`, propertyCountry, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdatePropertyCountry = createAsyncThunk('propertyCountry/startUpdatePropertyCountry', async ({ id, propertyCountry, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_COUNTRIES}/update/${id}`, propertyCountry, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeletePropertyCountry = createAsyncThunk('propertyCountry/startDeletePropertyCountry', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${PROPERTY_COUNTRIES}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActivePropertyCountry = createAsyncThunk('propertyCountry/startActivePropertyCountry', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${PROPERTY_COUNTRIES}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPropertyCountry = createAsyncThunk('propertyCountry/startFetchPropertyCountry', async ({ propertyCountry, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_COUNTRIES}/edit/${propertyCountry}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            country_en: data.country_en,
            country_el: data.country_el,
            geometry_location_id: data.geometry_location_id,
            kml: data.kml,
            lat: data.lat,
            lng: data.lng,
            portal: data.portal === 1 ? true : false
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('propertyCountry/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('propertyCountry/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('propertyCountry/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    propertyCountries: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    createPropertyCountry: '',
    updatePropertyCountry: '',
    deletePropertyCountry: '',
    propertyCountryData: {
        edit:false,
        geometry_location_id: '',
        country_en: '',
        country_el: '',
        kml: '',
        lat: '37.9815336',
        lng: '23.7493355',
        portal: false
    },
    dataFields: {
        geometry_locations: [] 
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const propertyCountrySlice = createSlice({
    name: 'propertyCountry',
    initialState,
    reducers: {
        fetchPropertyCountriesSuccess(state, { payload }) {
            return { ...state, loading: false, propertyCountries: payload, error: '' };
        },
        clearPropertyCountry(state) {
            return { ...state, loadingBtn: false, loading: false, propertyCountryData: initialState.propertyCountryData, error: '' };
        },
        clearAlertPropertyCountry(state) {
            return { ...state, updatePropertyCountry: '', createPropertyCountry: '', deletePropertyCountry: '', activePropertyCountry: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchPropertyCountries.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchPropertyCountries.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, propertyCountries: payload, error: '' };
      },
      [startFetchPropertyCountries.rejected]: (state, { payload }) => {
        return { ...state, loading: false, propertyCountries: initialState.propertyCountries, error: payload };
      },

      [startFetchPropertyCountry.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchPropertyCountry.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, propertyCountryData: payload, error: '' };
      },
      [startFetchPropertyCountry.rejected]: (state, { payload }) => {
        return { ...state, loading: false, propertyCountryData: '', error: payload };
      },
      
      [startCreatePropertyCountry.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreatePropertyCountry.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createPropertyCountry: payload, error: '' };
      },
      [startCreatePropertyCountry.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createPropertyCountry: '', error: payload };
      },

      [startUpdatePropertyCountry.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdatePropertyCountry.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updatePropertyCountry: payload, error: '' };
      },
      [startUpdatePropertyCountry.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updatePropertyCountry: '', error: payload };
      },

      [startDeletePropertyCountry.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deletePropertyCountry: payload, error: '' };
      },
      [startDeletePropertyCountry.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deletePropertyCountry: '', error: payload };
      },

      [startActivePropertyCountry.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activePropertyCountry: payload, error: '' };
      },
      [startActivePropertyCountry.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activePropertyCountry: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload }; 
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const propertyCountryActions = {
    ...propertyCountrySlice.actions,
    startFetchPropertyCountries,
    startFetchPropertyCountry,
    startCreatePropertyCountry,
    startUpdatePropertyCountry,
    startDeletePropertyCountry,
    startActivePropertyCountry,
    startFetchDataFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default propertyCountrySlice.reducer