import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, ACCOUNT } from '../../constants/defaultValues';
import { authUserActions } from "../auth/authUserSlice";

const startSaveAccount = createAsyncThunk('account/startSaveAccount', async ({ id, user, locale, token, form }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${ACCOUNT}/save/${id}`, user, { headers });

        if(res.data){
            form.setStatus({ success: true })
            dispatch(setStep(2))
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('account/startFetchDataFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${ACCOUNT}/dataFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchAccount = createAsyncThunk('account/startFetchAccount', async ({ user, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${ACCOUNT}/edit/${user}`, { headers });

        const data = res.data;
        
        const results = {
            id: data.id,
            name: data.name,
            surname: data.surname,
            email: data.email,
            vat: data.vat,
            tax: data.tax,
            gemi: data.gemi,
            birthday: data.birthday ? new Date(data.birthday) : null,
            passport: data.passport,
            address: data.address,
            country: data.country,
            iso: data.iso,
            lat: Number(data.lat),
            lng: Number(data.lng),
            extraPhones: data.extraPhones,
            extraMobiles: data.extraMobiles
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startSavePrivacy = createAsyncThunk('account/startSavePrivacy', async ({ id, user, locale, token, form }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${ACCOUNT}/savePrivacy/${id}`, user, { headers });

        if(res.data){
            form.setStatus({ success: true })
            dispatch(setStep(3))
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPrivacy = createAsyncThunk('account/startFetchPrivacy', async ({ user, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${ACCOUNT}/editPrivacy/${user}`, { headers });

        const data = res.data;
        
        const results = {
            id: data.id,
            signature: data.signature,
            terms: data.terms,
            privacy: data.privacy
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPayment = createAsyncThunk('account/startFetchPayment', async ({ id, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${ACCOUNT}/payment/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startSuccessPayment = createAsyncThunk('account/startSuccessPayment', async ({ author, payment, renewal, transaction,  locale, token }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const obj = {
            user_id: author.user.id,
            payment_id: payment,
            transaction_id: transaction,
            renewal: renewal
        };

        const res = await axios.post(`${ACCOUNT}/successPayment`, obj, { headers });

        if(res.data){
            let new_author = {...author};
            new_author.package = true;
            new_author.spm = true;
            
            dispatch(authUserActions.updateAuth(new_author));
        }

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setStep = createAsyncThunk('account/setStep', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    accountData: {
        id: '',
        name: '',
        surname: '',
        email: '',
        vat: '',
        tax: '',
        gemi: '',
        birthday: null,
        passport: '',
        address: '',
        country: '',
        iso: '',
        lat: '',
        lng: '',
        extraPhones: [],
        extraMobiles: []
    },
    privacyData: {
        id: '',
        signature: '',
        terms: 0,
        privacy: 0
    },
    paymentData: {
        id: '',
        clientSecret: '',
        package: [],
        banks: []
    },
    step: 0,
    saveAccount: '',
    savePrivacy: '',
    payment: '',
    dataFields: {
        countries: []
    },
    loadingBtn: false,
    loading: false,
    error: ''
}

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        clearAccount(state) {
            return { ...state, loadingBtn: false, loading: false, accountData: initialState.accountData, privacyData: initialState.privacyData, error: '' };
        },
        clearAlertAccount(state) {
            return { ...state, saveAccount: '', savePrivacy: '', error: '' };
        }
    },
    extraReducers: {
      [startSaveAccount.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startSaveAccount.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, saveAccount: payload, error: '' };
      },
      [startSaveAccount.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, saveAccount: '', error: payload };
      },

      [startFetchAccount.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchAccount.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, accountData: payload, error: '' };
      },
      [startFetchAccount.rejected]: (state, { payload }) => {
        return { ...state, loading: false, accountData: initialState.accountData, error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startSavePrivacy.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startSavePrivacy.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, savePrivacy: payload, error: '' };
      },
      [startSavePrivacy.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, savePrivacy: '', error: payload };
      },

      [startFetchPrivacy.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchPrivacy.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, privacyData: payload, error: '' };
      },
      [startFetchPrivacy.rejected]: (state, { payload }) => {
        return { ...state, loading: false, privacyData: initialState.privacyData, error: payload };
      },

      [startFetchPayment.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchPayment.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, paymentData: payload, error: '' };
      },
      [startFetchPayment.rejected]: (state, { payload }) => {
        return { ...state, loading: false, paymentData: initialState.paymentData, error: payload };
      },

      [startSuccessPayment.fulfilled]: (state) => {
        return { ...state, loading: false, error: '' };
      },

      //Heading Functions
      [setStep.fulfilled]: (state, { payload }) => {
        return { ...state, step: payload }
      }
    },
});


export const accountActions = {
    ...accountSlice.actions,
    startSaveAccount,
    startFetchAccount,
    startFetchDataFields,
    startSavePrivacy,
    startFetchPrivacy,
    startFetchPayment,
    startSuccessPayment,
    setStep
}
export default accountSlice.reducer