import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, PROPERTY_AREAS } from '../../constants/defaultValues';

const startFetchDataFields = createAsyncThunk('propertyArea/startFetchDataFields', async ({ locale, token, ifEdit, area_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_AREAS}/dataFields?edit=${ifEdit}&area_id=${area_id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataSelectFields = createAsyncThunk('propertyArea/startFetchDataSelectFields', async ({ locale, token, ifEdit, country_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_AREAS}/selectField?country_id=${country_id}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataSelectSecondFields = createAsyncThunk('propertyArea/startFetchDataSelectSecondFields', async ({ locale, token, ifEdit, country_id, prefecture_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_AREAS}/selectSecondField?country_id=${country_id}&prefecture_id=${prefecture_id}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPropertyAreas = createAsyncThunk('propertyArea/startFetchPropertyAreas', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${PROPERTY_AREAS}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreatePropertyArea = createAsyncThunk('propertyArea/startCreatePropertyArea', async ({ propertyArea, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_AREAS}/store`, propertyArea, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdatePropertyArea = createAsyncThunk('propertyArea/startUpdatePropertyArea', async ({ id, propertyArea, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_AREAS}/update/${id}`, propertyArea, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeletePropertyArea = createAsyncThunk('propertyArea/startDeletePropertyArea', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${PROPERTY_AREAS}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActivePropertyArea = createAsyncThunk('propertyArea/startActivePropertyArea', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${PROPERTY_AREAS}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPropertyArea = createAsyncThunk('propertyArea/startFetchPropertyArea', async ({ propertyArea, history, locale, token }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_AREAS}/edit/${propertyArea}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            area_en: data.area_en,
            area_el: data.area_el,
            country_id: data.country_id,
            prefecture_id: data.prefecture_id,
            municipality_id: data.municipality_id,
            lat: data.lat,
            lng: data.lng,
            zoom: data.zoom,
            geometric_location: data.geometric_location ? data.geometric_location : [],
            pref_sp_id: data.pref_sp_id,
            mun_sp_id: data.mun_sp_id,
            area_sp_id: data.area_sp_id,
            area_xe_id: data.area_xe_id,
            postal: data.postal,
            mortgage_office_type_id: data.mortgage_office_type_id,
            portal: data.portal === 1 ? true : false
        };

        dispatch(propertyAreaSlice.actions.dataSelectFields({prefectures: data.prefectures}))
        dispatch(propertyAreaSlice.actions.dataSelectSecondFields({municipalities: data.municipalities}))

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('propertyArea/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('propertyArea/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('propertyArea/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    propertyAreas: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    createPropertyArea: '',
    updatePropertyArea: '',
    deletePropertyArea: '',
    propertyAreaData: {
        edit:false,
        area_en: '',
        area_el: '',
        area_de: '',
        area_sr: '',
        area_bg: '',
        area_ru: '',
        lat: '37.9815336',
        lng: '23.7493355',
        zoom: 6,
        geometric_location: [],
        country_id: '',
        prefecture_id: '',
        municipality_id: '',
        pref_sp_id: '',
        mun_sp_id: '',
        area_sp_id: '',
        area_xe_id: '',
        postal: '',
        mortgage_office_type_id: '',
        portal: false
    },
    dataSelectFields: {
        prefectures: []
    },
    dataSelectSecondFields: {
        municipalities: []
    },
    dataFields: {
        countries: [],
        locations: [],
        mortgage_offices: [] 
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const propertyAreaSlice = createSlice({
    name: 'propertyArea',
    initialState,
    reducers: {
        fetchPropertyAreasSuccess(state, { payload }) {
            return { ...state, loading: false, propertyAreas: payload, error: '' };
        },
        dataSelectFields(state, { payload }){
            return { ...state, dataSelectFields: payload, error: '' };
        },
        dataSelectSecondFields(state, { payload }){
            return { ...state, dataSelectSecondFields: payload, error: '' };
        },
        clearPropertyArea(state) {
            return { ...state, loadingBtn: false, loading: false, propertyAreaData: initialState.propertyAreaData, error: '' };
        },
        clearAlertPropertyArea(state) {
            return { ...state, updatePropertyArea: '', createPropertyArea: '', deletePropertyArea: '', activePropertyArea: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchPropertyAreas.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchPropertyAreas.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, propertyAreas: payload, error: '' };
      },
      [startFetchPropertyAreas.rejected]: (state, { payload }) => {
        return { ...state, loading: false, propertyAreas: initialState.propertyAreas, error: payload };
      },

      [startFetchPropertyArea.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchPropertyArea.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, propertyAreaData: payload, error: '' };
      },
      [startFetchPropertyArea.rejected]: (state, { payload }) => {
        return { ...state, loading: false, propertyAreaData: '', error: payload };
      },
      
      [startCreatePropertyArea.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreatePropertyArea.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createPropertyArea: payload, error: '' };
      },
      [startCreatePropertyArea.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createPropertyArea: '', error: payload };
      },

      [startUpdatePropertyArea.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdatePropertyArea.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updatePropertyArea: payload, error: '' };
      },
      [startUpdatePropertyArea.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updatePropertyArea: '', error: payload };
      },

      [startDeletePropertyArea.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deletePropertyArea: payload, error: '' };
      },
      [startDeletePropertyArea.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deletePropertyArea: '', error: payload };
      },

      [startActivePropertyArea.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activePropertyArea: payload, error: '' };
      },
      [startActivePropertyArea.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activePropertyArea: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload }; 
      },

      [startFetchDataSelectFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataSelectFields: payload, error: '' };
      },
      [startFetchDataSelectFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataSelectFields: initialState.dataSelectFields, error: payload };
      },

      [startFetchDataSelectSecondFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataSelectSecondFields: payload, error: '' };
      },
      [startFetchDataSelectSecondFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataSelectSecondFields: initialState.dataSelectSecondFields, error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const propertyAreaActions = {
    ...propertyAreaSlice.actions,
    startFetchPropertyAreas,
    startFetchPropertyArea,
    startCreatePropertyArea,
    startUpdatePropertyArea,
    startDeletePropertyArea,
    startActivePropertyArea,
    startFetchDataFields,
    startFetchDataSelectFields,
    startFetchDataSelectSecondFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default propertyAreaSlice.reducer