/* 
JWT Auth Token for connected to the api
*/
export const jwt_secret = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_JWT : process.env.REACT_APP_JWT;

/* 
GOOGLE KEY for connected to the api
*/
export const google_secret = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_GOOGLE_KEY : process.env.REACT_APP_GOOGLE_KEY;

/* 
Base urls for api proxys
*/
export const PROXY = () => {
  if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
    return process.env.REACT_APP_DEV_PROXY;
  }else{
    return window.location.origin === "https://dev-control.rewize.com" ? process.env.REACT_APP_DEV_SERVER_PROXY : process.env.REACT_APP_PROXY;
  }
} 

/* 
Base urls for api and sites
*/
export const BASEURLAPI = () => {
  if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
    return process.env.REACT_APP_DEV_BASEURLAPI;
  }else{
    return window.location.origin === "https://dev-control.rewize.com" ? process.env.REACT_APP_DEV_SERVER_BASEURLAPI : process.env.REACT_APP_BASEURLAPI;
  }
} 
export const BASEURLREWIZECONTROL = () => {
  if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
    return  process.env.REACT_APP_DEV_BASEURLREWIZECONTROL;
  }else{
    return window.location.origin === "https://dev-control.rewize.com" ? process.env.REACT_APP_DEV_SERVER_BASEURLREWIZECONTROL : process.env.REACT_APP_BASEURLREWIZECONTROL;
  }
} 
export const BASEURLAPIIMAGES = () => {
  if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
    return process.env.REACT_APP_DEV_BASEURLAPIIMAGES;
  }else{
    return window.location.origin === "https://dev-control.rewize.com" ? process.env.REACT_APP_DEV_SERVER_BASEURLAPIIMAGES : process.env.REACT_APP_BASEURLAPIIMAGES;
  }
} 
export const BASEURLMEDIA = () => {
  if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
    return process.env.REACT_APP_DEV_BASEURLMEDIA;
  }else{
    return window.location.origin === "https://dev-control.rewize.com" ? process.env.REACT_APP_DEV_SERVER_BASEURLMEDIA : process.env.REACT_APP_BASEURLMEDIA;
  }
} 
export const BASEURLREWIZE = () => {
  if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
    return process.env.REACT_APP_DEV_BASEURLREWIZE;
  }else{
    return window.location.origin === "https://dev-control.rewize.com" ? process.env.REACT_APP_DEV_SERVER_BASEURLREWIZE : process.env.REACT_APP_BASEURLREWIZE;
  }
} 

export const STRIPE = () => {
  if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
    return process.env.REACT_APP_DEV_STRIPE_KEY;
  }else{
    return window.location.origin === "https://dev-control.rewize.com" ? process.env.REACT_APP_DEV_STRIPE_KEY : process.env.REACT_APP_STRIPE_KEY;
  }
} 

//AUTH URL
export const AUTH = `${BASEURLAPI()}/${process.env.REACT_APP_AUTH}`;

//START URL
export const START = `${BASEURLAPI()}/${process.env.REACT_APP_START}`;

//CALENDAR URL
export const CALENDAR = `${BASEURLAPI()}/${process.env.REACT_APP_CALENDAR}`;

//FILE TEMPLATES URL
export const FILE_TEMPLATES = `${BASEURLAPI()}/${process.env.REACT_APP_FILE_TEMPLATES}`;

//KNOWLEDGE DATABASE URL
export const KNOWLEDGE_DATABASES = `${BASEURLAPI()}/${process.env.REACT_APP_KNOWLEDGE_DATABASES}`;

//COMMENTS URL
export const COMMENTS = `${BASEURLAPI()}/${process.env.REACT_APP_COMMENTS}`;

//LEAVE OF ABSENCE URL
export const ABSENCE = `${BASEURLAPI()}/${process.env.REACT_APP_ABSENCE}`;

//LIVE GOALS URL
export const LIVE_GOALS = `${BASEURLAPI()}/${process.env.REACT_APP_LIVE_GOALS}`;

//LIVE CAMPAIGNS URL
export const CAMPAIGN = `${BASEURLAPI()}/${process.env.REACT_APP_CAMPAIGN}`;

//LEGAL AUDIT URL 
export const LEGAL_AUDIT = `${BASEURLAPI()}/${process.env.REACT_APP_LEGAL_AUDIT}`;

//GROUP URL
export const GROUP = `${BASEURLAPI()}/${process.env.REACT_APP_GROUP}`;

//PROPERTY URL
export const PROPERTY = `${BASEURLAPI()}/${process.env.REACT_APP_PROPERTY}`;

//WEBSITE PROPERTIES URL
export const WEBSITE_PROPERTY = `${BASEURLAPI()}/${process.env.REACT_APP_WEBSITE_PROPERTY}`;

//AUCTION PROPERTIES URL
export const AUCTION_PROPERTY = `${BASEURLAPI()}/${process.env.REACT_APP_AUCTION_PROPERTY}`;

//ASSIGNMENT URL
export const ASSIGNMENTS = `${BASEURLAPI()}/${process.env.REACT_APP_ASSIGNMENTS}`;

//INDICATION URL
export const INDICATIONS = `${BASEURLAPI()}/${process.env.REACT_APP_INDICATIONS}`;

//COMPLEXES URL
export const COMPLEXES = `${BASEURLAPI()}/${process.env.REACT_APP_COMPLEXES}`;

//PROPERTY BUCKET URL
export const PROPERTY_BUCKET = `${BASEURLAPI()}/${process.env.REACT_APP_PROPERTY_BUCKET}`;

//REQUEST BUCKET URL 
export const REQUEST_BUCKET = `${BASEURLAPI()}/${process.env.REACT_APP_REQUEST_BUCKET}`;

//REQUESTS
export const REQUEST = `${BASEURLAPI()}/${process.env.REACT_APP_REQUEST}`;

//AUCTION LEAD
export const AUCTION_LEAD = `${BASEURLAPI()}/${process.env.REACT_APP_AUCTION_LEAD}`;

//DEPOSITS
export const DEPOSIT = `${BASEURLAPI()}/${process.env.REACT_APP_DEPOSIT}`;

//DEALS
export const DEAL = `${BASEURLAPI()}/${process.env.REACT_APP_DEAL}`;

//USER URL
export const USER = `${BASEURLAPI()}/${process.env.REACT_APP_USER}`;

//PAYMENT URL
export const PAYMENTS = `${BASEURLAPI()}/${process.env.REACT_APP_PAYMENT}`;

//ACCOUNT URL
export const ACCOUNT = `${BASEURLAPI()}/${process.env.REACT_APP_ACCOUNT}`;

//PERMISSIONS URL
export const PERMISSIONS = `${BASEURLAPI()}/${process.env.REACT_APP_PERMISSIONS}`;

//ROLES URL
export const ROLES = `${BASEURLAPI()}/${process.env.REACT_APP_ROLES}`;

//CONTACT URL
export const CONTACT = `${BASEURLAPI()}/${process.env.REACT_APP_CONTACT}`;

/************** START MENUS URLS ******************/

//PARENT MENUS URL
export const PARENT_MENUS = `${BASEURLAPI()}/${process.env.REACT_APP_PARENT_MENUS}`;

//SECTION MENUS URL
export const SECTION_MENUS = `${BASEURLAPI()}/${process.env.REACT_APP_SECTION_MENUS}`;

//CHILDREN MENUS URL
export const CHILDREN_MENUS = `${BASEURLAPI()}/${process.env.REACT_APP_CHILDREN_MENUS}`;

/************** END MENUS URLS ******************/

/************** START DYNAMIC PAGES URLS ******************/

//ARTICLE CATEGORIES URL
export const ARTICLE_CATEGORIES = `${BASEURLAPI()}/${process.env.REACT_APP_ARTICLE_CATEGORIES}`;

//ARTICLE URL
export const ARTICLES = `${BASEURLAPI()}/${process.env.REACT_APP_ARTICLES}`;

//CAREER URL
export const CAREERS = `${BASEURLAPI()}/${process.env.REACT_APP_CAREERS}`;

//CVS URL
export const CVS = `${BASEURLAPI()}/${process.env.REACT_APP_CVS}`;

//FAQS URL
export const FAQS = `${BASEURLAPI()}/${process.env.REACT_APP_FAQS}`;

//PARTNERS URL
export const PARTNERS = `${BASEURLAPI()}/${process.env.REACT_APP_PARTNERS}`;

//OURTEAMS URL
export const OURTEAMS = `${BASEURLAPI()}/${process.env.REACT_APP_OURTEAMS}`;

/************** END DYNAMIC PAGES URLS ******************/

/************** START STATIC PAGES URLS ******************/

//HOME PAGE CONFIG URL
export const HOME_PAGE_CONFIG = `${BASEURLAPI()}/${process.env.REACT_APP_HOME_PAGE_CONFIG}`;

//CONTACT PAGE CONFIG URL
export const CONTACT_PAGE_CONFIG = `${BASEURLAPI()}/${process.env.REACT_APP_CONTACT_PAGE_CONFIG}`;

/************** END STATIC PAGES URLS ******************/

/************** START APP SETTINGS URLS ******************/

//PROPERTY CATEGORIES URL
export const PROPERTY_CATEGORIES = `${BASEURLAPI()}/${process.env.REACT_APP_PROPERTY_CATEGORIES}`;

//PROPERTY SUBCATEGORIES URL
export const PROPERTY_SUBCATEGORIES = `${BASEURLAPI()}/${process.env.REACT_APP_PROPERTY_SUBCATEGORIES}`;

//COUNTRIES URL
export const COUNTRIES = `${BASEURLAPI()}/${process.env.REACT_APP_COUNTRIES}`;

//TIMEZONES URL
export const TIMEZONES = `${BASEURLAPI()}/${process.env.REACT_APP_TIMEZONES}`;

//EXTRAS URL
export const EXTRAS = `${BASEURLAPI()}/${process.env.REACT_APP_EXTRAS}`;

//TYPES URL
export const TYPES = `${BASEURLAPI()}/${process.env.REACT_APP_TYPES}`;

//TYPESYNCS URL
export const TYPESYNCS = `${BASEURLAPI()}/${process.env.REACT_APP_TYPESYNCS}`;

//CONTACT SCORE URL
export const CONTACT_SCORE = `${BASEURLAPI()}/${process.env.REACT_APP_CONTACT_SCORE}`;

//PROPERTY SCORE URL
export const PROPERTY_SCORE = `${BASEURLAPI()}/${process.env.REACT_APP_PROPERTY_SCORE}`;

//READINESS SCORE URL
export const READINESS_SCORE = `${BASEURLAPI()}/${process.env.REACT_APP_READINESS_SCORE}`;

//QUARTER GOALS SCORE URL
export const QUARTER_GOALS = `${BASEURLAPI()}/${process.env.REACT_APP_QUARTER_GOALS}`;

//CLOSED DAYS URL
export const CLOSED_DAYS = `${BASEURLAPI()}/${process.env.REACT_APP_CLOSED_DAYS}`;

//DAYS OFF URL
export const DAYS_OFF = `${BASEURLAPI()}/${process.env.REACT_APP_DAYS_OFF}`;

//AUTO DESCRIPTION URL
export const AUTO_DESCRIPTION = `${BASEURLAPI()}/${process.env.REACT_APP_AUTO_DESCRIPTION}`;

//TASKS URL
export const TASKS = `${BASEURLAPI()}/${process.env.REACT_APP_TASKS}`;

/************** END APP SETTINGS URLS ******************/

/************** START REPORTS URLS ******************/

//BUGS URL
export const BUGS = `${BASEURLAPI()}/${process.env.REACT_APP_BUGS}`;

//ACTIVITY URL
export const ACTIVITY = `${BASEURLAPI()}/${process.env.REACT_APP_ACTIVITY}`;

//ACCEPTANCE OF DELETION URL
export const ACCEPTANCE_DELETION = `${BASEURLAPI()}/${process.env.REACT_APP_ACCEPTANCE_DELETION}`;

/************** END REPORTS URLS ******************/

/************** START LOCATIONS URLS ******************/

//PROPERTY COUNTRIES URL
export const PROPERTY_COUNTRIES = `${BASEURLAPI()}/${process.env.REACT_APP_PROPERTY_COUNTRIES}`;

//PROPERTY PREFECTURES URL
export const PROPERTY_PREFECTURES = `${BASEURLAPI()}/${process.env.REACT_APP_PROPERTY_PREFECTURES}`;

//PROPERTY MUNICIPALITIES URL
export const PROPERTY_MUNICIPALITIES = `${BASEURLAPI()}/${process.env.REACT_APP_PROPERTY_MUNICIPALITIES}`;

//PROPERTY AREAS URL
export const PROPERTY_AREAS = `${BASEURLAPI()}/${process.env.REACT_APP_PROPERTY_AREAS}`;

/************** END LOCATIONS URLS ******************/

// NOTIFICATIONS URL
export const NOTIFICATION = `${BASEURLAPI()}/${process.env.REACT_APP_NOTIFICATION}`;

//SOCIAL NETWORKS
export const FACEBOOK = `https://www.facebook.com`;
export const INSTAGRAM = `https://www.instagram.com`;
export const TWITTER = `https://twitter.com`;
export const LINKEDIN_COMP = `https://www.linkedin.com/company`;

//Headers for applications requests
export const HEADERS = {
  'Access-Control-Allow-Origin': '*',
  'Accept':'application/json',
};

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English", direction: "ltr" },
  { id: "el", name: "Ελληνικά", direction: "ltr" },
];

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const themeColorStorageKey = "__theme_color"
export const isMultiColorActive = true;
export const isDarkSwitchActive = true;
export const defaultColor = "light.purple";
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;