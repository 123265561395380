import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, CONTACT_PAGE_CONFIG } from '../../constants/defaultValues';

const startFetchContactPageConfig = createAsyncThunk('contactPageConfig/startFetchContactPageConfig', async ({ route_id, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT_PAGE_CONFIG}/edit/${route_id}`, { headers });

        const data = res.data;

        const results = {
            route_id: data.route_id,
            id: data.id,
            heading_title_en: data.page_config.heading_title_en,
            heading_title_el: data.page_config.heading_title_el,
            address_en: data.page_config.address_en,
            address_el: data.page_config.address_el,
            alt_image_en: data.page_config.alt_image_en,
            alt_image_el: data.page_config.alt_image_el,
            meta_title_en: data.page_config.meta_title_en,
            meta_title_el: data.page_config.meta_title_el,
            meta_desc_en: data.page_config.meta_desc_en,
            meta_desc_el: data.page_config.meta_desc_el,
            meta_lsi_en: data.page_config.meta_lsi_en,
            meta_lsi_el: data.page_config.meta_lsi_el,
            from_time: data.page_config.from_time,
            to_time: data.page_config.to_time,
            sat_time_from: data.page_config.sat_time_from,
            sat_time_to: data.page_config.sat_time_to,
            phones: data.page_config.phones,
            emails: data.page_config.emails,
            image: data.page_config.image,
            image_name: data.page_config.image,
            file: ''
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateContactPageConfig = createAsyncThunk('contactPageConfig/startUpdateContactPageConfig', async ({ id, contact_page_config, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CONTACT_PAGE_CONFIG}/update/${id}`, contact_page_config, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('contactPageConfig/startFetchDataFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT_PAGE_CONFIG}/dataFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const initialState = {
    contactPageConfigData: {
        route_id: '',
        id: '',
        heading_title_en: "",
        heading_title_el: "",
        address_en: "",
        address_el: "",
        alt_image_en: '',
        alt_image_el: '',
        meta_title_en: '',
        meta_title_el: '',
        meta_desc_en: '',
        meta_desc_el: '',
        meta_lsi_en: [],
        meta_lsi_el: [],
        from_time: null,
        to_time: null,
        sat_time_from: null,
        sat_time_to: null,
        phones: [],
        emails: [],
        image: '',
        image_name: '',
        file: ''
    },
    dataFields: {
        countries: []
    },
    updateContactPageConfig: '',
    loadingBtn: false,
    loading: false,
    error: ''
}

export const contactPageConfigSlice = createSlice({
    name: 'contactPageConfig',
    initialState,
    reducers: {
        clearAlertContactPageConfig(state) {
            return { ...state, updateContactPageConfig: '', error: '' };
        }
    },
    extraReducers: {
      [startFetchContactPageConfig.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, loadingBtn: false, contactPageConfigData: payload, error: '' };
      },
      [startFetchContactPageConfig.rejected]: (state, { payload }) => {
        return { ...state, loading: false, loadingBtn: false, contactPageConfigData: initialState.contactPageConfigData, error: payload };
      },

      [startUpdateContactPageConfig.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateContactPageConfig.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateContactPageConfig: payload, error: '' };
      },
      [startUpdateContactPageConfig.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateContactPageConfig: '', error: payload.message };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },
    },
});


export const contactPageConfigActions = {
    ...contactPageConfigSlice.actions,
    startFetchContactPageConfig,
    startUpdateContactPageConfig,
    startFetchDataFields
}
export default contactPageConfigSlice.reducer