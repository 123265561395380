import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, HOME_PAGE_CONFIG } from '../../constants/defaultValues';

const startFetchHomePageConfig = createAsyncThunk('homePageConfig/startFetchHomePageConfig', async ({ route_id, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${HOME_PAGE_CONFIG}/edit/${route_id}`, { headers });

        const data = res.data;

        const results = {
            route_id: data.route_id,
            id: data.id,
            heading_title_en: data.page_config.heading_title_en,
            heading_title_el: data.page_config.heading_title_el,
            heading_subtitle_en: data.page_config.heading_subtitle_en,
            heading_subtitle_el: data.page_config.heading_subtitle_el,
            meta_title_en: data.page_config.meta_title_en,
            meta_title_el: data.page_config.meta_title_el,
            meta_desc_en: data.page_config.meta_desc_en,
            meta_desc_el: data.page_config.meta_desc_el,
            meta_lsi_en: data.page_config.meta_lsi_en,
            meta_lsi_el: data.page_config.meta_lsi_el,
            headingsData: data.page_config.headingsData ? data.page_config.headingsData : [],
            categoriesData: data.page_config.categoriesData,
            bannersData: data.page_config.bannersData,
            mobile_image: data.page_config.mobile_image,
            mobile_image_name: data.page_config.mobile_image,
            mobile_alt_en: data.page_config.mobile_alt_en,
            mobile_alt_el: data.page_config.mobile_alt_el,
            mobile_image_file: '',
            headingsFiles: [],
            categoriesFiles: [],
            properties: data.page_config.properties ? data.page_config.properties : []
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateHomePageConfig = createAsyncThunk('homePageConfig/startUpdateHomePageConfig', async ({ id, home_page_config, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${HOME_PAGE_CONFIG}/update/${id}`, home_page_config, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        const data = res.data.data;

        const results = {
            route_id: data.route_id,
            id: data.id,
            heading_title_en: data.page_config.heading_title_en,
            heading_title_el: data.page_config.heading_title_el,
            heading_subtitle_en: data.page_config.heading_subtitle_en,
            heading_subtitle_el: data.page_config.heading_subtitle_el,
            meta_title_en: data.page_config.meta_title_en,
            meta_title_el: data.page_config.meta_title_el,
            meta_desc_en: data.page_config.meta_desc_en,
            meta_desc_el: data.page_config.meta_desc_el,
            meta_lsi_en: data.page_config.meta_lsi_en,
            meta_lsi_el: data.page_config.meta_lsi_el,
            headingsData: data.page_config.headingsData ? data.page_config.headingsData : [],
            categoriesData: data.page_config.categoriesData,
            bannersData: data.page_config.bannersData,
            mobile_image_name: data.page_config.mobile_image,
            mobile_image: data.page_config.mobile_image,
            mobile_alt_en: data.page_config.mobile_alt_en,
            mobile_alt_el: data.page_config.mobile_alt_el,
            headingsFiles: [],
            categoriesFiles: [],
            properties: data.page_config.properties ? data.page_config.properties : []
        };

        return { status: res.data.status, data: results };
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const initialState = {
    homePageConfigData: {
        route_id: '',
        id: '',
        heading_title_en: "",
        heading_title_el: "",
        heading_subtitle_en: "",
        heading_subtitle_el: "",
        meta_title_en: '',
        meta_title_el: '',
        meta_desc_en: '',
        meta_desc_el: '',
        meta_lsi_en: [],
        meta_lsi_el: [],

        mobile_image_file: '',
        mobile_image: '',
        mobile_image_name: '',
        mobile_alt_en: '',
        mobile_alt_el: '',

        headingsData: [],
        categoriesData: [],
        bannersData: [],
        headingsFiles: [],
        categoriesFiles: [],
        properties: []
    },
    updateHomePageConfig: '',
    loadingBtn: false,
    loading: false,
    error: ''
}

export const homePageConfigSlice = createSlice({
    name: 'homePageConfig',
    initialState,
    reducers: {
        clearHomePageConfig(state) {
            return { ...state, loadingBtn: false, loading: false, homePageConfigData: initialState.homePageConfigData, error: '' };
        },
        clearAlertHomePageConfig(state) {
            return { ...state, updateHomePageConfig: '', error: '' };
        }
    },
    extraReducers: {
      [startFetchHomePageConfig.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchHomePageConfig.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, loadingBtn: false, homePageConfigData: payload, error: '' };
      },
      [startFetchHomePageConfig.rejected]: (state, { payload }) => {
        return { ...state, loading: false, loadingBtn: false, homePageConfigData: initialState.homePageConfigData, error: payload };
      },

      [startUpdateHomePageConfig.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateHomePageConfig.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateHomePageConfig: payload.status, homePageConfigData: payload.data, error: '' };
      },
      [startUpdateHomePageConfig.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateHomePageConfig: '', error: payload };
      },
    },
});


export const homePageConfigActions = {
    ...homePageConfigSlice.actions,
    startFetchHomePageConfig,
    startUpdateHomePageConfig
}
export default homePageConfigSlice.reducer