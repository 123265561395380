import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, PROPERTY_CATEGORIES } from '../../constants/defaultValues';

const startFetchPropertyCategories = createAsyncThunk('propertyCategory/startFetchPropertyCategories', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${PROPERTY_CATEGORIES}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreatePropertyCategory = createAsyncThunk('propertyCategory/startCreatePropertyCategory', async ({ propertyCategory, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_CATEGORIES}/store`, propertyCategory, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdatePropertyCategory = createAsyncThunk('propertyCategory/startUpdatePropertyCategory', async ({ id, propertyCategory, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_CATEGORIES}/update/${id}`, propertyCategory, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeletePropertyCategory = createAsyncThunk('propertyCategory/startDeletePropertyCategory', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${PROPERTY_CATEGORIES}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActivePropertyCategory = createAsyncThunk('propertyCategory/startActivePropertyCategory', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${PROPERTY_CATEGORIES}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPropertyCategory = createAsyncThunk('propertyCategory/startFetchPropertyCategory', async ({ propertyCategory, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_CATEGORIES}/edit/${propertyCategory}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            property_category_en: data.property_category_en,
            property_category_el: data.property_category_el,
            sort: data.sort,
            portal: data.portal === 1 ? true : false
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('propertyCategory/setSearch', ( args ) => {
    return args;
});

const setCurrentPage = createAsyncThunk('propertyCategory/setCurrentPage', ( args ) => {
    return args;
});

const setSort = createAsyncThunk('propertyCategory/setSort', ( args ) => {
    return args;
});

const initialState = {
    propertyCategories: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    createPropertyCategory: '',
    updatePropertyCategory: '',
    deletePropertyCategory: '',
    propertyCategoryData: {
        edit:false,
        property_category_en: '',
        property_category_el: '',
        sort: 0,
        portal: false
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const propertyCategorySlice = createSlice({
    name: 'propertyCategory',
    initialState,
    reducers: {
        fetchPropertyCategoriesSuccess(state, { payload }) {
            return { ...state, loading: false, propertyCategories: payload, error: '' };
        },
        clearPropertyCategory(state) {
            return { ...state, loadingBtn: false, loading: false, propertyCategoryData: initialState.propertyCategoryData, error: '' };
        },
        clearAlertPropertyCategory(state) {
            return { ...state, updatePropertyCategory: '', createPropertyCategory: '', deletePropertyCategory: '', activePropertyCategory: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchPropertyCategories.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchPropertyCategories.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, propertyCategories: payload, error: '' };
      },
      [startFetchPropertyCategories.rejected]: (state, { payload }) => {
        return { ...state, loading: false, propertyCategories: initialState.propertyCategories, error: payload };
      },

      [startFetchPropertyCategory.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchPropertyCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, propertyCategoryData: payload, error: '' };
      },
      [startFetchPropertyCategory.rejected]: (state, { payload }) => {
        return { ...state, loading: false, propertyCategoryData: '', error: payload };
      },
      
      [startCreatePropertyCategory.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreatePropertyCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createPropertyCategory: payload, error: '' };
      },
      [startCreatePropertyCategory.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createPropertyCategory: '', error: payload };
      },

      [startUpdatePropertyCategory.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdatePropertyCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updatePropertyCategory: payload, error: '' };
      },
      [startUpdatePropertyCategory.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updatePropertyCategory: '', error: payload };
      },

      [startDeletePropertyCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deletePropertyCategory: payload, error: '' };
      },
      [startDeletePropertyCategory.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deletePropertyCategory: '', error: payload };
      },

      [startActivePropertyCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activePropertyCategory: payload, error: '' };
      },
      [startActivePropertyCategory.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activePropertyCategory: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const propertyCategoryActions = {
    ...propertyCategorySlice.actions,
    startFetchPropertyCategories,
    startFetchPropertyCategory,
    startCreatePropertyCategory,
    startUpdatePropertyCategory,
    startDeletePropertyCategory,
    startActivePropertyCategory,
    setSearch,
    setCurrentPage,
    setSort
}
export default propertyCategorySlice.reducer