import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, SECTION_MENUS } from '../../constants/defaultValues';

const startFetchDataFields = createAsyncThunk('sectionMenu/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${SECTION_MENUS}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSectionMenus = createAsyncThunk('sectionMenu/startFetchSectionMenus', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${SECTION_MENUS}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateSectionMenu = createAsyncThunk('sectionMenu/startCreateSectionMenu', async ({ section_menu, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${SECTION_MENUS}/store`, section_menu, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateSectionMenu = createAsyncThunk('sectionMenu/startUpdateSectionMenu', async ({ id, section_menu, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${SECTION_MENUS}/update/${id}`, section_menu, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteSectionMenu = createAsyncThunk('sectionMenu/startDeleteSectionMenu', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${SECTION_MENUS}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveSectionMenu = createAsyncThunk('sectionMenu/startActiveSectionMenu', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${SECTION_MENUS}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSectionMenu = createAsyncThunk('sectionMenu/startFetchSectionMenu', async ({ section_menu, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${SECTION_MENUS}/edit/${section_menu}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            parent_menu_id: data.parent_menu_id,
            section_menu_en: data.section_menu_en,
            section_menu_el: data.section_menu_el,
            sort: data.sort
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('sectionMenu/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('sectionMenu/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('sectionMenu/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    sectionMenus: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    sectionMenuData: {
        parent_menu_id: '',
        section_menu_en: '',
        section_menu_el: '',
        sort: 0
    },
    createSectionMenu: '',
    updateSectionMenu: '',
    deleteSectionMenu: '',
    dataFields: {
        parent_menus: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const sectionMenuSlice = createSlice({
    name: 'sectionMenu',
    initialState,
    reducers: {
        fetchSectionMenusSuccess(state, { payload }) {
            return { ...state, loading: false, sectionMenus: payload, error: '' };
        },
        clearSectionMenu(state) {
            return { ...state, loadingBtn: false, loading: false, sectionMenuData: initialState.sectionMenuData, error: '' };
        },
        clearAlertSectionMenu(state) {
            return { ...state, updateSectionMenu: '', createSectionMenu: '', deleteSectionMenu: '', activeSectionMenu: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchSectionMenus.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSectionMenus.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, sectionMenus: payload, error: '' };
      },
      [startFetchSectionMenus.rejected]: (state, { payload }) => {
        return { ...state, loading: false, sectionMenus: initialState.sectionMenus, error: payload };
      },

      [startFetchSectionMenu.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSectionMenu.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, sectionMenuData: payload, error: '' };
      },
      [startFetchSectionMenu.rejected]: (state, { payload }) => {
        return { ...state, loading: false, sectionMenuData: '', error: payload };
      },
      
      [startCreateSectionMenu.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateSectionMenu.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createSectionMenu: payload, error: '' };
      },
      [startCreateSectionMenu.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createSectionMenu: '', error: payload };
      },

      [startUpdateSectionMenu.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateSectionMenu.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateSectionMenu: payload, error: '' };
      },
      [startUpdateSectionMenu.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateSectionMenu: '', error: payload };
      },

      [startDeleteSectionMenu.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteSectionMenu: payload, error: '' };
      },
      [startDeleteSectionMenu.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteSectionMenu: '', error: payload };
      },

      [startActiveSectionMenu.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeSectionMenu: payload, error: '' };
      },
      [startActiveSectionMenu.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeSectionMenu: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload }; 
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const sectionMenuActions = {
    ...sectionMenuSlice.actions,
    startFetchSectionMenus,
    startFetchSectionMenu,
    startCreateSectionMenu,
    startUpdateSectionMenu,
    startDeleteSectionMenu,
    startActiveSectionMenu,
    startFetchDataFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default sectionMenuSlice.reducer