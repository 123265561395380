import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, PROPERTY_SUBCATEGORIES } from '../../constants/defaultValues';

const startFetchDataFields = createAsyncThunk('propertySubcategory/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_SUBCATEGORIES}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPropertySubcategories = createAsyncThunk('propertySubcategory/startFetchPropertySubcategories', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${PROPERTY_SUBCATEGORIES}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreatePropertySubcategory = createAsyncThunk('propertySubcategory/startCreatePropertySubcategory', async ({ propertySubcategory, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_SUBCATEGORIES}/store`, propertySubcategory, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdatePropertySubcategory = createAsyncThunk('propertySubcategory/startUpdatePropertySubcategory', async ({ id, propertySubcategory, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_SUBCATEGORIES}/update/${id}`, propertySubcategory, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeletePropertySubcategory = createAsyncThunk('propertySubcategory/startDeletePropertySubcategory', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${PROPERTY_SUBCATEGORIES}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActivePropertySubcategory = createAsyncThunk('propertySubcategory/startActivePropertySubcategory', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${PROPERTY_SUBCATEGORIES}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPropertySubcategory = createAsyncThunk('propertySubcategory/startFetchPropertySubcategory', async ({ propertySubcategory, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_SUBCATEGORIES}/edit/${propertySubcategory}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            property_subcategory_en: data.property_subcategory_en,
            property_subcategory_el: data.property_subcategory_el,
            property_category_id: data.property_category_id,
            sort: data.sort,
            portal: data.portal === 1 ? true : false
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('propertySubcategory/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('propertySubcategory/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('propertySubcategory/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    propertySubcategories: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    createPropertySubcategory: '',
    updatePropertySubcategory: '',
    deletePropertySubcategory: '',
    propertySubcategoryData: {
        edit:false,
        property_subcategory_en: '',
        property_subcategory_el: '',
        property_category_id: '',
        sort: 0,
        portal: false
    },
    dataFields: {
        property_categories: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const propertySubcategorySlice = createSlice({
    name: 'propertySubcategory',
    initialState,
    reducers: {
        fetchPropertySubcategoriesSuccess(state, { payload }) {
            return { ...state, loading: false, propertySubcategories: payload, error: '' };
        },
        clearPropertySubcategory(state) {
            return { ...state, loadingBtn: false, loading: false, propertySubcategoryData: initialState.propertySubcategoryData, error: '' };
        },
        clearAlertPropertySubcategory(state) {
            return { ...state, updatePropertySubcategory: '', createPropertySubcategory: '', deletePropertySubcategory: '', activePropertySubcategory: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchPropertySubcategories.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchPropertySubcategories.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, propertySubcategories: payload, error: '' };
      },
      [startFetchPropertySubcategories.rejected]: (state, { payload }) => {
        return { ...state, loading: false, propertySubcategories: initialState.propertySubcategories, error: payload };
      },

      [startFetchPropertySubcategory.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchPropertySubcategory.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, propertySubcategoryData: payload, error: '' };
      },
      [startFetchPropertySubcategory.rejected]: (state, { payload }) => {
        return { ...state, loading: false, propertySubcategoryData: '', error: payload };
      },
      
      [startCreatePropertySubcategory.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreatePropertySubcategory.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createPropertySubcategory: payload, error: '' };
      },
      [startCreatePropertySubcategory.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createPropertySubcategory: '', error: payload };
      },

      [startUpdatePropertySubcategory.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdatePropertySubcategory.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updatePropertySubcategory: payload, error: '' };
      },
      [startUpdatePropertySubcategory.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updatePropertySubcategory: '', error: payload };
      },

      [startDeletePropertySubcategory.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deletePropertySubcategory: payload, error: '' };
      },
      [startDeletePropertySubcategory.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deletePropertySubcategory: '', error: payload };
      },

      [startActivePropertySubcategory.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activePropertySubcategory: payload, error: '' };
      },
      [startActivePropertySubcategory.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activePropertySubcategory: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload }; 
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const propertySubcategoryActions = {
    ...propertySubcategorySlice.actions,
    startFetchPropertySubcategories,
    startFetchPropertySubcategory,
    startCreatePropertySubcategory,
    startUpdatePropertySubcategory,
    startDeletePropertySubcategory,
    startActivePropertySubcategory,
    startFetchDataFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default propertySubcategorySlice.reducer