import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, PROPERTY } from '../../constants/defaultValues';

const startSaveProperty = createAsyncThunk('property/startSaveProperty', async ({ token, history, locale, data, index, exit, steps, loading }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY}/save`, data, { headers });

        dispatch(propertySlice.actions.setEditAction({ property_id: res.data.property_id, editAction: res.data.editAction }));

        let new_steps = [...steps];
        new_steps[index] = 1;

        dispatch(propertySlice.actions.setSteps(new_steps));

        loading();

        if (exit) {
            dispatch(propertySlice.actions.clearProperty());
            history.push('/app/properties/properties-list');
        }

        return res.data.status;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchProperty = createAsyncThunk('property/startFetchProperty', async ({ property, history, locale, token }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY}/edit/${property}`, { headers });

        dispatch(propertySlice.actions.dataSelectSubcategory({ subcategory: res.data.subcategories, options: res.data.options, readiness: res.data.readiness, legals: '', technicals: '', type: 0 }))
        dispatch(propertySlice.actions.dataSelectMunicipalities(res.data.municipalities))
        dispatch(propertySlice.actions.dataSelectAreas(res.data.areas))

        dispatch(propertySlice.actions.setScore(res.data.score))
        dispatch(propertySlice.actions.setReadiness(res.data.sales_readiness))
        dispatch(propertySlice.actions.setStage(res.data.stage))
        dispatch(propertySlice.actions.setSteps(res.data.steps))
        dispatch(propertySlice.actions.setDeleted(res.data.deleted))
        dispatch(propertySlice.actions.setImage(0))
        

        return res.data.data;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchProperties = createAsyncThunk('property/startFetchProperties', async ({ locale, token, filter, loading }, { rejectWithValue, dispatch }) => {
    try {
        dispatch(propertySlice.actions.actionFetchProperties(loading))
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY}?
        pageSize=${filter.pageSize}
        &currentPage=${filter.currentPage}
        &orderBy=${filter.orderBy}
        &search=${filter.search}
        &transaction_type=${filter.searchFilter.transaction_type.length > 0 ? filter.searchFilter.transaction_type.join() : ""}
        &exchange_type=${filter.searchFilter.exchange_type.length > 0 ? filter.searchFilter.exchange_type.join() : ""}
        &availability_type=${filter.searchFilter.availability_type.length > 0 ? filter.searchFilter.availability_type.join() : ""}
        &finality_type=${filter.searchFilter.finality_type.length > 0 ? filter.searchFilter.finality_type.join() : ""}
        &category_type=${filter.searchFilter.category_type.length > 0 ? filter.searchFilter.category_type.join() : ""}
        &subcategory_type=${filter.searchFilter.subcategory_type.length > 0 ? filter.searchFilter.subcategory_type.join() : ""}
        &luxury_type=${filter.searchFilter.luxury_type.length > 0 ? filter.searchFilter.luxury_type.join() : ""}
        &bedrooms_type=${filter.searchFilter.bedrooms_type.length > 0 ? filter.searchFilter.bedrooms_type.join() : ""}
        &condition_type=${filter.searchFilter.condition_type.length > 0 ? filter.searchFilter.condition_type.join() : ""}
        &prefecture_type=${filter.searchFilter.prefecture_type.length > 0 ? filter.searchFilter.prefecture_type.join() : ""}
        &municipality_type=${filter.searchFilter.municipality_type.length > 0 ? filter.searchFilter.municipality_type.join() : ""}
        &area_type=${filter.searchFilter.area_type.length > 0 ? filter.searchFilter.area_type.join() : ""}
        &view_type=${filter.searchFilter.view_type.length > 0 ? filter.searchFilter.view_type.join() : ""}
        &rating_type=${filter.searchFilter.rating_type.length > 0 ? filter.searchFilter.rating_type.join() : ""}
        &sea_type=${filter.searchFilter.sea_type.length > 0 ? filter.searchFilter.sea_type.join() : ""}
        &readiness_type=${filter.searchFilter.readiness_type.length > 0 ? filter.searchFilter.readiness_type.join() : ""}
        &assignment_type_type=${filter.searchFilter.assignment_type_type.length > 0 ? filter.searchFilter.assignment_type_type.join() : ""}
        &assignment_kind_type=${filter.searchFilter.assignment_kind_type.length > 0 ? filter.searchFilter.assignment_kind_type.join() : ""}
        &agent_type=${filter.searchFilter.agent_type.length > 0 ? filter.searchFilter.agent_type.join() : ""}
        &team_type=${filter.searchFilter.team_type.length > 0 ? filter.searchFilter.team_type.join() : ""}
        &active_type=${filter.searchFilter.active_type.length > 0 ? filter.searchFilter.active_type.join() : ""}
        &delivery_type=${filter.searchFilter.delivery_type.length > 0 ? filter.searchFilter.delivery_type.join() : ""}
        &road_type=${filter.searchFilter.road_type.length > 0 ? filter.searchFilter.road_type.join() : ""}
        &score_type=${filter.searchFilter.score_type.length > 0 ? filter.searchFilter.score_type.join() : ""}
        &secret_portfolio_type=${filter.searchFilter.secret_portfolio_type.length > 0 ? filter.searchFilter.secret_portfolio_type.join() : ""}
        &pool_type=${filter.searchFilter.pool_type.length > 0 ? filter.searchFilter.pool_type.join() : ""}
        &current_furnished_type=${filter.searchFilter.current_furnished_type.length > 0 ? filter.searchFilter.current_furnished_type.join() : ""}
        &delivery_furnished_type=${filter.searchFilter.delivery_furnished_type.length > 0 ? filter.searchFilter.delivery_furnished_type.join() : ""}
        &parking_type=${filter.searchFilter.parking_type.length > 0 ? filter.searchFilter.parking_type.join() : ""}
        &floor_type=${filter.searchFilter.floor_type.length > 0 ? filter.searchFilter.floor_type.join() : ""}
        &energy_class_type=${filter.searchFilter.energy_class_type.length > 0 ? filter.searchFilter.energy_class_type.join() : ""}
        &bathrooms_type=${filter.searchFilter.bathrooms_type.length > 0 ? filter.searchFilter.bathrooms_type.join() : ""}
        &wc_type=${filter.searchFilter.wc_type.length > 0 ? filter.searchFilter.wc_type.join() : ""}
        &ensuits_type=${filter.searchFilter.ensuits_type.length > 0 ? filter.searchFilter.ensuits_type.join() : ""}
        &heating_source_type=${filter.searchFilter.heating_source_type.length > 0 ? filter.searchFilter.heating_source_type.join() : ""}
        &heating_facility_type=${filter.searchFilter.heating_facility_type.length > 0 ? filter.searchFilter.heating_facility_type.join() : ""}
        &elevator_type=${filter.searchFilter.elevator_type.length > 0 ? filter.searchFilter.elevator_type.join() : ""}
        &levels_type=${filter.searchFilter.levels_type.length > 0 ? filter.searchFilter.levels_type.join() : ""}
        &plan_type=${filter.searchFilter.plan_type.length > 0 ? filter.searchFilter.plan_type.join() : ""}
        &complex_type=${filter.searchFilter.complex_type.length > 0 ? filter.searchFilter.complex_type.join() : ""}
        &dateCreate_type=${filter.searchFilter.dateCreate_type.length > 0 ? filter.searchFilter.dateCreate_type.join() : ""}
        &orientation_type=${filter.searchFilter.orientation_type.length > 0 ? filter.searchFilter.orientation_type.join() : ""}
        &buildable_type=${filter.searchFilter.buildable_type.length > 0 ? filter.searchFilter.buildable_type.join() : ""}
        &artio_type=${filter.searchFilter.artio_type.length > 0 ? filter.searchFilter.artio_type.join() : ""}
        &water_type=${filter.searchFilter.water_type.length > 0 ? filter.searchFilter.water_type.join() : ""}
        &electricity_type=${filter.searchFilter.electricity_type.length > 0 ? filter.searchFilter.electricity_type.join() : ""}
        &lastVisits_type=${filter.searchFilter.lastVisits_type.length > 0 ? filter.searchFilter.lastVisits_type.join() : ""}
        &lastActivities_type=${filter.searchFilter.lastActivities_type.length > 0 ? filter.searchFilter.lastActivities_type.join() : ""}
        &conversions_type=${filter.searchFilter.conversions_type.length > 0 ? filter.searchFilter.conversions_type.join() : ""}
        &synchronized_type=${filter.searchFilter.synchronized_type.length > 0 ? filter.searchFilter.synchronized_type.join() : ""}
        &fromPrice_type=${filter.searchFilter.fromPrice_type}
        &toPrice_type=${filter.searchFilter.toPrice_type}
        &fromSq_type=${filter.searchFilter.fromSq_type}
        &toSq_type=${filter.searchFilter.toSq_type}
        &fromLusage_type=${filter.searchFilter.fromLusage_type}
        &toLusage_type=${filter.searchFilter.toLusage_type}
        &fromConYear_type=${filter.searchFilter.fromConYear_type}
        &toConYear_type=${filter.searchFilter.toConYear_type}
        &fromRenYear_type=${filter.searchFilter.fromRenYear_type}
        &toRenYear_type=${filter.searchFilter.toRenYear_type}
        &sync_type=${filter.searchFilter.sync_type.length > 0 ? filter.searchFilter.sync_type.join() : ""}
        &fromLastOffer_type=${filter.searchFilter.fromLastOffer_type}
        &toLastOffer_type=${filter.searchFilter.toLastOffer_type}
        &fromLastVT_type=${filter.searchFilter.fromLastVT_type}
        &toLastVT_type=${filter.searchFilter.toLastVT_type}
        `, { headers })
        

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteProperty = createAsyncThunk('property/startDeleteProperty', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${PROPERTY}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCopyProperty = createAsyncThunk('property/startCopyProperty', async ({ locale, token, id, sale_or_rent, properties }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY}/copy/${id}/${sale_or_rent}`, { headers });

        let newProperties = JSON.parse(JSON.stringify(properties));

        newProperties.data.unshift(res.data.property[0]);
        newProperties.totalItemCount = newProperties.totalItemCount + 1;

        let newArray = {
          data: newProperties.data,
          totalItemCount: newProperties.totalItemCount
        }

        dispatch(propertySlice.actions.fetchPropertiesSuccess(newArray));

        return res.data.status;
    } catch (error) {
        console.log(error)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startPropertyInfo = createAsyncThunk('property/startPropertyInfo', async ({ property, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY}/info/${property}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startPropertyPhotos = createAsyncThunk('property/startPropertyPhotos', async ({ property, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY}/propertyPhotos/${property}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startPropertiesPoints = createAsyncThunk('property/startPropertiesPoints', async ({ selected, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY}/propertiesPoints`, { ids: selected }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startPropertiesClusterPoints = createAsyncThunk('property/startPropertiesClusterPoints', async ({ filter, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY}/propertiesClusterPoints?
        search=${filter.search}
        &transaction_type=${filter.searchFilter.transaction_type.length > 0 ? filter.searchFilter.transaction_type.join() : ""}
        &exchange_type=${filter.searchFilter.exchange_type.length > 0 ? filter.searchFilter.exchange_type.join() : ""}
        &availability_type=${filter.searchFilter.availability_type.length > 0 ? filter.searchFilter.availability_type.join() : ""}
        &finality_type=${filter.searchFilter.finality_type.length > 0 ? filter.searchFilter.finality_type.join() : ""}
        &category_type=${filter.searchFilter.category_type.length > 0 ? filter.searchFilter.category_type.join() : ""}
        &subcategory_type=${filter.searchFilter.subcategory_type.length > 0 ? filter.searchFilter.subcategory_type.join() : ""}
        &luxury_type=${filter.searchFilter.luxury_type.length > 0 ? filter.searchFilter.luxury_type.join() : ""}
        &bedrooms_type=${filter.searchFilter.bedrooms_type.length > 0 ? filter.searchFilter.bedrooms_type.join() : ""}
        &condition_type=${filter.searchFilter.condition_type.length > 0 ? filter.searchFilter.condition_type.join() : ""}
        &prefecture_type=${filter.searchFilter.prefecture_type.length > 0 ? filter.searchFilter.prefecture_type.join() : ""}
        &municipality_type=${filter.searchFilter.municipality_type.length > 0 ? filter.searchFilter.municipality_type.join() : ""}
        &area_type=${filter.searchFilter.area_type.length > 0 ? filter.searchFilter.area_type.join() : ""}
        &view_type=${filter.searchFilter.view_type.length > 0 ? filter.searchFilter.view_type.join() : ""}
        &rating_type=${filter.searchFilter.rating_type.length > 0 ? filter.searchFilter.rating_type.join() : ""}
        &sea_type=${filter.searchFilter.sea_type.length > 0 ? filter.searchFilter.sea_type.join() : ""}
        &readiness_type=${filter.searchFilter.readiness_type.length > 0 ? filter.searchFilter.readiness_type.join() : ""}
        &assignment_type_type=${filter.searchFilter.assignment_type_type.length > 0 ? filter.searchFilter.assignment_type_type.join() : ""}
        &assignment_kind_type=${filter.searchFilter.assignment_kind_type.length > 0 ? filter.searchFilter.assignment_kind_type.join() : ""}
        &agent_type=${filter.searchFilter.agent_type.length > 0 ? filter.searchFilter.agent_type.join() : ""}
        &team_type=${filter.searchFilter.team_type.length > 0 ? filter.searchFilter.team_type.join() : ""}
        &active_type=${filter.searchFilter.active_type.length > 0 ? filter.searchFilter.active_type.join() : ""}
        &delivery_type=${filter.searchFilter.delivery_type.length > 0 ? filter.searchFilter.delivery_type.join() : ""}
        &road_type=${filter.searchFilter.road_type.length > 0 ? filter.searchFilter.road_type.join() : ""}
        &score_type=${filter.searchFilter.score_type.length > 0 ? filter.searchFilter.score_type.join() : ""}
        &secret_portfolio_type=${filter.searchFilter.secret_portfolio_type.length > 0 ? filter.searchFilter.secret_portfolio_type.join() : ""}
        &pool_type=${filter.searchFilter.pool_type.length > 0 ? filter.searchFilter.pool_type.join() : ""}
        &current_furnished_type=${filter.searchFilter.current_furnished_type.length > 0 ? filter.searchFilter.current_furnished_type.join() : ""}
        &delivery_furnished_type=${filter.searchFilter.delivery_furnished_type.length > 0 ? filter.searchFilter.delivery_furnished_type.join() : ""}
        &parking_type=${filter.searchFilter.parking_type.length > 0 ? filter.searchFilter.parking_type.join() : ""}
        &floor_type=${filter.searchFilter.floor_type.length > 0 ? filter.searchFilter.floor_type.join() : ""}
        &energy_class_type=${filter.searchFilter.energy_class_type.length > 0 ? filter.searchFilter.energy_class_type.join() : ""}
        &bathrooms_type=${filter.searchFilter.bathrooms_type.length > 0 ? filter.searchFilter.bathrooms_type.join() : ""}
        &wc_type=${filter.searchFilter.wc_type.length > 0 ? filter.searchFilter.wc_type.join() : ""}
        &ensuits_type=${filter.searchFilter.ensuits_type.length > 0 ? filter.searchFilter.ensuits_type.join() : ""}
        &heating_source_type=${filter.searchFilter.heating_source_type.length > 0 ? filter.searchFilter.heating_source_type.join() : ""}
        &heating_facility_type=${filter.searchFilter.heating_facility_type.length > 0 ? filter.searchFilter.heating_facility_type.join() : ""}
        &elevator_type=${filter.searchFilter.elevator_type.length > 0 ? filter.searchFilter.elevator_type.join() : ""}
        &levels_type=${filter.searchFilter.levels_type.length > 0 ? filter.searchFilter.levels_type.join() : ""}
        &plan_type=${filter.searchFilter.plan_type.length > 0 ? filter.searchFilter.plan_type.join() : ""}
        &complex_type=${filter.searchFilter.complex_type.length > 0 ? filter.searchFilter.complex_type.join() : ""}
        &dateCreate_type=${filter.searchFilter.dateCreate_type.length > 0 ? filter.searchFilter.dateCreate_type.join() : ""}
        &orientation_type=${filter.searchFilter.orientation_type.length > 0 ? filter.searchFilter.orientation_type.join() : ""}
        &buildable_type=${filter.searchFilter.buildable_type.length > 0 ? filter.searchFilter.buildable_type.join() : ""}
        &artio_type=${filter.searchFilter.artio_type.length > 0 ? filter.searchFilter.artio_type.join() : ""}
        &water_type=${filter.searchFilter.water_type.length > 0 ? filter.searchFilter.water_type.join() : ""}
        &electricity_type=${filter.searchFilter.electricity_type.length > 0 ? filter.searchFilter.electricity_type.join() : ""}
        &lastVisits_type=${filter.searchFilter.lastVisits_type.length > 0 ? filter.searchFilter.lastVisits_type.join() : ""}
        &lastActivities_type=${filter.searchFilter.lastActivities_type.length > 0 ? filter.searchFilter.lastActivities_type.join() : ""}
        &conversions_type=${filter.searchFilter.conversions_type.length > 0 ? filter.searchFilter.conversions_type.join() : ""}
        &synchronized_type=${filter.searchFilter.synchronized_type.length > 0 ? filter.searchFilter.synchronized_type.join() : ""}
        &fromPrice_type=${filter.searchFilter.fromPrice_type}
        &toPrice_type=${filter.searchFilter.toPrice_type}
        &fromSq_type=${filter.searchFilter.fromSq_type}
        &toSq_type=${filter.searchFilter.toSq_type}
        &fromLusage_type=${filter.searchFilter.fromLusage_type}
        &toLusage_type=${filter.searchFilter.toLusage_type}
        &fromConYear_type=${filter.searchFilter.fromConYear_type}
        &toConYear_type=${filter.searchFilter.toConYear_type}
        &fromRenYear_type=${filter.searchFilter.fromRenYear_type}
        &toRenYear_type=${filter.searchFilter.toRenYear_type}
        &sync_type=${filter.searchFilter.sync_type.length > 0 ? filter.searchFilter.sync_type.join() : ""}
        &fromLastOffer_type=${filter.searchFilter.fromLastOffer_type}
        &toLastOffer_type=${filter.searchFilter.toLastOffer_type}
        &fromLastVT_type=${filter.searchFilter.fromLastVT_type}
        &toLastVT_type=${filter.searchFilter.toLastVT_type}
    `, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startPropertiesBucketPoints = createAsyncThunk('property/startPropertiesBucketPoints', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY}/propertiesBucketPoints`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('property/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSearchFields = createAsyncThunk('property/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSubcategory = createAsyncThunk('property/startFetchSubcategory', async ({ locale, token, ifEdit, category_id, transaction_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY}/selectSubcategory?category_id=${category_id}&transaction_id=${transaction_id}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchTransaction = createAsyncThunk('property/startFetchTransaction', async ({ locale, token, ifEdit, category_id, transaction_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY}/selectTransaction?category_id=${category_id}&transaction_id=${transaction_id}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchMunicipalities = createAsyncThunk('property/startFetchMunicipalities', async ({ locale, token, ifEdit, prefecture_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY}/getMunicipality?prefecture_id=${prefecture_id}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchAreas = createAsyncThunk('property/startFetchAreas', async ({ locale, token, ifEdit, prefecture_id, municipality_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY}/getArea?prefecture_id=${prefecture_id}&municipality_id=${municipality_id}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteOrderAttach = createAsyncThunk('property/startDeleteOrderAttach', async ({ locale, token, id, property_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${PROPERTY}/destroyFile/${property_id}/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const sendOffer = createAsyncThunk('property/sendOffer', async ({ formData, locale, token, form, toggle }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY}/sendOffer`, formData, { headers });

        if(res.data){
            toggle(true);
            form.resetForm();
            form.setStatus({ success: true });
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false });
        form.setSubmitting(false);
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const sendAssignment = createAsyncThunk('property/sendAssignment', async ({ formData, locale, token, form, toggle }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY}/sendAssignment`, formData, { headers });

        if(res.data){
            toggle(true);
            form.resetForm();
            form.setStatus({ success: true });
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false });
        form.setSubmitting(false);
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const sendConfirmation = createAsyncThunk('property/sendConfirmation', async ({ formData, locale, token, form, toggle }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY}/sendConfirmation`, formData, { headers });

        if(res.data){
            toggle(true);
            form.resetForm();
            form.setStatus({ success: true });
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false });
        form.setSubmitting(false);
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchAssignment = createAsyncThunk('property/startFetchAssignment', async ({ properties, locale, token, toggle }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY}/fetchAssignmentData`, {props: properties}, { headers });

        return res.data;
    } catch (error) {
        toggle(true);
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchConfirmation = createAsyncThunk('property/startFetchConfirmation', async ({ property_id, locale, token, toggle }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY}/fetchConfirmationData`, {property_id: property_id}, { headers });

        return res.data;
    } catch (error) {
        toggle(true);
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startGenerateDesc = createAsyncThunk('property/startGenerateDesc', async ({ locale, token, property_id, descs, openai, type }, { rejectWithValue, dispatch }) => {
    try {
        dispatch(propertySlice.actions.descsPending(type));

        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY}/generateDescriptions?property_id=${property_id}&type=${type}`, { headers });

        const descEN = await openai.createChatCompletion({
            model: "gpt-3.5-turbo",
            messages: [{role: "user", content: res.data.description}]
        });

        return { status: res.data.status, description: descEN.data.choices[0].message.content, type: type, descs: descs };
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const exportPdf = createAsyncThunk('property/exportPdf', async ({ formData, locale, token, form, toggle }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY}/exportPdf`, formData, { headers });

        if(res.data){
            let pdfWindow = window.open("Properties", "_blank", "toolbar=1, scrollbars=1, resizable=1, width=" + 600 + ", height=" + 800);
            pdfWindow.document.write("<html<head><title>REWIZE</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
            pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(res.data)+"#toolbar=1&navpanes=0&scrollbar=0'></embed></body></html>");

            toggle(true);
            form.resetForm();
            form.setStatus({ success: true });
        }

        return '';
    } catch (error) {
        form.setStatus({ success: false });
        form.setSubmitting(false);
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const propertyStats = createAsyncThunk('property/propertyStats', async ({ property_id, search_id, from_date, to_date, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY}/stats?property_id=${property_id}&search_id=${search_id}&from=${from_date}&to=${to_date}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateContactAssignment = createAsyncThunk('property/startUpdateContactAssignment', async ({ contact_id, name, surname, email, fathers_name, address, postal, phone, mobile, passport, vat, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY}/updateContactAssignment`, {
            contact_id, 
            name, 
            surname, 
            email, 
            fathers_name, 
            address,
            postal, 
            phone, 
            mobile,
            passport,
            vat
        }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startOfferRequest = createAsyncThunk('property/startOfferRequest', async ({ locale, token, reqs, property_id, toggleModal }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY}/offerRequests`, {reqs: reqs, property_id: property_id}, { headers });

        if(res.data){
            toggleModal();
        }

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchReqMatching = createAsyncThunk('property/startFetchReqMatching', async ({ id, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY}/fetchReqMatching/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startAssignUser = createAsyncThunk('property/startAssignUser', async ({ locale, token, user, selected, toggle }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY}/assignUser`, {user, selected}, { headers });

        if(res.data){
            toggle()
        }

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startAvailability = createAsyncThunk('property/startAvailability', async ({ locale, token, availability, finality, selected, toggle }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY}/availability`, {availability, finality, selected}, { headers });

        if(res.data){
            toggle()
        }

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


//Heading Functions
const setSelectedOrderOption = createAsyncThunk('contact/setSelectedOrderOption', ( args ) => {
    return args;
});

const setSearch = createAsyncThunk('contact/setSearch', ( args ) => {
    return args;
});

const setCurrentPage = createAsyncThunk('contact/setCurrentPage', ( args ) => {
    return args;
});


const initialState = {
    properties: {
        data: [],
        totalItemCount: 0
    },
    displayMode: "list",
    selectedPageSize: 10,
    selectedOrderOption: "",
    search: "",
    searchValue: "",
    searchFilterType: 1,
    fields: [],
    searchFilter: [
        { translate: "property.property-transaction-types", id: "transaction_type", dataKey: "transaction_types", search: "", type: 1 },
        { translate: "general.exchange", id: "exchange_type", dataKey: "exchange_types", search: "", type: 1 },
        { translate: "general.availability", id: "availability_type", dataKey: "availability_types", search: "", type: 1 },
        { translate: "property.property-category", id: "category_type", dataKey: "category_types", search: "", type: 1 },
        { translate: "property.property-subcategory", id: "subcategory_type", dataKey: "subcategory_types", search: "", type: 1 },
        { translate: "property.luxury-property", id: "luxury_type", dataKey: "luxury_types", search: "", type: 1 },
        { translate: "property.property-bedrooms", id: "bedrooms_type", dataKey: "bedrooms_types", search: "", type: 1 },
        { translate: "general.condition", id: "condition_type", dataKey: "condition_types", search: "", type: 1 },
        { translate: "general.delivery_status", id: "delivery_type", dataKey: "delivery_types", search: "", type: 1 },
        { translate: "general.prefectures", id: "prefecture_type", dataKey: "prefecture_types", search: "", type: 1 },
        { translate: "general.municipalities", id: "municipality_type", dataKey: "municipality_types", search: "", type: 1 },
        { translate: "general.areas", id: "area_type", dataKey: "area_types", search: "", type: 1 },
        { translate: "general.public-price", id: "price_type", dataKey: "", search: "", type: 1 },
        { translate: "property.property-sq-meters", id: "sq_type", dataKey: "", search: "", type: 1 },
        { translate: "general.view", id: "view_type", dataKey: "view_types", search: "", type: 1 },
        { translate: "general.rate", id: "rating_type", dataKey: "rating_types", search: "", type: 1 },
        { translate: "general.energy-class", id: "energy_class_type", dataKey: "energy_class_types", search: "", type: 1 },
        { translate: "property.property-sea-accessibility", id: "sea_type", dataKey: "sea_types", search: "", type: 1 },
        { translate: "menu.readiness-score", id: "readiness_type", dataKey: "readiness_types", search: "", type: 1 },
        { translate: "general.assignment-type", id: "assignment_type_type", dataKey: "assignment_type_types", search: "", type: 1 },
        { translate: "general.assignment-kind", id: "assignment_kind_type", dataKey: "assignment_kind_types", search: "", type: 1 },
        { translate: "general.agents", id: "agent_type", dataKey: "agent_types", search: "", type: 1 },
        { translate: "general.teams", id: "team_type", dataKey: "team_types", search: "", type: 1 },

        /* Advance Filter */
        { translate: "general.active", id: "active_type", dataKey: "active_types", search: "", type: 2 },
        { translate: "property.property-construction_year", id: "conYear_type", dataKey: "", search: "", type: 2 },
        { translate: "property.property-renovated_year", id: "renYear_type", dataKey: "", search: "", type: 2 },
        { translate: "property.property-road-accessibility", id: "road_type", dataKey: "road_types", search: "", type: 2 },
        { translate: "general.health-score", id: "score_type", dataKey: "score_types", search: "", type: 2 },
        { translate: "general.secret-portfolio", id: "secret_portfolio_type", dataKey: "secret_portfolio_types", search: "", type: 2 },
        { translate: "general.pool", id: "pool_type", dataKey: "pool_types", search: "", type: 2 },
        { translate: "property.current-furnished", id: "current_furnished_type", dataKey: "current_furnished_types", search: "", type: 2 },
        { translate: "property.delivery-furnished", id: "delivery_furnished_type", dataKey: "delivery_furnished_types", search: "", type: 2 },
        { translate: "property.property-parking", id: "parking_type", dataKey: "parking_types", search: "", type: 2 },
        { translate: "property.property-land-usage", id: "lusage_type", dataKey: "", search: "", type: 2 },
        { translate: "property.property-floor", id: "floor_type", dataKey: "floor_types", search: "", type: 2 },
        { translate: "property.property-bathrooms", id: "bathrooms_type", dataKey: "bathrooms_types", search: "", type: 2 },
        { translate: "property.property-wc", id: "wc_type", dataKey: "wc_types", search: "", type: 2 },
        { translate: "property.property-en_suite_bathrooms", id: "ensuits_type", dataKey: "ensuits_types", search: "", type: 2 },
        { translate: "property.property-heating-source", id: "heating_source_type", dataKey: "heating_source_types", search: "", type: 2 },
        { translate: "property.property-heating-facility", id: "heating_facility_type", dataKey: "heating_facility_types", search: "", type: 2 },
        { translate: "property.property-elevator", id: "elevator_type", dataKey: "elevator_types", search: "", type: 2 },
        { translate: "property.property-level", id: "levels_type", dataKey: "levels_types", search: "", type: 2 },
        { translate: "property.property-plan", id: "plan_type", dataKey: "plan_types", search: "", type: 2 },
        { translate: "property.property-complex", id: "complex_type", dataKey: "complex_types", search: "", type: 2 },
        { translate: "general.create-date", id: "dateCreate_type", dataKey: "dateCreate_types", search: "", type: 2 },
        { translate: "general.orientation", id: "orientation_type", dataKey: "orientation_types", search: "", type: 2 },
        { translate: "property.property-buildable", id: "buildable_type", dataKey: "buildable_types", search: "", type: 2 },
        { translate: "property.property-artio", id: "artio_type", dataKey: "artio_types", search: "", type: 2 },
        { translate: "property.property-water", id: "water_type", dataKey: "water_types", search: "", type: 2 },
        { translate: "property.property-electricity", id: "electricity_type", dataKey: "electricity_types", search: "", type: 2 },
        { translate: "property.finality", id: "finality_type", dataKey: "finality_types", search: "", type: 2 },

        /* Advance Filter */

        /* Smart Filter */
        // { translate: "general.last-visit", id: "lastVisits_type", dataKey: "lastVisits_types", search: "", type: 3 },
        // { translate: "general.last-activity", id: "lastActivities_type", dataKey: "lastActivities_types", search: "", type: 3 },
        { translate: "general.portals-accounts", id: "sync_type", dataKey: "sync_types", search: "", type: 3 },
        { translate: "general.last-offer", id: "lastOffers_type", dataKey: "", search: "", type: 3 },
        { translate: "general.last-vt", id: "lastVTs_type", dataKey: "", search: "", type: 3 },
        { translate: "general.synchronized", id: "synchronized_type", dataKey: "synchronized_types", search: "", type: 3 },
        // { translate: "general.any-conversions", id: "conversions_type", dataKey: "conversions_types", search: "", type: 3 },

        /* Smart Filter */

    ],
    searchFilterValues: {
        transaction_type: [],
        exchange_type: [],
        availability_type: [],
        category_type: [],
        subcategory_type: [],
        luxury_type: [],
        bedrooms_type: [],
        condition_type: [],
        delivery_type: [],
        prefecture_type: [],
        municipality_type: [],
        area_type: [],
        view_type: [],
        rating_type: [],
        energy_class_type: [],
        sea_type: [],
        readiness_type: [],
        assignment_type_type: [],
        assignment_kind_type: [],
        agent_type: [],
        team_type: [],

        fromPrice_type: "",
        toPrice_type: "",
        fromSq_type: "",
        toSq_type: "",

        /* Advance Filter */
        active_type: [],
        road_type: [],
        score_type: [],
        secret_portfolio_type: [],
        pool_type: [],
        current_furnished_type: [],
        delivery_furnished_type: [],
        parking_type: [],
        floor_type: [],
        bathrooms_type: [],
        wc_type: [],
        ensuits_type: [],
        heating_source_type: [],
        heating_facility_type: [],
        elevator_type: [],
        levels_type: [],
        plan_type: [],
        complex_type: [],
        orientation_type: [],
        dateCreate_type: [],
        buildable_type: [],
        artio_type: [],
        water_type: [],
        electricity_type: [],
        finality_type: [],

        fromLusage_type: "",
        toLusage_type: "",
        fromConYear_type: "",
        toConYear_type: "",
        fromRenYear_type: "",
        toRenYear_type: "",
        /* Advance Filter */

        /* Smart Filter */
        sync_type: [],
        lastVisits_type: [],
        lastActivities_type: [],
        conversions_type: [],
        synchronized_type: [],

        fromLastOffer_type: "",
        toLastOffer_type: "",

        fromLastVT_type: "",
        toLastVT_type: "",
        /* Smart Filter */
    },
    currentPage: 1,

    bottomNavHidden: false,
    topNavDisabled: false,
    property_id: '',
    image: 0,
    score: 0,
    sales_readiness: 0,
    stage: "",
    editAction: 0,
    steps_data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    deleted: 0,
    propertyData: [{
        bucket_id: "",
        category_id: '',
        subcategory_id: '',
        sale_or_rent: 1,
        exchange: '',
        lux_prop: null,
        agent_id: '',
        finality_type_id: null,
        availability: 1,
        contacts: [{ id: '', contact_id: '', kinship_id: '', rate: 100, is_master: 1, contacts: [], loading: false }],
        active: 1
    },
    {
        legals: []
    },
    {
        technicals: []
    },
    {
        sq_meters: '',
        condition_type_id: '',
        condition_desc: '',
        delivery_type_id: '',
        bedrooms: '',
        bathrooms: '',
        wc: '',
        en_suite_bathrooms: '',
        construction_year: null,
        storage: '',
        floor: '',
        energy_class: '',
        elevator: '',
        renovated_year: null,
        renovation_type_id: null,
        sewage_type_id: '',
        pools: [],
        views: [{ id: '', view_type_id: '', view_rate_type_id: '' }],
        floor_materials: [],
        frames: [],
        level: '',
        total_building_levels: '',
        garden: '',
        suitable_for: [],
        office_spaces: '',
        parking_spots: '',
        hotel_pools: '',
        spaces: [],
        extras: [],
        heating_source_type_id: '',
        heating_facility_type_id: '',
        current_furnished: '',
        delivery_furnished: '',
        other_equipment: '',
        other_equipment_text: '',
        complex_id: '',
        complexes: { id: '', complexes: [], loading: false },
        building: '',
        building_rate: 0,
        coverage: '',
        coverage_rate: 0,
        plan_type_id: '',
        parking: '',
        garage: '',
        propContacts: [],
        artio: '',
        buildable_type_id: '',
        electricity: '',
        electricity_text: '',
        water: '',
        water_text: '',
        drilling: '',
        building_type_id: '',
        coverage_type_id: '',
        name: '',
        stars: '',
        web_site: '',
        beds: '',
        rooms: '',
        sgl: 0,
        dbl: 0,
        trp: 0,
        quad: 0,
        suite: 0,
        studios: 0,
        showcase_length: ''
    },
    {
        prefecture_id: '',
        municipality_id: '',
        area_id: '',
        address: '',
        location_show: '',
        land_usage: '',
        geometric_location: [],
        road_accessibility_type_id: '',
        sea_accessibility_type_id: '',
        orientation_type_id: '',
        iso: 'gr',
        lat: '',
        lng: '',
        zoom: '',
        distances: [],
        extra_distances: []
    },
    {
        short_desc_en: '',
        short_desc_el: '',
        desc_en: '',
        desc_el: ''
    },
    {
        ground_file: '',
        aerial_file: '',
        mixed_file: '',
        photos: []
    },
    {
        price: '',
        public_price: '',
        price_includes: [],
        upon_request: 0,
        legal_person: 0,
        transfer_tax: '',
        secret_portfolio: 0,
        obj_value: '',
        available_from: null,
        vat: 3.1,
        agreed_fee: '',
        agreed_fee_desc: '',
        assignment_type_id: '',
        monthly_shared_costs: '',
        assignment_kind_id: '',
        order_duration_from: null,
        order_duration_to: null,

        auction_id: '',
        auction_url: '',
        auction_date: null,

        auction_info: {},
        auction_cycles: [],
        files: [],
        price_history: [],
        public_price_history: []
    },
    {
        syncs: []
    }],
    dataFields: {
        categories: [],
        subcategories: [],
        portals: [],
        agents: [],
        finalities: [],
        kinships: [],
        legals: [],
        technicals: [],
        conditions: [],
        renovations: [],
        drainages: [],
        pools: [],
        views: [],
        gardens: [],
        suitables: [],
        viewsRates: [],
        floor_materials: [],
        energy_class: [],
        frames: [],
        extras: [],
        heating_sources: [],
        heating_facilities: [],
        complexes: [],
        buildables: [],
        plans: [],
        propContacts: [],
        prefectures: [],
        roadAccessibilities: [],
        seaAccessibilities: [],
        orientations: [],
        maps: [],
        extra_maps: [],
        photo_tags: [],
        assignments_type: [],
        assignments_kind: [],
        investigation_motivations: [],
        liquidate_priorities: [],
        users: [],
        stages_types: [],
        syncs: [],
        price_includes: []
    },
    municipalities: [],
    areas: [],
    subcategory: [],
    options: [],
    readiness: [],
    saveProperty: '',
    deleteProperty: '',
    copyProperty: '',
    propertyInfo: '',
    propertyOffer: '',
    propertyAssignment: '',
    updateContactAssignment: '',
    exportPdf: '',
    propertyConfirmation: '',
    generateDescProperty: '',
    assignUser: '', 
    availability: '',
    propertyPhotos: {
        large: [],
        thump: []
    },
    propertiesOfferData: {
        contacts: [],
        contact_id: '',
        language: '',
        subject: '',
        message: ''
    },
    propertyAssignmentData: {
        properties: [],
        contact_id: '',
        name: '',
        surname: '',
        email: '',
        address: '',
        vat: '',
        passport: '',
        phone: '',
        mobile: '',
        postal: '',
        language: '',  
        assignment: ''
    },
    propertyConfirmationData: {  
        property_id: '',
        contact_id: '',
        name: '',
        surname: '',
        email: '',
        language: ''
    },
    propertiesStatsData: {
        viewing_trips: 0,
        offers_sent: 0,
        property_requests: 0,
        page_views_number: 0,
        countries_views: {
            labels: [],
            data: [],
            border: [],
            background: []
        },
        page_views_chart: {
            labels: [],
            data: []
        },
        client_details: [],
    },
    propertiesClusterPoints: [],
    bucketPoints: [],
    propertiesPoints: [],
    deleteOrderAttach: '',
    reqMatching: [],
    offerRequest: '',
    loadingBtnContactAssignment: false,
    loadingDescBtn: false,
    loadingDescShortBtn: false,
    loadingBtn: false,
    loading: true,
    loadingModal: false,
    loadingSearch: false,
    error: ''
}

export const propertySlice = createSlice({  
    name: 'property',
    initialState,
    reducers: {
        fetchPropertiesSuccess(state, { payload }) {
            return { ...state, loading: false, loadingSearch: false, properties: payload, error: '' };
        },
        fetchPropertySuccess(state, { payload }) {
            let editData = JSON.parse(JSON.stringify(state.propertyData));

            editData[0] = payload[0];
            editData[1] = Object.keys(payload[1]).length > 0 ? payload[1] : editData[1];
            editData[2] = Object.keys(payload[2]).length > 0 ? payload[2] : editData[2];

            if (Object.keys(payload[3]).length > 0) {
                editData[3] = JSON.parse(JSON.stringify(payload[3]));
                editData[3].construction_year = editData[3].construction_year ? new Date(editData[3].construction_year) : null;
            }

            editData[4] = Object.keys(payload[4]).length > 0 ? payload[4] : editData[4];
            editData[5] = Object.keys(payload[5]).length > 0 ? payload[5] : editData[5];
            editData[6] = Object.keys(payload[6]).length > 0 ? payload[6] : editData[6];
            editData[7] = Object.keys(payload[7]).length > 0 ? payload[7] : editData[7];
            editData[8] = Object.keys(payload[8]).length > 0 ? payload[8] : editData[8];

            return { ...state, loading: false, propertyData: editData, error: '' };
        },
        actionFetchProperties(state, { payload }) {
            return { ...state, loading: payload ? true : false, loadingSearch: true, error: '' };
        },
        clearProperty(state) {
            return { ...state, loadingBtnContactAssignment: false, loadingDescBtn: false, loadingDescShortBtn: false, loadingBtn: false, loading: false, loadingSearch: false, propertyData: initialState.propertyData, image: 0, score: 0, sales_readiness: 0, stage: "", steps_data: initialState.steps_data, error: '' };
        },
        clearPropertyOffer(state) {
            return { ...state, loadingBtn: false, propertiesOfferData: initialState.propertiesOfferData, propertyOffer: '', error: '' };
        },
        clearPropertyAssignment(state) {
            return { ...state, loadingBtn: false, propertyAssignmentData: initialState.propertyAssignmentData, propertyAssignment: '', error: '' };
        },
        clearPropertyConfirmation(state) {
            return { ...state, loadingBtn: false, propertyConfirmationData: initialState.propertyConfirmationData, propertyConfirmation: '', error: '' };
        },
        clearPropertyStats(state) {
            return { ...state, loadingModal: false, propertiesStatsData: initialState.propertiesStatsData, error: ''};
        },
        clearAlertProperty(state) {
            return { ...state, saveProperty: '', deleteProperty: '', copyProperty: '', deleteOrderAttach: '', propertyOffer: '', propertyAssignment: '', updateContactAssignment: '', exportPdf: '', propertyConfirmation: '', generateDescProperty: '', assignUser: '', availability: '', error: '' };
        },
        setDisplayMode(state, { payload }) {
            return { ...state, displayMode: payload };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 };
        },
        setFilter(state, { payload }) {
            return { ...state, searchFilterValues: payload };
        },
        setFilterType(state, { payload }) {
            return { ...state, searchFilterType: payload };
        },
        setFilterFieldSearch(state, { payload }) {
            return { ...state, fields: payload.dataValues, searchFilter: payload.values };
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },

        setBottomNavHidden(state, { payload }) {
            return { ...state, bottomNavHidden: payload };
        },
        setTopNavDisable(state, { payload }) {
            return { ...state, topNavDisabled: payload };
        },
        setEditAction(state, { payload }) {
            return { ...state, property_id: payload.property_id ? payload.property_id : "", editAction: payload.editAction };
        },
        setScore(state, { payload }) {
            return { ...state, score: payload };
        },
        setReadiness(state, { payload }) {
            return { ...state, sales_readiness: payload };
        },
        setStage(state, { payload }) {
            return { ...state, stage: payload };
        },
        setSteps(state, { payload }) {
            return { ...state, steps_data: payload };
        },
        setDeleted(state, { payload }) {
            return { ...state, deleted: payload };
        },
        setImage(state, { payload }) {
            return { ...state, image: payload };
        },

        dataSelectAreas(state, { payload }) {
            return { ...state, areas: payload, error: '' };
        },
        dataSelectSubcategory(state, { payload }) {
            let returnData = { ...state, subcategory: payload.subcategory, options: payload.options, readiness: payload.readiness, error: '' };

            if (payload.type) {
                let allData = JSON.parse(JSON.stringify(state.propertyData));

                allData[1] = { legals: payload.legals };
                allData[2] = { technicals: payload.technicals };

                returnData.propertyData = allData;
            }

            return returnData;
        },
        dataSelectMunicipalities(state, { payload }) {
            return { ...state, municipalities: payload, error: '' };
        },
        propertiesBucketPointsRemove(state) {
            return { ...state, loadingBtn: false, bucketPoints: [], error: '' };
        },
        descsPending(state, { payload }) {
            return { ...state, loadingDescShortBtn: payload === 1 ? true : false, loadingDescBtn: payload === 2 ? true : false, error: '' };
        },
        setReqMatching(state, { payload }) {
            return { ...state, reqMatching: payload };
        },
    },
    extraReducers: {
        [startSaveProperty.pending]: (state) => {
            return { ...state, loadingBtn: true, error: '' };
        },
        [startSaveProperty.fulfilled]: (state, { payload }) => {
            return { ...state, loadingBtn: false, saveProperty: payload, error: '' };
        },
        [startSaveProperty.rejected]: (state, { payload }) => {
            return { ...state, loadingBtn: false, saveProperty: '', error: payload };
        },

        [startFetchProperty.pending]: (state) => {
            return { ...state, loading: true, error: '' };
        },
        [startFetchProperty.fulfilled]: (state, { payload }) => {
            let editData = JSON.parse(JSON.stringify(state.propertyData));

            editData[0] = payload[0];
            editData[1] = Object.keys(payload[1]).length > 0 ? payload[1] : initialState.propertyData[1];
            editData[2] = Object.keys(payload[2]).length > 0 ? payload[2] : initialState.propertyData[2];

            if (Object.keys(payload[3]).length > 0) {
                editData[3] = JSON.parse(JSON.stringify(payload[3]));
                editData[3].construction_year = editData[3].construction_year ? new Date(editData[3].construction_year) : null;
            }

            editData[4] = Object.keys(payload[4]).length > 0 ? payload[4] : initialState.propertyData[4];
            editData[5] = Object.keys(payload[5]).length > 0 ? payload[5] : initialState.propertyData[5];
            editData[6] = Object.keys(payload[6]).length > 0 ? payload[6] : initialState.propertyData[6];
            editData[7] = Object.keys(payload[7]).length > 0 ? payload[7] : initialState.propertyData[7];
            editData[8] = Object.keys(payload[8]).length > 0 ? payload[8] : initialState.propertyData[8];

            return { ...state, loading: false, propertyData: editData, error: '' };
        },
        [startFetchProperty.rejected]: (state, { payload }) => {
            return { ...state, loading: false, propertyData: initialState.propertyData, error: payload };
        },

        
        [startFetchProperties.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false, loadingSearch: false, properties: payload, error: '' };
        },
        [startFetchProperties.rejected]: (state, { payload }) => {
            return { ...state, loading: false, loadingSearch: false, properties: initialState.properties, error: payload };
        },

        [startDeleteProperty.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false, deleteProperty: payload, error: '' };
        },
        [startDeleteProperty.rejected]: (state, { payload }) => {
            return { ...state, loading: false, deleteProperty: '', error: payload };
        },

        [startCopyProperty.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false, copyProperty: payload, error: '' };
        },
        [startCopyProperty.rejected]: (state, { payload }) => {
            return { ...state, loading: false, copyProperty: '', error: payload };
        },

        [startPropertyInfo.pending]: (state) => {
            return { ...state, loadingModal: true, error: '' };
        },
        [startPropertyInfo.fulfilled]: (state, { payload }) => {
            return { ...state, loadingModal: false, propertyInfo: payload, error: '' };
        },
        [startPropertyInfo.rejected]: (state, { payload }) => {
            return { ...state, loadingModal: false, propertyInfo: '', error: payload };
        },

        [startPropertyPhotos.pending]: (state) => {
            return { ...state, loadingModal: true, error: '' };
        },
        [startPropertyPhotos.fulfilled]: (state, { payload }) => {
            return { ...state, loadingModal: false, propertyPhotos: payload, error: '' };
        },
        [startPropertyPhotos.rejected]: (state, { payload }) => {
            return { ...state, loadingModal: false, propertyPhotos: '', error: payload };
        },

        [startPropertiesPoints.pending]: (state) => {
            return { ...state, loadingModal: true, error: '' };
        },
        [startPropertiesPoints.fulfilled]: (state, { payload }) => {
            return { ...state, loadingModal: false, propertiesPoints: payload, error: '' };
        },
        [startPropertiesPoints.rejected]: (state, { payload }) => {
            return { ...state, loadingModal: false, propertiesPoints: [], bucketPoints: [], error: payload };
        },

        [startPropertiesClusterPoints.pending]: (state) => {
            return { ...state, loadingModal: true, error: '' };
        },
        [startPropertiesClusterPoints.fulfilled]: (state, { payload }) => {
            return { ...state, loadingModal: false, propertiesClusterPoints: payload, bucketPoints: [], error: '' };
        },
        [startPropertiesClusterPoints.rejected]: (state, { payload }) => {
            return { ...state, loadingModal: false, propertiesClusterPoints: [], bucketPoints: [], error: payload };
        },

        [startPropertiesBucketPoints.pending]: (state) => {
            return { ...state, loadingBtn: true, error: '' };
        },
        [startPropertiesBucketPoints.fulfilled]: (state, { payload }) => {
            return { ...state, loadingBtn: false, bucketPoints: payload, error: '' };
        },
        [startPropertiesBucketPoints.rejected]: (state, { payload }) => {
            return { ...state, loadingBtn: false, bucketPoints: [], error: payload };
        },

        [startFetchDataFields.pending]: (state) => {
            return { ...state, loading: true, error: '' };
        },
        [startFetchDataFields.fulfilled]: (state, { payload }) => {
            return { ...state, dataFields: payload, error: '' };
        },
        [startFetchDataFields.rejected]: (state, { payload }) => {
            return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
        },

        [startFetchSearchFields.pending]: (state) => {
            return { ...state, loading: true, error: '' };
        },
        [startFetchSearchFields.fulfilled]: (state, { payload }) => {
            return { ...state, fields: payload, error: '' };
        },
        [startFetchSearchFields.rejected]: (state, { payload }) => {
            return { ...state, loading: false, fields: initialState.fields, error: payload };
        },

        [startFetchSubcategory.fulfilled]: (state, { payload }) => {
            let returnData = { ...state, subcategory: payload.subcategories, options: payload.options, readiness: payload.readiness, error: '' };
            let allData = JSON.parse(JSON.stringify(state.propertyData));

            allData[1] = { legals: payload.legals };
            allData[2] = { technicals: payload.technicals };

            returnData.propertyData = allData;

            return returnData;
        },

        [startFetchSubcategory.rejected]: (state, { payload }) => {
            return { ...state, subcategory: initialState.subcategory, options: initialState.options, readiness: initialState.readiness, propertyData: initialState.propertyData, error: payload };
        },

        [startFetchTransaction.fulfilled]: (state, { payload }) => {
            let returnDataTrans = { ...state, error: '' };
            let allDataTrans = JSON.parse(JSON.stringify(state.propertyData));

            allDataTrans[1] = { legals: payload.legals };
            allDataTrans[2] = { technicals: payload.technicals };

            returnDataTrans.propertyData = allDataTrans;

            return returnDataTrans;
        },

        [startFetchTransaction.rejected]: (state, { payload }) => {
            return { ...state, propertyData: initialState.propertyData, error: payload };
        },


        [startFetchMunicipalities.fulfilled]: (state, { payload }) => {
            return { ...state, municipalities: payload, error: '' };
        },
        [startFetchMunicipalities.rejected]: (state, { payload }) => {
            return { ...state, municipalities: initialState.municipalities, error: payload };
        },

        [startFetchAreas.fulfilled]: (state, { payload }) => {
            return { ...state, areas: payload, error: '' };
        },
        [startFetchAreas.rejected]: (state, { payload }) => {
            return { ...state, areas: initialState.areas, error: payload };
        },

        [startDeleteOrderAttach.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false, deleteOrderAttach: payload, error: '' };
        },
        [startDeleteOrderAttach.rejected]: (state, { payload }) => {
            return { ...state, loading: false, deleteOrderAttach: '', error: payload };
        },

        [sendOffer.pending]: (state) => {
            return { ...state, loadingBtn: true, error: '' };
        },
        [sendOffer.fulfilled]: (state, { payload }) => {
            return { ...state, loadingBtn: false, propertyOffer: payload, error: '' };
        },
        [sendOffer.rejected]: (state, { payload }) => {
            return { ...state, loadingBtn: false, propertyOffer: '', error: payload };
        },

        [sendAssignment.pending]: (state) => {
            return { ...state, loadingBtn: true, error: '' };
        },
        [sendAssignment.fulfilled]: (state, { payload }) => {
            return { ...state, loadingBtn: false, propertyAssignment: payload, error: '' };
        },
        [sendAssignment.rejected]: (state, { payload }) => {
            return { ...state, loadingBtn: false, propertyAssignment: '', error: payload };
        },

        [startUpdateContactAssignment.pending]: (state) => {
            return { ...state, loadingBtnContactAssignment: true, error: '' };
        },
        [startUpdateContactAssignment.fulfilled]: (state, { payload }) => {
            return { ...state, loadingBtnContactAssignment: false, updateContactAssignment: payload, error: '' };
        },
        [startUpdateContactAssignment.rejected]: (state, { payload }) => {
            return { ...state, loadingBtnContactAssignment: false, updateContactAssignment: '', error: payload };
        },

        [sendConfirmation.pending]: (state) => {
            return { ...state, loadingBtn: true, error: '' };
        },
        [sendConfirmation.fulfilled]: (state, { payload }) => {
            return { ...state, loadingBtn: false, propertyConfirmation: payload, error: '' };
        },
        [sendConfirmation.rejected]: (state, { payload }) => {
            return { ...state, loadingBtn: false, propertyConfirmation: '', error: payload };
        },

        [startFetchAssignment.pending]: (state) => {
            return { ...state, loadingModal: true, error: '' };
        },
        [startFetchAssignment.fulfilled]: (state, { payload }) => {
            return { ...state, loadingModal: false, propertyAssignmentData: payload, error: '' };
        },
        [startFetchAssignment.rejected]: (state, { payload }) => {
            return { ...state, loadingModal: false, propertyAssignmentData: initialState.propertyAssignmentData, error: payload };
        },

        [startFetchConfirmation.pending]: (state) => {
            return { ...state, loadingModal: true, error: '' };
        },
        [startFetchConfirmation.fulfilled]: (state, { payload }) => {
            return { ...state, loadingModal: false, propertyConfirmationData: payload, error: '' };
        },
        [startFetchConfirmation.rejected]: (state, { payload }) => {
            return { ...state, loadingModal: false, propertyConfirmationData: initialState.propertyConfirmationData, error: payload };
        },

        [startGenerateDesc.fulfilled]: (state, { payload }) => {
            let editDescs = JSON.parse(JSON.stringify(state.propertyData));

            if(payload.type === 1){
                editDescs[5].short_desc_en = payload.description;
                editDescs[5].desc_en = payload.descs.desc_en;
            }else{
                editDescs[5].short_desc_en = payload.descs.short_desc_en;
                editDescs[5].desc_en = payload.description;
            }

            editDescs[5].short_desc_el = payload.descs.short_desc_el;
            editDescs[5].desc_el = payload.descs.desc_el;
            

            return { ...state, loadingDescBtn: false, loadingDescShortBtn: false, generateDescProperty: payload.status, propertyData: editDescs, error: '' };
        },
        [startGenerateDesc.rejected]: (state, { payload }) => {
            return { ...state, loadingDescBtn: false, loadingDescShortBtn: false, generateDescProperty: '', error: payload };
        },

        [exportPdf.pending]: (state) => {
            return { ...state, loadingBtn: true, error: '' };
        },
        [exportPdf.fulfilled]: (state, { payload }) => {
            return { ...state, loadingBtn: false, exportPdf: payload, error: '' };
        },
        [exportPdf.rejected]: (state, { payload }) => {
            return { ...state, loadingBtn: false, exportPdf: '', error: payload };
        },

        [propertyStats.pending]: (state) => {
            return { ...state, loadingModal: true, error: '' };
        },
        [propertyStats.fulfilled]: (state, { payload }) => {
            return { ...state, loadingModal: false, propertiesStatsData: payload, error: '' };
        },
        [propertyStats.rejected]: (state, { payload }) => {
            return { ...state, loadingModal: false, propertiesStatsData: initialState.propertiesStatsData, error: payload };
        },

        [startOfferRequest.pending]: (state) => {
            return { ...state, loadingBtn: true, error: '' };
        },
        [startOfferRequest.fulfilled]: (state, { payload }) => {
            return { ...state, loadingBtn: false, offerRequest: payload, error: '' };
        },
        [startOfferRequest.rejected]: (state, { payload }) => {
            return { ...state, loadingBtn: false, offerRequest: '', error: payload };
        },

        [startFetchReqMatching.pending]: (state) => {
            return { ...state, loadingModal: true, error: '' };
        },
        [startFetchReqMatching.fulfilled]: (state, { payload }) => {
            return { ...state, loadingModal: false, reqMatching: payload, error: '' };
        },
        [startFetchReqMatching.rejected]: (state, { payload }) => {
            return { ...state, loadingModal: false, reqMatching: [], error: payload };
        },

        [startAssignUser.pending]: (state) => {
            return { ...state, loadingBtn: true, error: '' };
        },
        [startAssignUser.fulfilled]: (state, { payload }) => {
            return { ...state, loadingBtn: false, assignUser: payload, error: '' };
        },
        [startAssignUser.rejected]: (state, { payload }) => {
            return { ...state, loadingBtn: false, assignUser: '', error: payload };
        },

        [startAvailability.pending]: (state) => {
            return { ...state, loadingBtn: true, error: '' };
        },
        [startAvailability.fulfilled]: (state, { payload }) => {
            return { ...state, loadingBtn: false, availability: payload, error: '' };
        },
        [startAvailability.rejected]: (state, { payload }) => {
            return { ...state, loadingBtn: false, availability: '', error: payload };
        },

        //Heading Functions
        [setSelectedOrderOption.fulfilled]: (state, { payload }) => {
            return { ...state, selectedOrderOption: payload, currentPage: 1 };
        },
        [setSearch.fulfilled]: (state, { payload }) => {
            return { ...state, search: payload, currentPage: 1 }
        },
        [setCurrentPage.fulfilled]: (state, { payload }) => {
            return { ...state, currentPage: payload }
        }
    }
});


export const propertyActions = {
    ...propertySlice.actions,
    startSaveProperty,
    startFetchProperty,
    startFetchProperties,
    startDeleteProperty,
    startCopyProperty,
    startPropertyInfo,
    startPropertyPhotos,
    startPropertiesPoints,
    startPropertiesClusterPoints,
    startPropertiesBucketPoints,
    startFetchDataFields,
    startFetchSearchFields,
    startFetchSubcategory,
    startFetchTransaction,
    startFetchMunicipalities,
    startFetchAreas,
    startDeleteOrderAttach,
    sendOffer,
    sendAssignment,
    sendConfirmation,
    startFetchAssignment,
    startUpdateContactAssignment,
    startFetchConfirmation,
    startGenerateDesc,
    exportPdf,
    propertyStats,
    startFetchReqMatching,
    startOfferRequest,
    startAssignUser,
    startAvailability,
    setSelectedOrderOption,
    setSearch,
    setCurrentPage
}
export default propertySlice.reducer